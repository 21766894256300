import React, { useContext, useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ThemeContext } from 'styled-components'

const CorazonLleno = ({ index, onClick, width = 24, height = 24, disabled }) => {
  const heartRef = useRef<SVGSVGElement>(null)
  const { colors } = useContext<{ colors }>(ThemeContext)

  const unfollowAnimation = tl => {
    tl.from(`#greyHeartFull${index}`, {
      duration: 1,
      attr: {
        r: 0,
      },
    })
      .set(
        `#greyHeartFull${index}`,
        {
          scale: 0,
          transformOrigin: '50% 100%',
        },
        '-=0.99'
      )
      .to(
        `#greyHeartFull${index}`,
        {
          duration: 3,
          scale: 1,
          ease: 'power4.inOut',
        },
        '-=1.6'
      )
      .to(`#pinkHeartFull${index}`, {
        alpha: 0,
        duration: 2,
      })
  }

  useEffect(() => {
    gsap.set('svg', {
      visibility: 'visible',
    })

    gsap.set([`#breakLineL${index}`, `#breakLineR${index}`], {
      drawSVG: '100% 100%',
    })

    const tl = gsap.timeline({ paused: true, onComplete: onClick })
    unfollowAnimation(tl)

    if (heartRef.current) {
      heartRef.current.onclick = () => {
        if (!disabled) {
          tl.play(0)
        }
      }
    }

    tl.timeScale(4)

    // Cleanup on unmount
    return () => {
      tl.kill()
    }
  }, [])

  return (
    <svg
      ref={heartRef}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${width} ${height}`}
    >
      <defs>
        <path
          id={`heartFull${index}`}
          d="M12 21C11.734 21 11.48 20.895 11.292 20.706L3.52505 12.926C1.48905 10.886 1.48905 7.567 3.52505 5.527C4.50805 4.543 5.82105 4 7.22005 4C8.61905 4 9.93205 4.543 10.915 5.527L12 6.614L13.084 5.528C14.068 4.543 15.381 4 16.78 4C18.179 4 19.492 4.543 20.475 5.527C22.511 7.567 22.511 10.886 20.476 12.926L12.708 20.707C12.52 20.895 12.266 21 12 21Z"
        />
        <path
          id={`heartLight${index}`}
          d="M7.2197 6C6.3557 6 5.5457 6.334 4.9397 6.941C3.6817 8.201 3.6817 10.252 4.9407 11.514L11.9997 18.585L19.0597 11.514C20.3187 10.252 20.3187 8.201 19.0597 6.941C17.8477 5.726 15.7117 5.728 14.4997 6.941L12.7077 8.736C12.3317 9.113 11.6677 9.113 11.2917 8.736L9.4997 6.94C8.8937 6.334 8.0847 6 7.2197 6ZM11.9997 21C11.7347 21 11.4797 20.895 11.2927 20.706L3.5247 12.926C1.4887 10.886 1.4887 7.567 3.5247 5.527C4.5087 4.543 5.8207 4 7.2197 4C8.6187 4 9.9317 4.543 10.9147 5.527L11.9997 6.614L13.0847 5.528C14.0687 4.543 15.3807 4 16.7807 4C18.1787 4 19.4917 4.543 20.4747 5.527C22.5117 7.567 22.5117 10.886 20.4757 12.926L12.7077 20.707C12.5197 20.895 12.2657 21 11.9997 21Z"
        />
      </defs>
      <use id={`greyHeartFull${index}`} xlinkHref={`#heartLight${index}`} fill={colors.black72} />
      <use
        id={`pinkHeartFull${index}`}
        xlinkHref={`#heartFull${index}`}
        fill={disabled ? colors.greyDark : colors.primary.normal}
      />
      <g className={`brokenHeartGroup${index}`} opacity="0">
        <path
          id={`brokenHeartR${index}`}
          fill={colors.primary.normal}
          d="M12 21C12.266 21 12.52 20.895 12.708 20.706L20.475 12.926C22.511 10.886 22.511 7.567 20.476 5.527C19.493 4.543 18.18 4 16.78 4C15.381 4 14.068 4.543 13.085 5.527L12 6.614L12 21Z"
        />
        <path
          id={`brokenHeartL${index}`}
          fill={colors.primary.normal}
          d="M12 21C11.734 21 11.48 20.895 11.292 20.706L3.52505 12.926C1.48905 10.886 1.48905 7.567 3.52505 5.527C4.50805 4.543 5.82105 4 7.22005 4C8.61905 4 9.93205 4.543 10.915 5.527L12 6.614L12 21Z"
        />
      </g>
    </svg>
  )
}
export default CorazonLleno
