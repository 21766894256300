import React from 'react'
import { Text, TextContainer } from './mixins'
import { Trans } from 'react-i18next'

const Component = ({ labelKey, percentage, addMarginTop = false }) => {
  if (!percentage) percentage = 0
  return (
    <TextContainer addMarginTop={addMarginTop}>
      <Text>
        <Trans
          i18nKey={labelKey}
          values={{ percentage: percentage === 100 || percentage === 0 ? percentage : percentage?.toFixed(1) }}
          components={[<strong></strong>]}
        />
      </Text>
    </TextContainer>
  )
}

export default Component
