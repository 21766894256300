import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImageForEmptyState, ElementsContainer, TextContainer, Subtitle, Heading } from './mixins'
import image from '../../../../assets/images/postulaciones/statistics-not-found.svg'
import imageZJ from '../../../../assets/images/postulaciones/statistics-not-found-zj.svg'
import { IS_ZONA_JOBS } from '../../../../constants'

const EmptyState = () => {
  const { t } = useTranslation()

  return (
    <ElementsContainer>
      <ImageForEmptyState src={IS_ZONA_JOBS ? imageZJ : image} />
      <TextContainer>
        <Heading level={1}>{t('postulacionesStatistics.empty.title')}</Heading>
        <Subtitle>{t('postulacionesStatistics.empty.subtitle')}</Subtitle>
      </TextContainer>
    </ElementsContainer>
  )
}

export default EmptyState
