import styled from 'styled-components'

export const BottomNav = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 99;
  border-top: 1px solid #ebebeb;
  max-height: 55px;
  ${({ isIos }) => isIos && 'padding-bottom: 10px; max-height: 70px;'}
`

export const BottomNavItem = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;
  user-select: none;
  height: 56px;
  width: 25%;

  &:active {
    color: ${({ theme }) => theme.colors.secondary.normal};
  }

  ${({ ripple, theme }) => ripple && rippleEffect(theme)};
`

const rippleEffect = theme => {
  return `overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, ${theme.colors.black84} 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.4s, opacity 0.8s;
  }
  
  &:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
`
}

export const MenuItem = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ active, theme }) => (active ? theme.colors.secondary.normal : theme.colors.black48)};
  display: none;

  @media (min-width: 321px) {
    display: block;
  }
`

export const FloatingButton = styled.span`
  position: absolute;
  border-radius: 100px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12));
  background: white;
  padding: 14px;
  top: -9px;
`

export const ProfileImg = styled.img`
  border-radius: 50%;
  border: ${({ active, theme }) => `1px solid ${active ? theme.colors.secondary.normal : 'transparent'}`};
`
