import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const SidebarContentWrapper = styled.div`
  padding: 52px 16px 0px;
  height: 100vh;
  overflow-y: auto;
`

export const SuccessImg = styled.img`
  width: 100%;
  max-width: 222px;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 168px;
  }
`
export const FailureImg = styled.img`
  width: 100%;
  max-width: 234px;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 178px;
  }
`

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 24px;
  text-align: center;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 304px;
  }
`

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 32px;
  text-align: center;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 304px;
  }
`
export const SubTitleFailure = styled(SubTitle)`
  margin-bottom: 24px;
`
export const Question = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 32px;
  text-align: center;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 304px;
  }
`

export const CustomButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 220px;
  min-height: 48px;
`
