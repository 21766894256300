import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const Page = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: no-repeat 0% 100% url(${({ urlImg }) => urlImg}), #ffffff;
`

export const CustomDiv = styled.div`
  margin: 16px auto;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin: 105px auto;
  }
`

export const MetricDiv = styled('div')``

export const ImgLogo = styled.img`
  margin-top: 15px;
  height: ${({ logoStyles }) => (logoStyles?.height ? `${logoStyles?.height}px` : '45px')};
  width: ${({ logoStyles }) => (logoStyles?.width ? `${logoStyles?.width}` : 'inherit')};

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    width: inherit;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-top: 0;
  }
`

export const ImgCustom = styled.img`
  margin-bottom: 24px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-top: 66px;
    margin-bottom: 66px;
  }
`

export const TitleContainer = styled.div`
  margin: 24px 0;
`

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.black84};
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 52px;
    line-height: 64px;
  }
`

export const PreTitle = styled.p`
  color: ${({ theme }) => theme.colors.black84};
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 32px;
    line-height: 40px;
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.black84};
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`

export const ContainerButtons = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    flex-direction: row;
  }
`

export const CustomButton = styled(Button)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    min-width: 160px;
    width: auto;
  }

  ${({ hasTwoButtons, theme }) =>
    hasTwoButtons &&
    `&:last-child {
      margin-top: 16px;

      @media (min-width: ${theme.screen.lgMin}) {
        margin-left: 24px;
      }
    }`}
`

export const CustomLink = styled(Link)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    min-width: 160px;
    margin-top: 16px;
    width: auto;
  }
`
