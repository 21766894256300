import {
  GET_SETTINGS_SORT,
  GetSettingsSort,
  GET_POSTULACIONES_SETTINGS_SORT,
  GetPostulacionesSortSettings,
  GET_EMPRESAS_SETTINGS_SORT,
  GetEmpresasSortSettings,
  SET_EMPRESAS_SETTINGS_SORT,
  SetEmpresasSortSettings,
} from '../types/sort'

export const getSettingsSort = (): GetSettingsSort => {
  return {
    type: GET_SETTINGS_SORT,
    payload: null,
  }
}

export const getPostulacionesSortSettings = (): GetPostulacionesSortSettings => {
  return {
    type: GET_POSTULACIONES_SETTINGS_SORT,
    payload: null,
  }
}

export const getEmpresasSortSettings = (): GetEmpresasSortSettings => {
  return {
    type: GET_EMPRESAS_SETTINGS_SORT,
    payload: null,
  }
}

export const setEmpresasSortSettings = (value): SetEmpresasSortSettings => {
  return {
    type: SET_EMPRESAS_SETTINGS_SORT,
    payload: value,
  }
}
