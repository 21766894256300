import React from 'react'
import paymentMPSettings from '../../../../constants/payment-mp-settings'
import { PaymentStateTitle } from './mixins'

const Component = ({ status, product }) => {
  const pageSetting = paymentMPSettings()

  return <PaymentStateTitle level={2}>{`${pageSetting.products[product][status].title}`}</PaymentStateTitle>
}

export default Component
