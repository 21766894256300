import styled from 'styled-components'

export const PageContent = styled.div``

export const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 18px;
  }
`
export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme, soft }) => (soft ? theme.colors.black8 : theme.colors.black24)};
  margin-top: 8px;
  margin-bottom: 12px;
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-top: 10px;
  }
`
export const CustomMainContainer = styled.div`
  padding-left: 0px;
  padding-right: 0px;
`
