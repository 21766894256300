import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Link } from '@navent-jobs/ui-kit/components/link'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const CardHeaderContainer = styled('div')`
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyDark};
`

export const CardHeader = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    flex-direction: row;
    align-items: center;
  }
`

export const LogoTitleWrapper = styled('div')`
  display: flex;
  margin-bottom: 15px;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-bottom: 0px;
  }
`

export const LogoWrapper = styled('div')`
  width: 87px;
  background: white;
  border-radius: 8px;
  margin-right: 12px;
`

export const Logo = styled('img')`
  width: 87px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  ${({ hasAvisoFinalizado }) => hasAvisoFinalizado && 'filter: grayscale(100%);'}
`

export const EstadoWrapper = styled('div')`
  background-color: ${({ estado, theme }) => (estado === 'finalizada' ? '#474759' : theme.colors.extra.ultraLighten)};
  border-radius: 8px;
  padding: 6px 15px;
  width: fit-content;
  margin-bottom: 16px;
`

export const TitleAndCompany = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const OffsetRight = styled('div')`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    flex-direction: row;
  }
`

export const Flexbox = styled('div')`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    flex-direction: row;
  }
`

export const LinkCustom = styled(Link)`
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.primary.normal};
  margin-top: 16px;

  &:hover,
  &:focus:not(:active) {
    border: 1px solid ${({ theme }) => theme.colors.primary.normal};
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-left: 16px;
    margin-top: 0px;
  }
`

export const IconCustom = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

export const CustomButton = styled(Button)`
  margin: 0;
  padding: 12px;
`

export const EstadisticasContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const CustomText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
`
