import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const Page = styled.div`
  background-color: '#ffffff';
  padding: 24px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 48px 0px;
  }
`

export const FormGroup = styled.div`
  margin-bottom: 12px;
`

export const SubmitButton = styled(Button)`
  max-width: 194px;
  margin: 0 auto;
`

export const MessageError = styled.span`
  color: red;
  font-size: 12px;
`

export const LinkEmpresasWrapper = styled.div`
  margin: 20px auto 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
