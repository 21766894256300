import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const SortContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0 20px 0;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin: 0;
  }
`

export const SortLabel = styled.label`
  color: ${({ theme }) => theme.sort.colors.black84};
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  margin: 0 5px 0 0;
`

export const SortOptionsContainer = styled.ul`
  background-color: ${({ theme }) => theme.sort.colors.white};
  display: flex;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  overflow: hidden;
`

export const SortOption = styled.li`
  list-style-type: none;
`

const color = {
  active: ({ theme }) => theme.sort.active.color,
  inactive: ({ theme }) => theme.sort.inactive.color,
}

const background = {
  active: ({ theme }) => theme.sort.active.background,
  inactive: ({ theme }) => theme.sort.inactive.background,
}

const hover = {
  active: ({ theme }) => theme.sort.active.hover.color,
  inactive: ({ theme }) => theme.sort.inactive.hover.color,
}

export const SortLink = styled(Link)`
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  padding: 0 14px;
  color: ${({ active }) => (active ? color.active : color.inactive)};
  background: ${({ active }) => (active ? background.active : background.inactive)};
  transition: all 0.4s ease-in-out;
  border-radius: 8px;

  &:hover {
    text-decoration: none;
    color: ${({ active }) => (active ? hover.active : hover.inactive)};
  }
`
