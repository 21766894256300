import styled from 'styled-components'

export const SubtitleText = styled('p')`
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.black84};
`

export const StrongText = styled('p')`
  display: inline;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
`

export const CardNotEnded = styled('div')`
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  margin-top: 37px;
  margin-bottom: 37px;
  border: ${({ theme }) => theme.colors.greyDark} 1px solid;
`
