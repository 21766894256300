const sortPostulaciones = {
  enabled: true,
  noFollow: false,
  initValue: 'fechaPostulacion',
  items: [
    {
      key: 'fechaPostulacion',
      to: '?fechaPostulacion=true',
      label: 'Fecha de postulación',
    },
    {
      key: 'fechaPublicacionAviso',
      to: '?fechaPublicacionAviso=true',
      label: 'Fecha de publicación',
    },
  ],
  path: '/postulantes/postulaciones',
}

export default sortPostulaciones
