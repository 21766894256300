import React, { useContext } from 'react'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Col, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'

import {
  Asterisco,
  Disclaimer,
  DisclaimerText,
  Info,
  Title,
  ContentWrapper,
  FlexContainer,
  CustomButton,
  OptTypeContainer,
  SecondTitle,
  ButtonText,
} from './mixins'

// constants
import calificarImg from '../../../../constants/calificar-img'
import { DataLayersReviews } from '../../../../constants/gtm-events-reviews'

const Component = ({ step, nameCompany, isEmployee, setIsEmployee }) => {
  const { t } = useTranslation()
  const { colors } = useContext<{ colors }>(ThemeContext)
  let showReviewOpts
  let title

  switch (step) {
    case 'empresas':
      title = t('review.companies.section')
      break
    case 'calificacion':
      title = t('review.section')
      showReviewOpts = true
      break
    case 'comentario':
      title = t('review.comment.section')
      break
    default:
  }

  const ButtonOptTypeOnClick = value => {
    setIsEmployee(value)
    DataLayersReviews.selectEmployeeOpt(value)
  }

  const ButtonOptType = ({ bkg, label, selected, value }) => (
    <CustomButton selected={selected} bkg={bkg} onClick={() => ButtonOptTypeOnClick(value)}>
      <ButtonText selected={selected}>{t(label)}</ButtonText>
    </CustomButton>
  )

  return (
    <MainContainer>
      <Row>
        <Col>
          <FlexContainer>
            <ContentWrapper>
              {step !== 'confirmacion' && (
                <>
                  <Title>{title}</Title>
                  {showReviewOpts && (
                    <OptTypeContainer>
                      <ButtonOptType
                        selected={isEmployee}
                        bkg={calificarImg.empleado}
                        label="review.optionType.employee"
                        value={true}
                      />
                      <ButtonOptType
                        selected={isEmployee === false}
                        bkg={calificarImg.postulante}
                        label="review.optionType.applicant"
                        value={false}
                      />
                    </OptTypeContainer>
                  )}
                  {(!showReviewOpts || isEmployee !== null) && (
                    <>
                      <SecondTitle>{t('review.title', { nameCompany })}</SecondTitle>
                      <Disclaimer>
                        <Icon name="icon-light-eye-open" size="20" color={colors.black84} />
                        <DisclaimerText>{t('review.disclaimer')}</DisclaimerText>
                      </Disclaimer>
                      {step !== 'comentario' && (
                        <Info>
                          {t('review.info')}
                          <Asterisco>*</Asterisco>
                        </Info>
                      )}
                    </>
                  )}
                </>
              )}
            </ContentWrapper>
          </FlexContainer>
        </Col>
      </Row>
    </MainContainer>
  )
}

export default Component
