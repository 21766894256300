import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const MainWrapper = styled.div`
  width: 100%;
  background-color: white;
  padding-bottom: 16px;
  border-radius: 0 0 8px 8px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 0;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 0 10px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    height: ${({ lookandfeel }) => (lookandfeel ? '106px' : '102px')};
    padding: 8px 0;
    flex-direction: row;
  }
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-height: ${({ lookandfeel }) => (lookandfeel ? '106px' : '85px')};
  height: 85px;
  justify-content: center;
  margin: ${({ lookandfeel }) => (lookandfeel ? '0' : '20px 0 14px')};
  transition: all 0.5s ease 0s;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    height: ${({ lookandfeel }) => (lookandfeel ? '106px' : '56px')};
    margin: 0px;
    justify-content: flex-start;
  }
`

export const CompanyLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background-color: #ffff;
  border: 1px solid ${({ theme }) => theme.colors.greyDark} black;
  align-self: center;
  max-width: 134px;
  height: 100%;
  position: relative;
  top: ${({ top }) => top};
  transition: all 0.5s ease 0s;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    min-width: ${({ lookandfeel }) => (lookandfeel ? '166px' : '88px')};
  }
`

export const CompanyLogoGptwWrapper = styled.div`
  padding: 0;
  margin-left: 6px;
  min-width: 53px;
  position: relative;
  top: ${({ top }) => top};
  transition: all 0.5s ease 0s;
  width: min-content;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-left: 16px;
    min-width: 35px;
  }
`

export const CompanyLogo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`

export const CompanyContainer = styled.div`
  padding-left: 0;
  width: 100%;
  text-align: start;
  padding-top: ${({ hasLogo }) => (hasLogo ? '0' : '10px')};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding-left: ${({ hasLogo }) => (hasLogo ? '16px' : '0')};
  }
`

export const Company = styled.h1`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-size: 24px;
  line-height: 32px;
`

export const BumGrayLogoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding-right: 24px;
  }
`

export const BumGrayLogo = styled.img``

export const ShareWrapperButton = styled.div`
  display: flex;
  flex: none;
`

export const Review = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const RatingContainer = styled.div`
  display: flex;

  & div:first-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0px;
    margin-right: 2px;
    color: ${({ theme }) => theme.colors.black72};
  }
`

export const RatingText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.black48};
`

export const Spacer = styled(RatingText)`
  margin: 0 5px;
  color: ${({ theme }) => theme.colors.black60};
`

export const LinkCustom = styled(Link)`
  line-height: 16px;
  padding: 0;
`

export const BusquedaActivaContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: ${({ addMargin }) => (addMargin ? '12px' : 0)};
    flex-direction: row;
    align-items: center;
  }
`
