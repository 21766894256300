import styled from 'styled-components'

export const DataInfo = styled('h3')`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
  color: ${({ theme }) => theme.colors.black84};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const DataInfoContainer = styled('div')`
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  scroll-behavior: smooth;

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    flex: 0 0 25%;
    max-width: 25%;
  }`}
`

export const MatchLabel = styled('div')`
  color: #7000c8;
  font-weight: 600;
`
