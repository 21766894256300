import loggerService from '../services/logger-service'

export const useErrorLogging = () => {
  const performErrorLogging = error => {
    if (error.stack) {
      return loggerService.logError(error)
    }
    return loggerService.logError(error, error.stack)
  }

  return performErrorLogging
}
