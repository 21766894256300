import React, { useContext, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { useHistory } from 'react-router-dom'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// mixins
import { HeaderPage, HeaderContainer, ComponentContainer, CustomBack, Label, Title } from './mixins'

// components
import ListadoPromedioSalarial from '../components/list'
import Metas from '../../../components/metas/promedio-salarial-subarea'
import HeaderPromedioSalarial from '../components/header'
import HeaderPlaceholder from '../components/header-placeholder'

// redux
import { getPromedioSalarialArea } from '../../../redux/actions/promedioSalarial'

const BackButton = ({ history, label, color, variant = 'primary', size = 'medium' }) => {
  return (
    <CustomBack onClick={() => history.push('/salarios')} linkoptions={{ variant, type: 'link', size }}>
      <Icon name="icon-light-arrow-left" size="24" color={color} />
      <Label>{label}</Label>
    </CustomBack>
  )
}

const PromedioSalarialArea = ({ match, error, loading, promedioArea }) => {
  const { area } = match.params
  const { colors } = useContext<{ colors }>(ThemeContext)
  const { isMobile, isTabletPortrait } = useWindowSize()
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobileOrPortrait = isMobile || isTabletPortrait
  const [subarea, setSubarea] = useState(null)

  useEffect(() => {
    dispatch(getPromedioSalarialArea(area))
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [area, subarea])

  useEffect(() => {
    if (!isMobileOrPortrait) {
      setSubarea(null)
    }
  }, [isMobileOrPortrait])

  return (
    <>
      <Metas />
      {isMobileOrPortrait && (
        <>
          <HeaderPage>
            <HeaderContainer>
              <BackButton history={history} label="Salario" color={colors.black84} variant="black" size="small" />
            </HeaderContainer>
          </HeaderPage>

          {loading ? (
            <HeaderPlaceholder />
          ) : (
            promedioArea?.subareas?.length > 0 && (
              <HeaderPromedioSalarial promedioSalarial={subarea ? subarea : promedioArea} showButton={!!subarea} />
            )
          )}
        </>
      )}

      <ComponentContainer>
        {!isMobileOrPortrait && (
          <BackButton history={history} label="Volver a Salarios pretendidos" color={colors.primary.normal} />
        )}

        {!subarea && (
          <ListadoPromedioSalarial
            error={error}
            loading={loading}
            promedios={promedioArea?.subareas}
            onClick={isMobileOrPortrait ? promedio => setSubarea(promedio) : null}
            iconBtn={isMobileOrPortrait}
            title={() => <Title>Empleos por subáreas</Title>}
          />
        )}
      </ComponentContainer>
    </>
  )
}

const states = ({ promedioSalarialStore }) => ({
  error: promedioSalarialStore.statesPromedioArea.error,
  loading: promedioSalarialStore.statesPromedioArea.loading,
  promedioArea: promedioSalarialStore.promedioArea,
})

export default connect(states)(PromedioSalarialArea)
