const signIn = {
  googleLogin: 'Ingresar con Google',
  IngresaCuenta: 'Ingresa a tu cuenta',
  title: '¡Hola! Accede y encuentra el trabajo que buscas',
  emailPlaceholder: 'Ingresa tu email',
  IngresaEmailValido: 'Ingresa un email válido.',
  IngresContrasena: 'Ingresá tu contraseña',
  olvideContrasena: 'Olvidé mi contraseña',
  IngresaContrasenaValida: 'Ingresa una contraseña válida.',
  sinCuenta: '¿No tienes cuenta?',
  registrate: 'Regístrate como candidato',
  linkEmpresas: 'Ingresa como empresa',
  emailLabel: 'Email',
}

export default signIn
