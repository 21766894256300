import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'

// components
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { Textarea } from '@navent-jobs/ui-kit/components/textarea'
import { StepButtons } from '../../../../components/StepButtons'

// redux
import { sendReview } from '../../../../redux/actions/review'

// style
import {
  CustomForm,
  GeneralReview,
  FormRow,
  ConditionsLabel,
  FullContainer,
  ExampleText,
  ExampleContainer,
  CaretIcon,
  ExampleTitle,
  SuggestionText,
} from './mixins'
import { ContentWrapper, FlexContainer } from '../../mixins'

// components
import { showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'
import { DataLayersReviews } from '../../../../constants/gtm-events-reviews'

const Component = ({ successHandler, companyId, sendReviewStates, reviewData, backHandler, commentData }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { colors } = useContext<{ colors }>(ThemeContext)

  // Form
  const { control, formState, handleSubmit, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const submitHandler = data => {
    if (data.titleController || data.commentController) {
      dispatch(
        sendReview({
          companyId,
          body: {
            ...reviewData,
            comment: {
              title: data.titleController,
              description: data.commentController,
            },
          },
        })
      )
    } else {
      dispatch(
        sendReview({
          companyId,
          body: {
            ...reviewData,
          },
        })
      )
    }
    DataLayersReviews.stepClick('comentario', 'Continuar')
  }

  const stepBackHandler = () => {
    getValues()
    DataLayersReviews.stepClick('comentario', 'Volver')
  }

  useEffect(() => {
    if (sendReviewStates.success) {
      successHandler()
    }
    if (sendReviewStates.error) {
      dispatch(showSnackbarError(t('review.error')))
    }
  }, [sendReviewStates])

  useEffect(() => {
    DataLayersReviews.commentCompanyPrint()
  }, [])

  return (
    <>
      <form onSubmit={e => e.preventDefault()}>
        <MainContainer>
          <Row>
            <Col>
              <FlexContainer>
                <ContentWrapper>
                  <CustomForm>
                    <ExampleTitle>{t('review.comment.exampleTitle')}</ExampleTitle>
                    <Row>
                      {[
                        t('review.comment.exampleOne'),
                        t('review.comment.exampleTwo'),
                        t('review.comment.exampleThree'),
                        t('review.comment.exampleFour'),
                        t('review.comment.exampleFive'),
                      ].map((text, index) => (
                        <Col md={6} sm={12} key={`comment-example-${index}`}>
                          <ExampleContainer>
                            <CaretIcon name="icon-light-caret-right" color={colors.secondary.normal} size="20" />
                            <ExampleText>{text}</ExampleText>
                          </ExampleContainer>
                        </Col>
                      ))}
                    </Row>
                    <SuggestionText>{t('review.comment.suggestion')}</SuggestionText>
                    <GeneralReview>
                      <Controller
                        name="titleController"
                        control={control}
                        defaultValue={commentData?.titleController}
                        render={({ value, onChange }) => (
                          <FullContainer>
                            <Input
                              id="title"
                              name="title"
                              type="text"
                              value={value}
                              onChange={onChange}
                              placeholder={t('review.comment.titleExample')}
                              fieldOptions={{
                                label: t('review.comment.titleLabel'),
                                variant: 'darken',
                                maxLength: 50,
                                helperText: t('review.comment.titleHelperText'),
                              }}
                            />
                          </FullContainer>
                        )}
                      />
                    </GeneralReview>
                    <FormRow>
                      <Controller
                        name="commentController"
                        control={control}
                        defaultValue={commentData?.commentController}
                        render={({ value, onChange }) => (
                          <FullContainer>
                            <Textarea
                              id="comment"
                              name="comment"
                              value={value}
                              onChange={onChange}
                              placeholder={t('review.comment.commentExample')}
                              maxLength={500}
                              fieldOptions={{
                                label: t('review.comment.commentLabel'),
                                size: 'medium',
                                variant: 'darken',
                                helperText: t('review.comment.commentHelperText'),
                                minHeight: 200,
                                allowOnlyText: true,
                              }}
                              // TODO hack para mostrar siempre el helperText
                              errors={{}}
                            />
                          </FullContainer>
                        )}
                      />
                    </FormRow>
                    <ConditionsLabel>{t('review.terms')}</ConditionsLabel>
                  </CustomForm>
                </ContentWrapper>
              </FlexContainer>
            </Col>
          </Row>
        </MainContainer>
      </form>
      <StepButtons
        buttonsOptions={{
          primaryButton: {
            isLoading: sendReviewStates.loading,
            isValid: formState.isValid,
            label: t('review.send'),
            action: () => handleSubmit(submitHandler)(),
          },
          secondaryButton: {
            label: t('review.back'),
            action: () => backHandler(stepBackHandler),
          },
        }}
      />
    </>
  )
}

const mapStateToProps = ({ reviewStore }) => ({
  sendReviewStates: reviewStore.sendReviewStates,
})

export default connect(mapStateToProps)(Component)
