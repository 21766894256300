import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

// ui-kit
import { Title } from '@navent-jobs/ui-kit/components/title'

// images
import badgeGooglePlay from '../../../assets/images/google-play.svg'
import badgeAppStore from '../../../assets/images/app-store.svg'

// mixins
import { CTAComponent, StoreBadgesContainer, StoreBadgeContainer, StoreBadgeLink, StoreBadgeImage } from './mixins'

// constants
import { NOMBRE_SITIO, SUFIJO_PAIS } from '../../../constants'

const storeBadges = storeLinks => [
  {
    name: i18n.t('ctaDownloadApp.store.android.name'),
    badge: badgeGooglePlay,
    link: storeLinks[SUFIJO_PAIS][NOMBRE_SITIO.toLowerCase()].android,
    alt: i18n.t('ctaDownloadApp.store.android.alt'),
    width: '106px',
    height: '32px',
  },
  {
    name: i18n.t('ctaDownloadApp.store.ios.name'),
    badge: badgeAppStore,
    link: storeLinks[SUFIJO_PAIS][NOMBRE_SITIO.toLowerCase()].ios,
    alt: i18n.t('ctaDownloadApp.store.ios.alt'),
    width: '97px',
    height: '32px',
  },
]

const CtaDesktop = ({ storeLinks }) => {
  const { t } = useTranslation()

  return (
    <CTAComponent>
      <Title level={4}>{t('ctaDownloadApp.desktop.title')}</Title>
      <StoreBadgesContainer>
        {storeBadges(storeLinks).map(badge => {
          return (
            <StoreBadgeContainer key={badge.name}>
              <StoreBadgeLink href={badge.link} target="_blank">
                <StoreBadgeImage width={badge.width} height={badge.height} src={badge.badge} alt={badge.alt} />
              </StoreBadgeLink>
            </StoreBadgeContainer>
          )
        })}
      </StoreBadgesContainer>
    </CTAComponent>
  )
}

export default CtaDesktop
