import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import ui from './ui'
import applicant from './applicant'
import locations from './locations'
import seoFooter from './seo-footer'
import listadoAvisos from './listado-avisos'
import postulaciones from './postulaciones'
import homePage from './home-page'
import breadcrumbs from './breadcrumbs'
import modal from './modal'
import activeAviso from './active-aviso'
import filters from './filters'
import sort from './sort'
import statics from './statics'
import ctaDownloadApp from './cta-download-app'
import email from './email'
import paymentMP from './payment-mp'
import curriculum from './descarga-curriculum'
import auth from './auth'
import confirmCandidate from './confirm-candidate'
import activationEmail from './activation-email'
import changePassword from './change-password'
import notifications from './notifications'
import notificationSettings from './notification-settings'
import accountPrivacy from './account/privacy'
import accountChangePassword from './account/changePasword'
import updateEmail from './account/updateEmail'
import alertsSettings from './alerts-settings'
import deleteaccount from './delete-account'
import logout from './logout'
import editAlertsSettings from './edit-alerts-settings'
import alertRemove from './alert-remove'
import postularBar from './postular-bar'
import companySummary from './company-summary'
import analysisCv from './analysis-cv'
import paymentInventory from './applicant/inventory'
import languageTest from './language-test'
import postulacionQr from './postulacion-qr'
import saveJobPosting from './save-job-posting'
import messages from './messages'
import products from './products'
import listadoEmpresas from './listado-empresas'
import marketing from './marketing'
import confirmReference from './confirm-reference'
import alertsV2 from './alert-v2'
import review from './review'
import denuncia from './denuncia'
import actividad from './actividad'
import promedioSalarial from './promedioSalarial'
import suscripciones from './suscripcion'
import recomendados from './recomendados'
import cvParser from './cv-parser'
import saveCompany from './save-company'
import feria from './feria'
import disponibleInmediato from './disponible-inmediato'
import disponibleOfertas from './disponible-ofertas'
import confetti from './confetti'
import areaAds from './area-ads'

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    uiStore: ui,
    applicantStore: applicant,
    locationsStore: locations,
    notificationsStore: notifications,
    notificationSettingsStore: notificationSettings,
    seoFooterStore: seoFooter,
    listadoAvisosStore: listadoAvisos,
    postulacionesStore: postulaciones,
    activeAvisoStore: activeAviso,
    homePageStore: homePage,
    breadcrumbsStore: breadcrumbs,
    modalStore: modal,
    filtersStore: filters,
    sortStore: sort,
    staticStore: statics,
    ctaDownloadAppStore: ctaDownloadApp,
    emailStore: email,
    paymentMPStore: paymentMP,
    curriculumStore: curriculum,
    authStore: auth,
    confirmCandidateStore: confirmCandidate,
    activationEmailStore: activationEmail,
    changePasswordStore: changePassword,
    accountPrivacyStore: accountPrivacy,
    deleteAccountStore: deleteaccount,
    logoutStore: logout,
    accountChangePasswordStore: accountChangePassword,
    updateEmailStore: updateEmail,
    alertsSettingsStore: alertsSettings,
    editAlertsSettingsStore: editAlertsSettings,
    removeAlertStore: alertRemove,
    postularBarStore: postularBar,
    companySummaryStore: companySummary,
    analysisCvStore: analysisCv,
    paymentInventoryStore: paymentInventory,
    languageTestStore: languageTest,
    postulacionQrStore: postulacionQr,
    saveJobPostingStore: saveJobPosting,
    messagesStore: messages,
    confirmReferenceStore: confirmReference,
    productsStore: products,
    listadoEmpresasStore: listadoEmpresas,
    marketingStore: marketing,
    alertsV2Store: alertsV2,
    reviewStore: review,
    denunciaStore: denuncia,
    actividadStore: actividad,
    promedioSalarialStore: promedioSalarial,
    suscripcionesStore: suscripciones,
    recomendadosStore: recomendados,
    cvParserStore: cvParser,
    saveCompanyStore: saveCompany,
    feriaStore: feria,
    disponibleInmediatoStore: disponibleInmediato,
    disponibleOfertasStore: disponibleOfertas,
    confettiStore: confetti,
    areaAdsStore: areaAds,
  })

export default createRootReducer
