import React, { useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// mixins
import { OptionContainer, OptionIcon, OptionLabel, OptionKeyword, OptionTitle } from './mixins'

interface OptionProps {
  option: any
}

const OptionLabels = ({ option }: OptionProps) => {
  const { isMobile } = useWindowSize()
  const { colors } = useContext<{ colors }>(ThemeContext)
  const optionBusqueda = option?.busqueda

  const formatOptions = () => {
    const types = ['localidad', 'provincia', 'area', 'modalidad_trabajo', 'tipo_trabajo']
    const labels = Object.entries(option)
      .map(([key, value]: [any, any]) => {
        if (key !== 'url' && key !== 'busqueda') {
          return { order: types.indexOf(key), label: value.label }
        }
        return null
      })
      .filter(f => f !== null && f.order >= 0)
      .sort((a, b) => (a != null && b != null ? a.order - b.order : 0))
      .map(i => (i != null ? i.label : ''))
    return labels
  }

  const getOptions = useMemo(() => formatOptions(), [option])

  return (
    <OptionContainer
      isMobile={isMobile}
      href={option?.url}
      linkoptions={{ size: 'small', variant: isMobile ? 'gray' : 'black', type: 'link', native: false }}
    >
      <OptionTitle>
        <OptionIcon name="icon-light-clock" size="16" color={colors.black48} />
        <OptionLabel>
          {optionBusqueda && <OptionKeyword>{optionBusqueda?.value}</OptionKeyword>}
          {optionBusqueda && getOptions.length > 0 && <strong> · </strong>}
          {getOptions && getOptions.join(' · ')}
        </OptionLabel>
      </OptionTitle>
      {isMobile && <OptionIcon name="icon-light-caret-right" size="16" color={colors.black48} />}
    </OptionContainer>
  )
}

export default OptionLabels
