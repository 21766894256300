const sort = {
  enabled: true,
  noFollow: false,
  initValue: 'relevantes',
  items: [
    {
      key: 'relevantes',
      to: '?relevantes=true',
      label: 'Relevantes',
    },
    {
      key: 'recientes',
      to: '?recientes=true',
      label: 'Recientes',
    },
  ],
  path: '/empleos.html',
}

export default sort
