import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const PageContent = styled('div')`
  background: #ffffff;
  max-width: 850px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 25px auto 59px;
  padding: 24px 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin: 25px auto 159px;
  }
`
export const CustomDiv = styled('div')`
  margin-bottom: 25px;
  text-align: left;
`

export const CustomButton = styled(Button)`
  width: 100%;
  max-width: 180px;
`
