import styled from 'styled-components'
import { Container } from '@navent-jobs/ui-kit/components/grid'

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-bottom: 18px;
  margin-top: 16px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 52px;
    line-height: 64px;
    margin-bottom: 24px;
  }
`

export const Img = styled.img`
  height: 150px;
`

export const Subtitle = styled.p`
  margin: 16px auto;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const Description = styled.p`
  font-size: 18px;
  line-height: 24px;
`

export const Page = styled.div`
  padding: 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding-top: 35px;
  }
`

export const ColContainer = styled.div`
  text-align: center;
`

export const Information = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black84};
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 31px;
  }
`

export const CustomContainer = styled(Container)`
  background: #ffffff;
  padding: 0 16px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 95px 112px 51px;
  }
`
