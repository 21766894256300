import React, { useEffect, useState, lazy, Suspense } from 'react'
// uikit
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Link } from '@navent-jobs/ui-kit/components/link'

// tools
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { getUrlParamByName } from '../../hooks/get-url-param'
import GoogleLoginButton from './components/GoogleLoginButton'
import { LinkedinLoginButton } from './components/LinkedinLoginButton'
import { getCookieData } from '../../utils/cookies'
import { DataLayer } from '../../constants/gtm-events-sign-in'

// components
import { IS_ZONA_JOBS } from '../../constants'
import Heading from './components/Heading'
import LinkRecoverPassword from './components/LinkRecoverPassword'
import SubmitButton from '../../components/SubmitButton'
import LinkSignIn from './components/LinkSignIn'
import Image from './components/Image'
import Metas from '../../components/metas/signin'

// redux
import { signIn } from '../../redux/actions/sign-in'
// styles
import { Page, FormGroup, MessageError, LinkEmpresasWrapper } from './mixins'

// lazy components
const LayoutSingleHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../components/layouts/layout-single-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../components/PageLoader'))

const mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

const Component = ({ signInStates, applicant, applicantId, props }) => {
  const history = useHistory()
  const returnTo = getUrlParamByName('returnTo')
  const [showColImage, setShowColImage] = useState(false)
  const [showMessageError, setShowMessageError] = useState(signInStates.error)
  const url = window?.location
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isTabletLandscape, isDesktop, isMobile } = useWindowSize()
  const enabledViewport = isTabletLandscape || isDesktop

  const redirect = () => {
    if (returnTo) {
      history.replace(returnTo)
    } else {
      history.push('/')
    }
  }

  // Init React Hook Form
  const {
    handleSubmit,
    formState,
    formState: { errors },
    register,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = data => {
    const newData = {
      ...data,
      keepSession: true,
    }
    dispatch(signIn(newData))
    setShowMessageError(false)
  }

  const emailRules = {
    required: t('signIn.IngresaEmailValido'),
    pattern: {
      value: mailformat,
      message: t('signIn.IngresaEmailValido'),
    },
  }
  const emailZonaRules = {
    required: t('signIn.IngresaEmailValido'),
  }

  const passwordRules = {
    required: t('signIn.IngresaContrasenaValida'),
  }

  useEffect(() => {
    if (signInStates.success && applicantId) {
      DataLayer.userLogged({ applicantId, method: 'sesion_Manual', url })
      redirect()
    }
    if (signInStates.error) {
      setShowMessageError(true)
    }
  }, [signInStates, applicantId])

  useEffect(() => {
    setShowColImage(enabledViewport)
  }, [enabledViewport])

  useEffect(() => {
    // solo redirect cuando no este autologeado (en este caso si es posible logearse con su usario fuerte)
    const rtoken = getCookieData('loginRestringido', document.cookie)
    if (applicant && !rtoken) {
      redirect()
    }
  }, [applicant])

  useEffect(() => {
    DataLayer.pageView()
  }, [])

  return (
    <Suspense fallback={<PageLoader />}>
      <LayoutSingleHeader
        {...props}
        showBackButton={history.location.search !== ''}
        linkEmpresas={{ url: '/empresas/ingreso', label: t('signIn.linkEmpresas') }}
      >
        <Page>
          <Metas />
          <MainContainer>
            <Row>
              <Col lg={6} xl={4} offset={{ xl: 1 }}>
                <Heading />
                <GoogleLoginButton />
                <LinkedinLoginButton />
                <form onSubmit={handleSubmit(onSubmit)} id="form-signin">
                  <FormGroup>
                    {IS_ZONA_JOBS ? (
                      <Input
                        id="email"
                        name="user"
                        type="text"
                        rules={register(emailZonaRules) as any}
                        placeholder={t('signIn.emailPlaceholder')}
                        fieldOptions={{
                          label: t('signIn.emailLabel'),
                          variant: 'darken',
                          maxLength: 50,
                          before: <Icon name="icon-light-person" color="#3D47F5" size="22" />,
                        }}
                        onChange={() => setShowMessageError(false)}
                        errors={{ ...errors }}
                      />
                    ) : (
                      <Input
                        id="email"
                        name="user"
                        type="email"
                        rules={register(emailRules) as any}
                        placeholder={t('signIn.emailPlaceholder')}
                        fieldOptions={{
                          label: 'Email',
                          variant: 'darken',
                          maxLength: 50,
                          before: <Icon name="icon-light-person" color="#3D47F5" size="22" />,
                        }}
                        onChange={() => setShowMessageError(false)}
                        errors={{ ...errors }}
                      />
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Input
                      id="password"
                      name="password"
                      type="password"
                      rules={register(passwordRules) as any}
                      placeholder={t('signIn.IngresContrasena')}
                      fieldOptions={{
                        label: 'Contraseña',
                        variant: 'darken',
                        maxLength: 50,
                        before: <Icon name="icon-light-lock" color="#3D47F5" size="22" />,
                      }}
                      onChange={() => setShowMessageError(false)}
                      errors={{ ...errors }}
                      noBottomMargin={showMessageError}
                    />

                    {showMessageError && <MessageError>Usuario o contraseña incorrecto</MessageError>}
                  </FormGroup>

                  <LinkRecoverPassword />

                  <SubmitButton
                    id="ingresar"
                    idForm="form-signin"
                    isLoading={signInStates.loading}
                    disabled={!formState.isValid || signInStates.loading}
                  >
                    Ingresar
                  </SubmitButton>
                </form>
                <LinkSignIn returnTo={returnTo} />
                {isMobile && (
                  <LinkEmpresasWrapper>
                    <Link href="/empresas/ingreso" linkoptions={{ variant: 'primary', type: 'link', size: 'large' }}>
                      {t('signIn.linkEmpresas')}
                    </Link>
                  </LinkEmpresasWrapper>
                )}
              </Col>

              {showColImage && (
                <Col lg={6} xl={4} offset={{ xl: 2 }}>
                  <Image />
                </Col>
              )}
            </Row>
          </MainContainer>
        </Page>
      </LayoutSingleHeader>
    </Suspense>
  )
}

const states = ({ authStore, applicantStore }) => {
  const { signIn: signInStore } = authStore
  const { states: signInStates } = signInStore
  const { applicant } = applicantStore
  return {
    signInStates,
    applicant,
    applicantId: applicant?.id,
  }
}

export default connect(states)(Component)
