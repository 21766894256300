import styled, { createGlobalStyle } from 'styled-components'

export const Background = createGlobalStyle`
body {
  background-color: ${({ theme }) => theme.colors['body-bg']};
  padding-top: 0px;
}
`

export const Steps = styled.div`
  height: calc(100vh - 64px);
  overflow: auto;
  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    height: calc(100vh - 80px);
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 684px;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 88px;
`
