import React, { ReactElement } from 'react'
import {
  ImageForEmptyState,
  ElementsContainer,
  TextContainer,
  Subtitle,
  ComponentContainer,
  CustomButton,
  Heading,
} from './mixins'
import image from '../../assets/images/empty/empty-folder-woman.svg'
import imageZJ from '../../assets/images/empty/empty-folder-woman-zj.svg'
import { IS_ZONA_JOBS } from '../../constants'

interface EmptyWithButtonProps {
  heading: string
  subtitle: string
  src?: SVGElement
  button?: ReactElement
  buttonProps?: {
    onClick: () => void
    text: string
    disabled?: boolean
    disabledText?: string
  }
}

const EmptyWithButton = ({
  heading,
  subtitle,
  button,
  buttonProps,
  src = IS_ZONA_JOBS ? imageZJ : image,
}: EmptyWithButtonProps) => {
  return (
    <ElementsContainer>
      <ImageForEmptyState src={src} />
      <TextContainer>
        <Heading level={1}>{heading}</Heading>
        <Subtitle>{subtitle}</Subtitle>
        <ComponentContainer>
          {buttonProps ? (
            <CustomButton
              buttonoptions={{ variant: 'primary', size: 'medium', block: true }}
              disabled={buttonProps.disabled}
              onClick={buttonProps.onClick}
            >
              {buttonProps.disabled ? buttonProps.disabledText : buttonProps.text}
            </CustomButton>
          ) : (
            button
          )}
        </ComponentContainer>
      </TextContainer>
    </ElementsContainer>
  )
}

export default EmptyWithButton
