/* eslint-disable react/display-name */
import React from 'react'
import { Row, MainContainer, Col } from '@navent-jobs/ui-kit/components/grid'
import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'
import { CategoryName, Score, StarContainer, Spacer, CustomIcon, StarValueContainer, StarIcon } from './mixins'

const Component = ({ categories, mobileView }) => {
  const { isMobile } = useScreenSize()
  const smallView = isMobile || mobileView

  return (
    <MainContainer noGutter>
      <Row noGutter>
        {categories?.map(e => {
          return (
            <Col xs={12} md={mobileView ? 12 : 4} key={`categoriasPuntaje-${e.categoryId}`} noGutter>
              <Spacer>
                <Row noGutter>
                  <Col xs={smallView ? 10 : 12} noGutter>
                    <CategoryName>{e.categoryName}</CategoryName>
                  </Col>
                  {smallView && (
                    <Col xs={2} noGutter>
                      <StarValueContainer>
                        <Score>{e.average?.toFixed(1)}</Score>
                        <StarIcon key="icon-estrella" name="icon-bold-star" color="#FAB306" size="16" />
                      </StarValueContainer>
                    </Col>
                  )}
                </Row>
                <Row noGutter>
                  {!smallView && (
                    <>
                      <Score>{e.average?.toFixed(1)}</Score>
                      <StarContainer>
                        <Row noGutter>
                          {[...Array(5)].map((estrella, i) => (
                            <CustomIcon
                              key={`icon-${estrella}`}
                              name={i + 1 <= e.average ? 'icon-bold-star' : 'icon-light-star'}
                              color="#FAB306"
                              size="16"
                            />
                          ))}
                        </Row>
                      </StarContainer>
                    </>
                  )}
                </Row>
              </Spacer>
            </Col>
          )
        })}
      </Row>
    </MainContainer>
  )
}

export default Component
