import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'

// uikit
import { Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'

// style
import { PlaceholderContainer, SectionTitle, Wrapper } from './mixins'

// feature
import features from '../../constants/features-per-country'
import { SITE_ID } from '../../constants'

// components
import Resultados from './components/resultados'
import CalificacionesPlaceholderMobile from './components/placeholder/CalificacionesPlaceholderMobile'
import CalificacionesPlaceholderDesktop from './components/placeholder/CalificacionesPlaceholderDesktop'
import CalificacionesPlaceholderTablet from './components/placeholder/CalificacionesPlaceholderTablet'

// redux
import { getReviewsCompany } from '../../redux/actions/review'

// constants
import calificarImg from '../../constants/calificar-img'

const Placeholder = ({ isFichaAviso, mobileView }) => {
  const { isMobile, isDesktop } = useScreenSize()

  if (isMobile || mobileView) {
    return <CalificacionesPlaceholderMobile />
  }

  if (isDesktop && !isFichaAviso) {
    return <CalificacionesPlaceholderDesktop />
  }
  return <CalificacionesPlaceholderTablet />
}

const Component = ({
  isLoading,
  idCompany,
  denominacion,
  gptw = null,
  showCommentSection = false,
  noMarginTop = false,
  reviewsCompanyStates,
  isFichaAviso = false,
  noTitle = false,
  mobileView = false,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const reviewEnabled = features[SITE_ID || 'BMAR'].reviews.enabled

  useEffect(() => {
    if (idCompany && !gptw && reviewEnabled) {
      dispatch(getReviewsCompany(idCompany))
    }
  }, [idCompany])

  if (!reviewEnabled || !!gptw) {
    return null
  }

  if (isLoading) {
    return (
      <PlaceholderContainer>
        <Placeholder mobileView={mobileView} isFichaAviso={isFichaAviso} />
      </PlaceholderContainer>
    )
  }

  return (
    <Wrapper noMarginTop={noMarginTop} mobileView={mobileView}>
      <Helmet>
        <link rel="preload" as="image" href={calificarImg.resultado} />
      </Helmet>
      {!noTitle && (
        <Row noGutter>
          <Col noGutter>
            <SectionTitle mobileView={mobileView}>
              {t('review.access.section', { nameCompany: denominacion })}
            </SectionTitle>
          </Col>
        </Row>
      )}

      {reviewsCompanyStates.loading ? (
        <Placeholder isFichaAviso={isFichaAviso} mobileView={mobileView} />
      ) : (
        <Resultados
          idCompany={idCompany}
          denominacion={denominacion}
          showCommentSection={showCommentSection}
          isFichaAviso={isFichaAviso}
          mobileView={mobileView}
        />
      )}
    </Wrapper>
  )
}

const mapStateToProps = ({ reviewStore }) => ({
  reviewsCompanyStates: reviewStore.reviewsCompanyStates,
})

export default connect(mapStateToProps)(Component)
