import styled from 'styled-components'
import { Dropdown } from '@navent-jobs/ui-kit/components/dropdown'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const NotificationDropdownHeaderContainer = styled.div`
  margin-bottom: ${({ hasNotifications }) => (hasNotifications ? '32px' : '0px')};
  display: flex;
  flex-direction: column;
`

export const NotificationDropdownHeaderTitle = styled.h3`
  margin: 0px;
  color: ${({ theme }) => theme.colors.black18};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`

export const WithoutNotificationsImg = styled.img`
  margin: 0px auto 24px;
  width: 100%;
  max-width: 248px;
`
export const DropdownNotification = styled(Dropdown)`
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    [class^='Dropdown__DropdownCollapse'],
    [class*=' Dropdown__DropdownCollapse'] {
      min-width: 420px;
    }

    & caption:first-letter {
      text-transform: capitalize;
    }
  }
`
export const WithoutNotificationsText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black60};
  margin-bottom: 32px;
`

export const NotificationsWrapper = styled.div`
  margin: 0 -16px 28px;
  display: flex;
  align-item: center;
  width: 100%;
  justify-content: flex-start;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin: 0px -4px;
    padding: 10px 0px;
  }
`

export const NotificationImg = styled.img`
  width: 100%;
  max-width: 68px;
  max-height: 44px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.greyDark};
`

export const NotificationContent = styled.div`
  margin-left: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-left: 12px;
  }
`

export const NotificationTitle = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.colors.black84};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`

export const NotificationDescription = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.colors.black84};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

export const NotificationDate = styled.caption`
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black48};
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.grey};

  &:hover {
    background-color: #d6d6da;
  }
  &:active {
    background-color: #c2c2c8;
  }
`
export const NotificationBullet = styled.span`
  position: absolute;
  top: -7px;
  right: -4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: ${({ theme }) => theme.colors.primary.lighten};

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: white;
  border: 1px solid white;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    top: 0px;
    right: 1px;
  }
`

export const NotificationToggle = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    width: 32px;
    height: 32px;
  }
`
export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  align-item: center;
  justify-content: center;
  width: 100%;
  height: 306px;
`

export const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`

export const CustomLink = styled(Link)`
  height: 20px;
  line-height: 20px;
  max-width: 200px;
  font-weight: 700;
`
