import React, { useState, useEffect, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory } from 'react-router-dom'

// ui-kit
import { useOperativeSystem } from '@navent-jobs/ui-kit/hooks/operative-system'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { AvatarUser } from '@navent-jobs/ui-kit/components/avatar-user'

// components
import NavAccessBar from '../AccessBar'
import Notifications from '../Notifications'
import HeaderSearchBar from '../search-bar/HeaderSearchBar'
import Skeleton from './components/skeleton'

// mixins
import {
  Header,
  HeaderNav,
  HeaderItemNavbar,
  UserDropdownHeaderContainer,
  UserAvatar,
  UserName,
  UserDropdownHeaderSubtitle,
  HeaderItemLink,
  HeaderDropdown,
  MobileRightMenu,
  NavbarCustom,
  DataContainer,
  UserEmail,
} from './mixins'

// store
import { HeaderInterface, UserDropdownHeaderInterface, NavbarContentInterface, NavbarContentDefault } from './types'
import { resetListadoAvisosStore } from '../../redux/actions/listado-avisos'
import { setFilterSettings } from '../../redux/actions/filters'
import { getLocations } from '../../redux/actions/locations'

// constants
import logosHeader from '../../constants/logos'
import { loggedInMenuItems, noLoggedInMenuItems } from '../../constants/header/menu-items'
import { SITE_ID, NOMBRE_SITIO, SUFIJO_PAIS, IS_ZONA_JOBS } from '../../constants'
import storeLinks from '../../constants/cta-download-app-links'
import avatarDefault from '../../constants/avatar-default'

// services
import { SearchStack } from '../../services/search-stack'

// utils
import { loginRestringidoCookieExist, userLoggedIn } from '../../utils/cookies'
import features from '../../constants/features-per-country'

const pushGtmEvent = async item => {
  if (item.metric) {
    await item.metric()
  }
}

const handleOnClick = (e, item) => {
  const { linkoptions } = item

  if (!linkoptions.native) {
    e.preventDefault()
    pushGtmEvent(item)
    if (item.onClick) {
      item.onClick(e)
    }

    if (e.currentTarget.href) {
      if (linkoptions.target === '_blank') {
        window.open(e.currentTarget.href)
      } else {
        window.location.href = e.currentTarget.href
      }
    }
  } else {
    pushGtmEvent(item)
  }
}

/**
 * Verificamos el SITE_ID
 */
const getSiteId = () => SITE_ID || 'BMAR'

/**
 * Header del UserDropdown.
 * Si está logueado, muestra foto, nombre y apellido.
 * Si no lo está, muestra un título default.
 */
const UserDropdownHeader = ({ applicant, isUserLoggedIn }: UserDropdownHeaderInterface) => {
  const history = useHistory()
  const LogedInTitle = () => {
    return (
      <UserDropdownHeaderContainer
        onClick={() => (IS_ZONA_JOBS ? history.push('/postulantes/perfil') : history.push('/postulantes/curriculum'))}
        isUserLoggedIn={isUserLoggedIn}
      >
        <UserAvatar src={applicant && applicant.fotoURL ? `${applicant.fotoURL}` : avatarDefault} />
        <DataContainer>
          <UserName>{applicant && `${applicant.nombre} ${applicant.apellido}`}</UserName>
          <UserEmail>{applicant && `${applicant.email}`}</UserEmail>
        </DataContainer>
      </UserDropdownHeaderContainer>
    )
  }

  const LogedOutTitle = () => {
    const { t } = useTranslation()

    return (
      <UserDropdownHeaderContainer isUserLoggedIn={isUserLoggedIn}>
        <UserDropdownHeaderSubtitle>
          {t('Ingresa a tu cuenta y encuentra el trabajo que buscas')}
        </UserDropdownHeaderSubtitle>
      </UserDropdownHeaderContainer>
    )
  }

  return isUserLoggedIn ? LogedInTitle() : LogedOutTitle()
}

/**
 * Items del NavbarContent.
 */
const getLoggedInMenuItems = storeUrl => {
  return loggedInMenuItems({ storeUrl })
}

const getNoLoggedInMenuItems = storeUrl => {
  return noLoggedInMenuItems({ storeUrl })
}

/*
 *  Dropdown
 */
const NavItemDropdown = ({ item, menuToShow, isNavbarContentVisible, isUserLoggedIn, applicant, isMobile }) => {
  // eslint-disable-next-line prettier/prettier
  const itemsForDevice = item.items
    .filter(itemBis => itemBis.visibleIn?.includes(menuToShow) && item.items)
    .filter(itemBis => itemBis.portals.includes(getSiteId()))

  const onClickHandler = async (e, option) => {
    e.preventDefault()
    await pushGtmEvent(option)
    if (option && option.href) window.location.href = option.href
  }

  return (
    // Items header dropdown
    <HeaderDropdown
      id={item.items.id || null}
      withArrow={false}
      closeButton={!isMobile}
      visible={isMobile && isNavbarContentVisible}
      items={itemsForDevice}
      position="right"
      dropdownKey="user-menu"
      dropdownHeader={<UserDropdownHeader isUserLoggedIn={isUserLoggedIn} applicant={applicant} />}
      onClickHandler={onClickHandler}
    >
      {!isMobile && isUserLoggedIn && (
        <AvatarUser src={applicant && applicant.fotoURL ? `${applicant.fotoURL}` : avatarDefault} />
      )}
    </HeaderDropdown>
  )
}

const activeMenu = isMobile => {
  return isMobile ? 'mobile' : 'desktop'
}

/**
 * Si el item del Nav es solo un link, se llama a NavItemLink.
 * Si se tiene que imprimir un dropdown, se llama a NavItemDropdown.
 */
const NavItemLink = (item, isUserLoggedIn, section?: string) => {
  if (!item.portals.includes(getSiteId())) {
    return null
  }

  return (
    <HeaderItemLink
      key={item.content}
      id={item.id}
      linkoptions={item.linkoptions}
      href={item.href}
      rel={item.rel}
      onClick={e => handleOnClick(e, item)}
      isUserLoggedIn={isUserLoggedIn}
      section={section}
      width={item.width}
    >
      {item.before}
      {item.content}
    </HeaderItemLink>
  )
}

const navbarNotMobile = (item, menuToShow, isUserLoggedIn, location) => {
  return item.items
    ?.filter(option => option.portals?.includes(getSiteId()))
    .map(itemBis => {
      return (
        itemBis.visibleIn.includes(menuToShow) &&
        (!itemBis.visibleInPage || itemBis.visibleInPage.includes(location.pathname)) && (
          <HeaderItemNavbar key={`navbar-item-${itemBis.key}`} isUserLoggedIn={isUserLoggedIn}>
            {itemBis.href ? NavItemLink(itemBis, isUserLoggedIn, 'navbar') : itemBis.content}
          </HeaderItemNavbar>
        )
      )
    })
}

/**
 * Contenido del Nav, sean links o dropdown.
 */
const NavbarContent = ({
  menuItems,
  isUserLoggedIn,
  isMobile,
  applicant,
  isNavbarContentVisible,
  location,
}: NavbarContentInterface = NavbarContentDefault) => {
  const [isUserLoggedInLoading, setIsUserLoggedInLoading] = useState(true)

  setTimeout(() => {
    setIsUserLoggedInLoading(false)
  }, 1500)

  /**
   * Se determina si se tiene que renderizar un dropdown o no dependiendo el type del item.
   */
  const isDropdown = item => item?.type === 'dropdown'
  const isNavbar = item => item?.type === 'navbar'

  /**
   * Skeleton
   */
  if (isUserLoggedInLoading && isUserLoggedIn) {
    return <Skeleton />
  }

  /**
   * En la siguiente línea recién se renderizan los componentes de NavbarContent.
   */
  return (
    <HeaderNav>
      {menuItems &&
        menuItems.map(item => {
          const menuToShow = activeMenu(isMobile)

          if (isDropdown(item) && isMobile) {
            return item.items
              ?.filter(option => option.portals?.includes(getSiteId()))
              .map(itemBis => {
                if (itemBis.key === 'separador' || itemBis.key === 'seccion') {
                  return <>{itemBis.content}</>
                }
                // ITEMS MENU MOBILE
                return (
                  itemBis.visibleIn.includes(menuToShow) && (
                    <HeaderItemNavbar key={`navbar-item-${itemBis.key}`} isUserLoggedIn={isUserLoggedIn}>
                      {NavItemLink(itemBis, isUserLoggedIn)}
                    </HeaderItemNavbar>
                  )
                )
              })
          }

          if (isNavbar(item) && !isMobile) {
            return navbarNotMobile(item, menuToShow, isUserLoggedIn, location)
          }

          return (
            item.visibleIn?.includes(menuToShow) && (
              <HeaderItemNavbar key={`navbar-item-${item.key}`}>
                {isDropdown(item)
                  ? NavItemDropdown({ item, menuToShow, isNavbarContentVisible, isUserLoggedIn, applicant, isMobile })
                  : NavItemLink(item, isUserLoggedIn)}
              </HeaderItemNavbar>
            )
          )
        })}
    </HeaderNav>
  )
}

/**
 * Componente Header.
 */
const Component = ({
  applicant,
  locations,
  location,
  hideWithPath = [],
  filtersSettings,
  singleHeader = false,
  feria = undefined,
}: HeaderInterface) => {
  const dispatch = useDispatch()
  const { isMobile, isTabletPortrait, isTabletLandscape, isDesktop } = useWindowSize()
  const { currentOS } = useOperativeSystem()

  const [menuItems, setMenuItems] = useState()
  const [isNavbarCollapseVisible, setNavbarCollapse] = useState(false)
  const [showHeaderSearchBar, setShowHeaderSearchBar] = useState(false)

  const storeUrl = storeLinks[SUFIJO_PAIS][NOMBRE_SITIO.toLowerCase()][currentOS || 'android']

  // Verificamos si el usuario ingreso por autologuin y guardamos en memo (Desktop)
  const autologged = useMemo(() => loginRestringidoCookieExist(), [])

  // Verificamos si el usuario esta logueado mediante cookie
  // La data del usuario logueado la seguimos obteniendo desde el store 'applicant'
  const isUserLoggedIn = userLoggedIn()

  const [hideByRouting, setHideByRouting] = useState(false)

  const tabletLandscapeMinWidth = 1024
  const preTabletLandscapeMinWidth = 992
  const TabletLandscapeToDesktop =
    window.outerWidth >= preTabletLandscapeMinWidth && window.outerWidth <= tabletLandscapeMinWidth

  /**
   * Enlistamos todas las páginas en donde está el Header y no se tiene que renderizar el Searchbar.
   */
  const hideSearchbarIn: string[] = ['/']

  const hideSearchBar = hideSearchbarIn.includes(location.pathname)

  const logos = feria
    ? { ...features[SITE_ID || 'BMAR'].feria?.logo, src: feria.imagenes.logoHeader }
    : logosHeader[getSiteId()]

  const updateMenuItems = () => {
    const newMenuItems: any = isUserLoggedIn ? getLoggedInMenuItems(storeUrl) : getNoLoggedInMenuItems(storeUrl)

    setMenuItems(newMenuItems)
  }

  const updateNavbarCollapseStatus = () => {
    setNavbarCollapse(!isNavbarCollapseVisible)
  }

  useEffect(() => {
    updateMenuItems()
  }, [isUserLoggedIn])

  useEffect(() => {
    if (isDesktop && !hideSearchBar && !feria) {
      setShowHeaderSearchBar(true)
    }
  }, [isDesktop, hideSearchbarIn, isUserLoggedIn])

  const resetLanding = () => {
    dispatch(resetListadoAvisosStore())
    dispatch(setFilterSettings())
  }

  const onLocations = () => {
    dispatch(getLocations())
  }

  /**
   * Ocultamos Header en la page de Ficha de aviso Mobile.
   */
  const RoutingComponent = () => (
    /**
     * Si el path es igual al hideWithPath, se asigna al hideByRouting true y no se renderiza el componente.
     */
    <Switch>
      <Route
        path={hideWithPath}
        exact
        render={() => setHideByRouting((isMobile || isTabletPortrait) && !TabletLandscapeToDesktop)}
      />
      <Route render={() => setHideByRouting(false)} />
    </Switch>
  )

  return (
    <>
      <RoutingComponent />
      {!hideByRouting && (
        <Header id="header-search">
          <MainContainer>
            <Row>
              <Col>
                <NavbarCustom
                  hideSearchBar={hideSearchBar || !!feria}
                  isNavbarContentVisible={isNavbarCollapseVisible}
                  toggleNavbarContent={updateNavbarCollapseStatus}
                  logo={{ ...logos }}
                  callback={pushGtmEvent}
                  url={feria ? `/${feria.slug}` : undefined}
                >
                  {showHeaderSearchBar && (
                    <HeaderSearchBar
                      recentSearches={SearchStack}
                      availableLocations={locations}
                      resetLanding={resetLanding}
                      onLocations={onLocations}
                    />
                  )}

                  {!isDesktop && <UserDropdownHeader isUserLoggedIn={isUserLoggedIn} applicant={applicant} />}

                  <NavbarContent
                    menuItems={menuItems}
                    isUserLoggedIn={isUserLoggedIn}
                    isMobile={isMobile || isTabletPortrait || isTabletLandscape}
                    applicant={applicant}
                    isNavbarContentVisible={isNavbarCollapseVisible}
                    location={location}
                    filtersSettings={filtersSettings}
                  />
                </NavbarCustom>

                {!isDesktop && isUserLoggedIn && !autologged && (
                  <MobileRightMenu>
                    <Notifications />
                  </MobileRightMenu>
                )}
              </Col>
            </Row>

            {isDesktop && !singleHeader && (
              <Row>
                <Col>
                  <NavAccessBar />
                </Col>
              </Row>
            )}
          </MainContainer>
        </Header>
      )}
    </>
  )
}

/**
 * De esta manera, pedimos al store los datos del postulante y locations.
 */
const state = ({ applicantStore, locationsStore, filtersStore, feriaStore }) => {
  return {
    applicant: applicantStore.applicant,
    locations: locationsStore && locationsStore.locations,
    filtersSettings: filtersStore.filtersSettings,
    feria: feriaStore && feriaStore.feria,
  }
}

const ComponentWithStore = connect(state)(Component)
export default ComponentWithStore
