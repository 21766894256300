import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Statistics } from '../../../redux/types/postulaciones'

const ICONS = {
  1: 'icon-light-phone-off',
  2: 'icon-light-phone-call',
  3: 'icon-light-screen',
  4: 'icon-light-person-checked',
  5: 'icon-light-person-delete',
}

const LABELS = {
  1: 'No me contactaron',
  2: 'Me contactaron',
  3: 'Me entrevistaron',
  4: 'Quedé seleccionado',
  5: 'No quedé seleccionado',
}

interface StatusStatisticsProps {
  estados: Statistics['estadosPostulacion']
}

const useStatusStatistics = ({ estados }: StatusStatisticsProps) => {
  const { colors } = useContext<{ colors }>(ThemeContext)

  return estados?.map(estado => ({
    key: estado.nombre,
    title: LABELS[estado.id] || estado.descripcion,
    icon: {
      color: colors.black24,
      name: ICONS[estado.id],
      size: '48',
    },
    label: `${estado.cantidad}`,
  }))
}

export default useStatusStatistics
