import styled from 'styled-components'
import { Modal } from '@navent-jobs/ui-kit/components/modal'
import { Button } from '@navent-jobs/ui-kit/components/button'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const ModalComponent = styled(Modal)``

export const PreguntaSimpleContainer = styled('div')`
  margin: 0 0 32px;
`

export const ModalFooterComponent = styled('div')`
  display: flex;
  text-align: center;
  width: 100%;
  padding: 0;
`

export const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    flex-direction: row;
  }
`

export const CustomButton = styled(Button)`
  padding: 0 12px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    max-width: 200px;
  }
`

export const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  padding-right: 22px;
  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    padding-right: 0px;
  }
`

export const ModalTitle = styled('p')`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin: 0;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    font-size: 24px;
    line-height: 32px;
  }
`
export const ModalSubtitle = styled('p')`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black84};
  margin: 0;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const IconCustom = styled(Icon)`
  margin-right: 12px;
`
