import styled from 'styled-components'
import { Title } from '@navent-jobs/ui-kit/components/title'

export const PaymentStateTitle = styled(Title)<{ theme }>`
  color: ${props => props.theme.colors.secondary.normal};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.screen.mdMin}) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  }
`
