import styled from 'styled-components'

export const ContainerComponent = styled('div')`
  border: 0 solid #c5cee0;
  border-width: 0 0 0 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    border-width: 0 ${({ isLast }) => (isLast ? 0 : '1px')} 0 0;
  }
`

export const Divider = styled.div`
  border: 0 solid #c5cee0;
  border-width: 0 0 ${({ isLast }) => (isLast ? 0 : '1px')} 0;
  width: calc(100% - 28px);
  margin: auto;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    border-width: 0 0 0 0;
  }
`

export const Section = styled('div')`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 24px;
  }
`

export const Title = styled('div')`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black84};
  font-weight: 600;
`

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  margin: 13px 16px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin: 26px 26px ${({ plusHeight }) => (plusHeight ? `${26 + plusHeight}px` : '26px')} 26px;
  }
`

export const Label = styled('div')`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary.normal};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-left: 12px;
  }
`
