import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const CustomButton = styled(Button)<{ typeWidth }>`
  text-align: center;
  line-height: 48px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: ${({ typeWidth }) => (typeWidth ? '280px' : '200px')};
  }
`

export const StickyContent = styled.div`
  padding: 12px;
  bottom: 0px;
  width: 100%;
  z-index: 12;
  background-color: #ffffff;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
    gap: 24px;
  }
`
