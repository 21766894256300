import { merge } from 'lodash'
import all from '../all'
import interlinking from './interlinking'
import pdaPago from './pda-pago'
import fichaAviso from './ficha-aviso'
import card from './card'
import estadisticas from './estadisticas'
import curriculum from './curriculum'
import promedioSalarial from './promedio-salarial'
import empleosMasBuscados from './empleos-mas-buscados'
import searchAvisosV2 from './search-avisos-v2'
import feria from './feria'
import avisosFake from './avisos-fake'

const bmve = {
  ...all,
  ...interlinking,
  pdaPago,
  fichaAviso: merge({}, all.fichaAviso, fichaAviso),
  card: merge({}, all.card, card),
  estadisticas: merge({}, all.estadisticas, estadisticas),
  curriculum: merge({}, all.curriculum, curriculum),
  promedioSalarial: merge({}, all.promedioSalarial, promedioSalarial),
  empleosMasBuscados: merge({}, all.empleosMasBuscados, empleosMasBuscados),
  searchAvisosV2: merge({}, all.searchAvisosV2, searchAvisosV2),
  feria,
  avisosFake: merge({}, all.avisosFake, avisosFake),
}

export default bmve
