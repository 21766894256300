import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// ui-kit
import { Row, Col, ContainerFluid } from '@navent-jobs/ui-kit/components/grid'
import { Textarea } from '@navent-jobs/ui-kit/components/textarea'
import RadioButton from '@navent-jobs/ui-kit/components/RadioButton'

// mixins
import {
  ModalComponent,
  PreguntaSimpleContainer,
  ModalFooterComponent,
  ButtonContainer,
  CustomButton,
  ModalTitle,
  ModalSubtitle,
  IconCustom,
  TitleContainer,
} from './mixins'

// constants
import { DataLayersPostPostulacion } from '../../../../constants/gtm-events-post-postulacion'

export interface ModalPreguntasProps {
  width: number
  visible: boolean
  preguntas?: {
    simple?: {
      id: number
      texto: string
    }
    choice?: {
      id: number
      texto: string
      opciones
    }
  }[]
  loading: boolean
  preguntasObligatorias: boolean
  title?: string
  closeModal: () => void
  sendAnswers: ({ respuestas }) => void
}

export interface ModalFooterProps {
  isFormValid: boolean
  loading: boolean
}

const ModalFooter = ({ isFormValid, loading }: ModalFooterProps) => {
  const { t } = useTranslation()

  return (
    <ModalFooterComponent>
      <ContainerFluid>
        <Row>
          <Col>
            <ButtonContainer>
              <CustomButton
                buttonoptions={{ variant: 'primary' }}
                form="form-preguntas"
                type="submit"
                disabled={!isFormValid}
                isLoading={loading}
              >
                {t('modalPreguntas.submitButton')}
              </CustomButton>
            </ButtonContainer>
          </Col>
        </Row>
      </ContainerFluid>
    </ModalFooterComponent>
  )
}

const ModalPreguntas = ({
  width,
  visible,
  preguntas,
  closeModal,
  sendAnswers,
  loading,
  title,
  preguntasObligatorias,
}: ModalPreguntasProps) => {
  const { register, errors, handleSubmit, formState, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { t } = useTranslation()

  const textareaValidacion = t('modalPreguntas.validation.textArea')
  const radioButtonValidacion = t('modalPreguntas.validation.radioButton')

  const sendAnswersHandler = formData => {
    const respuestas = formData
      ? Object.keys(formData).map(e => {
          return { preguntaId: e, respuesta: formData[e] }
        })
      : []

    if (sendAnswers && !loading) {
      DataLayersPostPostulacion.postLeadClick('Responder')
      sendAnswers({ respuestas })
    }
  }

  const closeHanddler = () => {
    DataLayersPostPostulacion.postLeadClick('Cerrar')
    closeModal()
  }

  return (
    <ModalComponent
      width={width}
      visible={visible}
      onClose={closeHanddler}
      title={
        <TitleContainer>
          {!preguntasObligatorias && <IconCustom name="icon-light-checkbox-checked" size="24" color="#0A26EE" />}
          <ModalTitle>{title || t('modalPreguntas.title')}</ModalTitle>
        </TitleContainer>
      }
      subtitle={<ModalSubtitle>{t('modalPreguntas.subtitle')}</ModalSubtitle>}
      footer={<ModalFooter loading={loading} isFormValid={formState.isValid} />}
    >
      <form id="form-preguntas" onSubmit={handleSubmit(sendAnswersHandler)}>
        <ContainerFluid>
          {preguntas?.map((pregunta, index) => (
            <div key={`pregunta-${index}`}>
              <Row>
                <Col>
                  {pregunta.simple && (
                    <PreguntaSimpleContainer>
                      <Controller
                        control={control}
                        name={`${pregunta.simple.id}`}
                        rules={{
                          required: textareaValidacion,
                        }}
                        render={({ onChange, value }) => (
                          <Textarea
                            id={`id-pregunta-${pregunta.simple?.id}`}
                            placeholder={t('modalPreguntas.placeholder.input')}
                            name={`name-pregunta-${pregunta.simple?.id}`}
                            fieldOptions={{
                              label: pregunta.simple?.texto,
                              variant: 'darken',
                              allowOnlyText: true,
                            }}
                            rules={{
                              required: true,
                            }}
                            maxLength={2000}
                            errors={errors}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </PreguntaSimpleContainer>
                  )}

                  {pregunta.choice && (
                    <RadioButton
                      name={`${pregunta.choice?.id}`}
                      fieldOptions={{
                        label: pregunta.choice?.texto,
                        size: 'medium',
                      }}
                      options={pregunta.choice.opciones}
                      rules={register({ required: radioButtonValidacion })}
                      errors={errors}
                    />
                  )}
                </Col>
              </Row>
            </div>
          ))}
        </ContainerFluid>
      </form>
    </ModalComponent>
  )
}

export default ModalPreguntas
