const feria = {
  logo: {
    width: '119px',
    height: '28px',
    alt: 'Logo Laborum',
    className: 'logo',
  },
}

export default feria
