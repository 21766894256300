import React, { useEffect, useState, lazy, Suspense } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

// ui-kit & mixins
import PhoneInput from '@navent-jobs/ui-kit/components/PhoneInput'
import { Select } from '@navent-jobs/ui-kit/components/select'
import { ContainerFluid, MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// components
import { Link } from '@navent-jobs/ui-kit/components/link'
import Heading from './components/Heading'
import TermsAndConditions from './components/TermsAndConditions'
import SubmitButton from '../../components/SubmitButton'
import LinkSignUp from './components/LinkSignUp'
import Image from './components/Image'
import AutorizeNewsletter from './components/checkboxs/AuthorizeNewsletter'
import Metas from '../../components/metas/signup'
import GoogleLoginButton from '../sign-in/components/GoogleLoginButton'

// store
import { signUp } from '../../redux/actions/sign-up'
import { showSnackbar } from '../../redux/actions/applicant/curriculum/snackbar'

// constants
import formRules from '../../constants/sign-up'
import { ID_PAIS, SITE_ID } from '../../constants'
import { sitePhonePrefix } from '../../constants/site-phone-prefix'
import { DataLayer } from '../../constants/gtm-events-sign-up'

// hooks
import { getUrlParamByName } from '../../hooks/get-url-param'
import { getTipoDocumento } from '../../redux/actions/statics'

// styles
import {
  Page,
  FormGroup,
  HelperRequired,
  MessageError,
  Label,
  TipoYNumeroWrapper,
  NumeroWrapper,
  TipoDocumentoWrapper,
  LinkEmpresasWrapper,
  SocialLoadedButton,
} from './mixins'
import { LinkedinLoginButton } from '../sign-in/components/LinkedinLoginButton'
import DataProtectionPolicy from './components/checkboxs/DataProtectionPolicy'

// lazy components
const LayoutSingleHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../components/layouts/layout-single-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../components/PageLoader'))

const BMEC = SITE_ID === 'BMEC'

const SocialLogin = ({ type }) => {
  const { t } = useTranslation()

  return !type ? (
    <>
      <Row>
        <Col lg={7} xl={7}>
          <GoogleLoginButton />
        </Col>
      </Row>
      <Row>
        <Col lg={7} xl={7}>
          <LinkedinLoginButton />
        </Col>
      </Row>
    </>
  ) : (
    <Row>
      <Col lg={7} xl={7}>
        <SocialLoadedButton buttonoptions={{ variant: 'primary', size: 'small' }} disabled onClick={() => ''}>
          {type === 'google' && t('signUp.googleDataLoaded')}
          {type === 'linkedin' && t('signUp.linkedinDataLoaded')}
        </SocialLoadedButton>
      </Col>
    </Row>
  )
}

const Component = ({ signUpStates, applicantId, optionsTipoDocumento, ...rest }) => {
  const returnTo = getUrlParamByName('returnTo')
  const { isDesktop, isMobile } = useWindowSize()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [acceptAccountCreation, setAcceptAccountCreation] = useState(false)
  const [dataProtectionPolicy, setDataProtectionPolicy] = useState(null)
  const [suscripciones, setSuscripciones] = useState(false)
  const [showMessageError, setShowMessageError] = useState(false)
  const formRulesSettings = formRules()
  const urlTracking = window?.location

  // Init React Hook Form
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setError,
    trigger,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      nombre: location.state?.nombre,
      apellido: location.state?.apellido,
      email: location.state?.email,
    },
  })

  const onSubmit = data => {
    const hasDocument = !!data.numeroDocumento && !!data.tipoDocumento
    const newData = {
      nombre: data.nombre,
      apellido: data.apellido,
      password: data.password,
      documento: hasDocument ? data.numeroDocumento : null,
      tipoDocumentoId: hasDocument ? data.tipoDocumento.value : null,
      celularPrefijo: data.celular.prefijo,
      celularNumero: data.celular.numero,
      suscripciones,
      proteccionDatos: dataProtectionPolicy,
      email: location.state?.email ?? data.email,
      token: location.state?.token ?? null,
      type: location.state?.type ?? null,
    }

    dispatch(signUp(newData))
  }

  /** States donde el usuario declara: {
   acceptAccountCreation: aceptar terminos/condiciones /politicas de privacidad (obligatorio),
   suscripciones : el usuario decide si quiere recivir emails de portal (opcional),
  }
  */
  const handleDisbled = () => {
    // disableCta = true significa que el cta va a estar deshabilitado
    const disableCta = !isValid || signUpStates.loading || !acceptAccountCreation
    return disableCta
  }

  // Obtenemos los estaticos tipo documento de ser necesario
  useEffect(() => {
    if (!optionsTipoDocumento) {
      dispatch(getTipoDocumento())
    }
  }, [])

  // Mostramos snackbar luego de cargar la info de Google
  useEffect(() => {
    if (location.state?.token) {
      const title = location.state?.type === 'google' ? t('signUp.googleDataLoaded') : t('signUp.linkedinDataLoaded')
      dispatch(showSnackbar(title, 'icon-light-cloud-upload'))
    }
    // tracking page view
    DataLayer.pageView()
  }, [])

  /**
   * Logica de redireccionamiento en caso de exito o si el usuario ya esta logueado
   */
  useEffect(() => {
    if (applicantId) {
      const procesando = signUpStates.success || signUpStates.error || signUpStates.loading
      if (signUpStates.success) {
        DataLayer.registrationSuccess({ url: urlTracking, method: location.state?.type, applicantId })
        if (returnTo?.includes('/postulacion-qr/')) {
          history.replace(returnTo)
        } else {
          const url = new URL('/bienvenida', window.location.origin)
          url.searchParams.set('registroType', location.state?.type ?? 'manual')
          if (returnTo) {
            url.searchParams.set('returnToUrl', returnTo)
          }
          window.location.href = url.toString()
        }
      } else if (!procesando) {
        if (returnTo) {
          history.push(returnTo)
        } else {
          history.push('/')
        }
      }
    }
    setShowMessageError(signUpStates.error)
  }, [signUpStates, applicantId])

  return (
    <Suspense fallback={<PageLoader />}>
      <LayoutSingleHeader {...rest} linkEmpresas={{ url: '/empresas/registro', label: t('signUp.linkEmpresas') }}>
        <Page>
          <Metas />
          <MainContainer>
            <Row>
              <Col lg={10} xl={6} offset={{ lg: 1, xl: 1 }}>
                <Heading />

                <form onSubmit={handleSubmit(onSubmit)} id="form-signup">
                  <ContainerFluid noGutter>
                    <SocialLogin type={location.state?.type} />
                    <Row>
                      <Col md={6}>
                        {/* Nombre */}
                        <FormGroup>
                          <Input
                            id="nombre"
                            name="nombre"
                            type="text"
                            rules={register(formRulesSettings.name) as any}
                            placeholder={t('signUp.ingresaNombre')}
                            fieldOptions={{
                              label: <Label>Nombre(s)</Label>,
                              variant: 'darken',
                            }}
                            errors={{ ...errors }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        {/* Apellido */}
                        <FormGroup>
                          <Input
                            id="apellido"
                            name="apellido"
                            type="text"
                            rules={register(formRulesSettings.surname) as any}
                            placeholder={t('signUp.ingresaApellido')}
                            fieldOptions={{
                              label: <Label>Apellido(s)</Label>,
                              variant: 'darken',
                            }}
                            errors={{ ...errors }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <TipoYNumeroWrapper>
                            {/* Tipo de documento */}
                            <TipoDocumentoWrapper>
                              <Select
                                options={optionsTipoDocumento}
                                name="tipoDocumento"
                                control={control}
                                rules={{ required: false }}
                                placeholder={t('signUp.documento.tipo.placeholder')}
                                fieldOptions={{
                                  label: t('signUp.documento.tipo.label'),
                                  variant: 'darken',
                                  maxHeight: '120px',
                                }}
                                reactSelectOptions={{ isClearable: false, isSearchable: false }}
                                errors={{ ...errors }}
                              />
                            </TipoDocumentoWrapper>

                            {/* Número de documento */}
                            <NumeroWrapper>
                              <Input
                                id="numeroDocumento"
                                name="numeroDocumento"
                                type="text"
                                rules={register(formRulesSettings.numeroDocumento) as any}
                                placeholder={t('signUp.documento.numero.placeholder')}
                                fieldOptions={{
                                  variant: 'darken',
                                  maxLength: 20,
                                }}
                                errors={{ ...errors }}
                              />
                            </NumeroWrapper>
                          </TipoYNumeroWrapper>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        {/* Número de telefono */}
                        <FormGroup>
                          <Controller
                            name="celular"
                            control={control}
                            rules={{ required: true }}
                            render={({ onChange, value }) => (
                              <PhoneInput
                                value={value}
                                rules={{ required: true }}
                                onChange={(numero, isFormatValid) => {
                                  if (!isFormatValid) {
                                    setError('celular', {})
                                  } else {
                                    onChange(numero)
                                    trigger('celular')
                                  }
                                }}
                                defaultPrefix={sitePhonePrefix[ID_PAIS]}
                                placeholder={t('signUp.telefono.placeholder')}
                                fieldOptions={{
                                  label: t('signUp.telefono.label'),
                                  formatErrorText: t('signUp.telefono.format'),
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        {/* Email */}
                        <FormGroup>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            rules={register(formRulesSettings.email) as any}
                            onChange={() => setShowMessageError(false)}
                            disabled={!!location.state?.email}
                            placeholder={t('signUp.ingresaEmail')}
                            fieldOptions={{
                              label: <Label>Email</Label>,
                              variant: 'darken',
                              before: <Icon name="icon-light-email" color="#3D47F5" size="22" />,
                            }}
                            errors={{ ...errors }}
                          />

                          {showMessageError && <MessageError>El email ya está en uso.</MessageError>}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        {/* pass */}
                        <FormGroup>
                          <Input
                            id="password"
                            name="password"
                            type="password"
                            rules={register(formRulesSettings.password) as any}
                            placeholder={t('signUp.ingresaContrasena')}
                            fieldOptions={{
                              label: <Label>Contraseña</Label>,
                              variant: 'darken',
                              before: <Icon name="icon-light-lock" color="#3D47F5" size="22" />,
                            }}
                            errors={{ ...errors }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </ContainerFluid>
                  <HelperRequired>Campos obligatorios</HelperRequired>
                  <TermsAndConditions setAcceptAccountCreation={setAcceptAccountCreation} />
                  {BMEC && <DataProtectionPolicy onCheckProtectionPolicy={setDataProtectionPolicy} />}
                  <AutorizeNewsletter setSuscripciones={setSuscripciones} />
                  <SubmitButton
                    id="crearCuenta"
                    idForm="form-signup"
                    isLoading={signUpStates.loading}
                    disabled={handleDisbled()}
                  >
                    Crear cuenta
                  </SubmitButton>
                  <LinkSignUp />
                </form>
                {isMobile && (
                  <LinkEmpresasWrapper>
                    <Link href="/empresas/registro" linkoptions={{ variant: 'primary', type: 'link', size: 'large' }}>
                      {t('signUp.linkEmpresas')}
                    </Link>
                  </LinkEmpresasWrapper>
                )}
              </Col>

              {isDesktop && (
                <Col xl={5}>
                  <Image />
                </Col>
              )}
            </Row>
          </MainContainer>
        </Page>
      </LayoutSingleHeader>
    </Suspense>
  )
}

const states = ({ authStore, applicantStore, staticStore }) => {
  const { signUp: signUpStore } = authStore
  const { states: signUpStates } = signUpStore
  return {
    signUpStates,
    applicantId: applicantStore?.applicant?.id,
    optionsTipoDocumento: staticStore.tipoDocumento,
  }
}

export default connect(states)(Component)
