import { Link } from '@navent-jobs/ui-kit/components/link'
import styled from 'styled-components'

export const GptwCard = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: #ffff;
  border: 1px solid #e02929;
  margin-top: ${({ isFichaAviso }) => (isFichaAviso ? 0 : '16px')};
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 20px 10px;

  ${({ mobileView, theme }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      padding: 33px;
    }
  `}
`

export const Logo = styled.img`
  width: 100%;
  min-height: 120px;
  min-width: 120px;

  ${({ mobileView, theme }) =>
    mobileView
      ? `
        @media (min-width: 992px) {
          min-width: 62px;
        }
        @media (min-width: 1060px) {
          min-width: 70px;
        }
        @media (min-width: 1120px) {
          min-width: 80px;
        }
        @media (min-width: 1150px) {
          min-width: 100px;
        }
        @media (min-width: 1260px) {
          min-width: 110px;
        }
      `
      : `
    @media (min-width: ${theme.screen.smMin}) {
      max-height: 154px;
    }
  
    @media (min-width: ${theme.screen.mdMin}) {
      max-height: 204px;
    }
  `}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #e02929;
  padding: 16px 16px 16px 6px;

  ${({ theme, mobileView, isFichaAviso }) =>
    mobileView
      ? ''
      : `
    @media (min-width: 420px) {
      font-size: 24px;
      line-height: 40px;
      padding: 4px 6px 4px 12px;
    }
  
    @media (min-width: ${theme.screen.mdMin}) {
      font-size: ${isFichaAviso ? '24px' : '32px'};
      line-height: ${isFichaAviso ? '40px' : '50px'};
      padding-right: 30px;
    }
  `}
`

export const LinkCustom = styled(Link)`
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 0px;

  && {
    color: #e02929 !important;
    border-color: #e02929;
  }
  &:hover {
    background-color: #f5abab;
  }

  ${({ mobileView, isFichaAviso }) =>
    mobileView
      ? ''
      : `
    @media (min-width: 340px) and (max-width: 370px) {
      font-size: 14px;
    }
  
    @media (min-width: 370px) {
      font-size: 16px;
    }
  
    @media (min-width: 500px) {
      max-width: ${isFichaAviso ? '275px' : '311px'};
    }
  `}
`

export const CustomText = styled.p`
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 16px;

  ${({ isFichaAviso }) =>
    isFichaAviso
      ? `
    font-size: 24px;
    line-height: 40px;
  `
      : ''}
`
