import React, { useContext, useEffect, useState } from 'react'

import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Toggle } from '@navent-jobs/ui-kit/components/toggle'
import { ThemeContext } from 'styled-components'

import { useSearchKeyword } from '../../../hooks/search-keyword'

import {
  ToogleSidebarMenu,
  LayerOneFilterScreenComponent,
  LayerTwoFilterScreenComponent,
  LayerOneSidebarTitle,
  LayerOneSidebarFooter,
  SidebarMenu,
  RemoveAllFilters,
  ToggleContainer,
  IdleFilter,
  DisabledFilterContainer,
  FilterIcon,
  NewTag,
  CustomText,
  Divider,
  NewFilterRow,
  SelectedFilter,
  FilterBadge,
  IconWrapper,
} from './mixins'
import FiltersWithSearch from '../filters-with-search'

const ActiveFilter = ({ filter, deleteFilter, customStyles = {}, colors }) => {
  const { optionSelected, type } = filter

  return (
    <>
      <IdleFilter onClick={() => deleteFilter(type, null)} styles={customStyles}>
        <SelectedFilter>{optionSelected[0].name || optionSelected[0].value}</SelectedFilter>
        <FilterIcon name="icon-light-close" size="24" color={colors.secondary.normal} />
      </IdleFilter>
    </>
  )
}

const Filter = ({
  filter,
  openFilter,
  performFiltering,
  filterSelected,
  searchQueryFacet,
  setSearchQueryFacet,
  searchFacetWithMetric,
  searchFacet,
  colors,
}) => {
  const { options, type, facets, icon, label, optionSelected } = filter
  const caret = filter?.type === filterSelected?.type ? 'up' : 'down'

  return options && options.withCheckbox ? (
    <>
      <IdleFilter>
        <FilterIcon name={icon} size="16" color="#231F20" leftSide />
        {label}
        <ToggleContainer>
          <Toggle
            size="small"
            onChange={() =>
              optionSelected
                ? performFiltering(type, null)
                : performFiltering(type, { value: facets[0].idSemantico || facets[0].id, label: facets[0].name })
            }
            isChecked={optionSelected}
          />
        </ToggleContainer>
      </IdleFilter>
      <Divider />
    </>
  ) : (
    <>
      {!optionSelected ? (
        <>
          <IdleFilter onClick={() => openFilter(filter)}>
            <NewFilterRow>
              {label}
              {options && options.withLabelNew && <NewTag>Nuevo</NewTag>}
            </NewFilterRow>
            <FilterIcon name={`icon-light-caret-${caret}`} size="24" color={colors.black84} />
          </IdleFilter>
          {filterSelected?.type === filter.type && (
            <LayerTwoFilterScreenComponent>
              <FiltersWithSearch
                handleFacetSelected={searchFacetWithMetric}
                filterSelected={filterSelected}
                searchQueryFacet={searchQueryFacet}
                setSearchQueryFacet={setSearchQueryFacet}
              />
            </LayerTwoFilterScreenComponent>
          )}
        </>
      ) : (
        <ActiveFilter filter={filter} deleteFilter={searchFacet} colors={colors} />
      )}
      <Divider />
    </>
  )
}

const DisabledFilter = ({ filter, colors }) => {
  const { label } = filter

  return (
    <>
      <DisabledFilterContainer>
        {label}
        <FilterIcon name="icon-light-caret-down" size="24" color={colors.black84} />
      </DisabledFilterContainer>
      <Divider />
    </>
  )
}

export const FilterScreen = ({
  filters,
  filtersApplied,
  performFiltering,
  deleteAll,
  selectFilter,
  filterSelected,
  metric,
  onSearch,
  onRemoveAll,
}) => {
  const [filtersOpened, setFiltersOpened] = useState(false)
  const [searchQueryFacet, setSearchQueryFacet] = useState<string | undefined>(undefined)
  const { searchKeyword } = useSearchKeyword()
  const { colors } = useContext<{ colors }>(ThemeContext)
  const [height, setHeight] = useState(0)
  const filterBadge = filtersApplied?.length || 0

  const focusSearchInput = () => {
    setTimeout(() => document.getElementById('search-facets')?.focus(), 500)
  }

  const filterQueryApplied = filtersApplied?.find(f => f.id === 'query')
  const busquedaFilter = {
    type: 'busqueda',
    optionSelected: [
      {
        name: searchKeyword || filterQueryApplied?.value?.split('-').join(' '),
      },
    ],
  }

  const openFilter = filter => {
    selectFilter(filter, filterSelected)
    focusSearchInput()
    setSearchQueryFacet('')
  }

  const searchFacet = (type, facetSelected) => {
    onSearch(type, facetSelected)
    setFiltersOpened(true)
  }

  const searchFacetWithMetric = (filter, { facet }) => {
    if (metric) metric(filter, facet.name)
    searchFacet(filter.type, { value: facet.idSemantico || facet.id, label: facet.name })
  }

  const getHeight = () => {
    if (filterQueryApplied) {
      return 9
    }
    return -42
  }

  useEffect(() => {
    setHeight(getHeight())
  }, [filterQueryApplied])

  if (!filters) {
    return null
  }

  return (
    <>
      <ToogleSidebarMenu onClick={() => setFiltersOpened(true)}>
        <IconWrapper>
          <Icon size="20" color="rgba(0, 0, 24, 0.84)" name="icon-light-options-2" />
          {filterBadge > 0 && <FilterBadge>{filterBadge}</FilterBadge>}
        </IconWrapper>
      </ToogleSidebarMenu>

      <SidebarMenu
        position="right"
        closeType="close"
        visible={filtersOpened}
        handleClose={() => setFiltersOpened(false)}
      >
        <LayerOneSidebarTitle>Filtros</LayerOneSidebarTitle>

        <Divider />

        {filterQueryApplied && (
          <>
            <ActiveFilter
              filter={busquedaFilter}
              deleteFilter={searchFacet}
              customStyles={{ paddingRight: 18 }}
              colors={colors}
            />
            <Divider />
          </>
        )}

        <LayerOneFilterScreenComponent height={height}>
          {filters.map(filter => {
            const { facets, optionSelected } = filter
            const thereIsAnOptionSelected = optionSelected && optionSelected.length > 0
            const thereAreFacets = facets && facets.length > 0
            const filterDisabled = !thereIsAnOptionSelected && !thereAreFacets

            if (filterDisabled) {
              return <DisabledFilter filter={filter} colors={colors} />
            }

            return (
              <Filter
                key={filter.type}
                filter={filter}
                openFilter={openFilter}
                performFiltering={performFiltering}
                filterSelected={filterSelected}
                searchQueryFacet={searchQueryFacet}
                setSearchQueryFacet={setSearchQueryFacet}
                searchFacetWithMetric={searchFacetWithMetric}
                searchFacet={searchFacet}
                colors={colors}
              />
            )
          })}
        </LayerOneFilterScreenComponent>

        <LayerOneSidebarFooter>
          {deleteAll ? (
            <>
              <RemoveAllFilters
                onClick={() => {
                  onRemoveAll()
                }}
              >
                <Icon name="icon-light-trash-2" color={colors.primary.normal} size="24" />
              </RemoveAllFilters>
              <RemoveAllFilters
                onClick={() => {
                  setFiltersOpened(false)
                }}
              >
                <CustomText active>Confirmar</CustomText>
              </RemoveAllFilters>
            </>
          ) : (
            <>
              <Icon name="icon-light-trash-2" color="#C3C2C8" size="24" />
              <CustomText>Confirmar</CustomText>
            </>
          )}
        </LayerOneSidebarFooter>
      </SidebarMenu>
    </>
  )
}
