import React, { lazy, Suspense, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

// Tools
import { DataLayer } from '../../constants/gtm-events-home'
import { getCookieData, setCookieData, userLoggedIn } from '../../utils/cookies'
import { IS_ZONA_JOBS, SITE_ID } from '../../constants'

// Components
import HomeHeader from './components/home-header'
import Confetti from './components/confetti'
import { setConfetti } from '../../redux/actions/confetti'
import { getRemoteConfigFeature } from '../../services/firebase'

// Lazy imports
const LayoutAuthHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../components/layouts/layout-auth-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../components/PageLoader'))
const LogosEmpresas = lazy(() => import(/* webpackChunkName: "logos-empresas" */ './components/logos-empresas'))
const SuggestionsForUsers = lazy(
  () => import(/* webpackChunkName: "suggestions-for-users" */ './components/suggestions-for-users')
)
const SeoFooter = lazy(() => import(/* webpackChunkName: "seo-footer" */ './components/seo-footer'))
const Metas = lazy(() => import(/* webpackChunkName: "metas" */ './components/metas'))
const Landings = lazy(() => import(/* webpackChunkName: "new-features" */ './components/landings'))
const HomeHeaderZona = lazy(() => import(/* webpackChunkName: "header-zonajobs" */ './components/zonajobs/home-header'))
const ListadoHome = lazy(() => import(/* webpackChunkName: "listado" */ './components/zonajobs/listado'))
const Perfil = lazy(() => import(/* webpackChunkName: "perfil" */ '../candidato/perfil'))

const Home = props => {
  const dispatch = useDispatch()
  const isUserLoggedIn = userLoggedIn() || !!props.applicant

  const initConfetti = async () => {
    const CONFETTI_HOME_ENABLED = await getRemoteConfigFeature(`CONFETTI_FERIA_${SITE_ID}`)
    if (CONFETTI_HOME_ENABLED) {
      if (getCookieData('confetti', document.cookie) !== 'true') {
        setCookieData('confetti', 'true', 86400 * 7) // dura una semana
        setTimeout(() => dispatch(setConfetti()), 1000) // se prende
        setTimeout(() => dispatch(setConfetti()), 20000) // se apaga solo luego de 20 segs
      }
    }
  }

  useEffect(() => {
    DataLayer.handlerPageViewTraking(isUserLoggedIn)
  }, [isUserLoggedIn])

  useEffect(() => {
    initConfetti()
  }, [])

  return (
    <Suspense fallback={<PageLoader />}>
      <Confetti />
      <LayoutAuthHeader {...props}>
        <>
          <Metas />
          {/* Home para portales BUMERAN */}
          {!IS_ZONA_JOBS &&
            (isUserLoggedIn ? (
              <Perfil {...props} />
            ) : (
              <>
                <HomeHeader />
                <Landings />
                <LogosEmpresas />
                <SuggestionsForUsers />
                {/* Vamos a dejar comentada la seccion de blog por ahora
                <NewFeature />
               */}
                <SeoFooter />
              </>
            ))}
          {/* Home Zonajobs */}
          {IS_ZONA_JOBS && (
            <>
              <HomeHeaderZona />
              <LogosEmpresas />
              <ListadoHome />
              <SeoFooter />
            </>
          )}
        </>
      </LayoutAuthHeader>
    </Suspense>
  )
}

const state = ({ applicantStore }) => {
  return {
    applicant: applicantStore.applicant,
  }
}

export default connect(state)(Home)
