import styled from 'styled-components'

export const Title = styled('div')`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black84};
`

export const Description = styled('div')`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`
