import { FeriaDto } from '../../redux/types/feria'
import { FiltersSettings } from '../../redux/types/filters'

interface ApplicantInterface {
  nombre: string
  apellido: string
  fotoURL: string
  email: string
}

interface Item {
  href: string
  content: string | JSX.Element
  key?: string
  visibleIn: string[]
  visibleInPage: string[]
  portals: string[]
  metric?: () => void
}

export interface HeaderInterface {
  applicant?: ApplicantInterface
  locations?: []
  location: { pathname: string }
  hideWithPath?: string[]
  filtersSettings: FiltersSettings | null
  singleHeader?: boolean
  feria?: FeriaDto
}

export interface UserDropdownHeaderInterface {
  applicant?: ApplicantInterface
  isUserLoggedIn?: boolean
}

export interface NavbarContentInterface {
  isNavbarContentVisible?: boolean
  isUserLoggedIn?: boolean
  isMobile?: boolean
  menuItems?: {
    key: string
    type: string
    visibleIn: string[]
    items: Item[]
  }[]

  applicant?: ApplicantInterface
  location: { pathname: string }
  filtersSettings: FiltersSettings | null
}

export const NavbarContentDefault: NavbarContentInterface = {
  isNavbarContentVisible: true,
  isUserLoggedIn: false,
  isMobile: true,
  location: { pathname: '' },
  filtersSettings: null,
}
