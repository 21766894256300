import React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Lead } from './mixins'

const Component = () => {
  const { t } = useTranslation()
  return (
    <>
      <Heading level={2}>{t('¡Este enlace ya venció!')}</Heading>
      <Lead>{t('Pero, no te preocupes, siempre hay una nueva oportunidad.')}</Lead>
    </>
  )
}

export default Component
