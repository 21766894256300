import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import moment from 'moment'

// uikit
import { Col, Row } from '@navent-jobs/ui-kit/components/grid'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// style
import {
  CardContainer,
  ApplicantName,
  CustomIcon,
  Text,
  CustomRow,
  CustomRowEnd,
  Spacer,
  Recommended,
  Title,
  Description,
  RatingRow,
  RatingContainer,
  DisclaimerWrapper,
  Disclaimer,
  DisclaimerText,
} from './mixins'

// components
import CompanyRating from '../../../card-company/rating'

const Component = ({ name, applicantReview }) => {
  const { t } = useTranslation()
  const { colors } = useContext<{ colors }>(ThemeContext)
  const title = applicantReview.comment?.title?.trim() || ''
  const description = applicantReview.comment?.description?.trim() || ''

  const recommendedContent = () => {
    if (applicantReview.recommends === null || applicantReview.recommends === undefined) {
      if (!applicantReview.answer) return null

      return <Text>{t(`review.comments.options.${applicantReview.answer}`, { defaultValue: null })}</Text>
    }

    return (
      <>
        <CustomIcon
          name={applicantReview.recommends ? 'icon-light-thumbs-up' : 'icon-light-thumbs-down'}
          color="#666674"
          size="16"
        />
        <Text>{t(`review.comments.${applicantReview.recommends ? 'recomendation' : 'noRecommendation'}`)}</Text>
      </>
    )
  }

  return (
    <CardContainer>
      <Row noGutter>
        <Col>
          <CustomRow>
            <Icon name="icon-light-user-circle" color={colors.primary.normal} size="20" />
            <ApplicantName>{name}</ApplicantName>
          </CustomRow>
        </Col>
        <Col>
          <CustomRowEnd>
            <Text>{moment(applicantReview.createdAt).format('D [de] MMMM, YYYY')}</Text>
          </CustomRowEnd>
        </Col>
      </Row>
      <Spacer>
        <RatingRow noGutter>
          <RatingContainer>
            <CompanyRating reviewAverage={applicantReview.rating} />
          </RatingContainer>
          <Recommended>{recommendedContent()}</Recommended>
        </RatingRow>
        <Row noGutter>
          <Col>
            {title && (
              <Row>
                <Title>{title}</Title>
              </Row>
            )}
            {description && (
              <Row>
                <Description>{description}</Description>
              </Row>
            )}
          </Col>
        </Row>
      </Spacer>
      <DisclaimerWrapper>
        <Disclaimer>
          <Icon name="icon-light-eye-open" size="20" color={colors.black60} />
          <DisclaimerText>{t('review.comments.disclaimer')}</DisclaimerText>
        </Disclaimer>
      </DisclaimerWrapper>
    </CardContainer>
  )
}

export default Component
