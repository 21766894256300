import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const body = styled('div')

export const Background = styled('div')`
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.secondary.lighten};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
`

export const ContentWrapper = styled('div')`
  padding: 12px;
  margin: 0px 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  font-size: 18px;
  line-height: 24px;
  position: absolute;
  bottom: 12px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 12px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const Label = styled('div')`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black84};
  margin: 0 0 8px 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin: 0 8px 0 0;
  }
`

export const PriceButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`

export const PriceText = styled.text`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black72};
  margin: 0 4px 0 0;
`

export const TaxText = styled.p`
  color: ${({ theme }) => theme.colors.black48};
  margin: 0;
  font-size: 12px;
  line-height: 18px;
`

export const CustomButton = styled(Button)`
  width: 160px;

  &:last-child {
    margin-left: 16px;
  }
`

export const ButtonText = styled.span`
  padding: 1px 0px 1px 0px;
`
