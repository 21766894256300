import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { OptionContainer, OptionIcon, OptionKeyword, OptionLabel, OptionTitle } from '../utils/mixins'

const OptionLabels = ({ option, noBold = false, hideCaret = false }) => {
  const { colors } = useContext<{ colors }>(ThemeContext)

  return (
    <OptionContainer
      isMobile
      noBold={noBold}
      href={option?.url}
      linkoptions={{ size: 'small', variant: 'gray', type: 'link', native: false }}
    >
      <OptionTitle>
        <OptionIcon name="icon-light-clock" size="16" color={colors.black48} />
        <OptionLabel>{option && <OptionKeyword>{option}</OptionKeyword>}</OptionLabel>
      </OptionTitle>
      {!hideCaret && <OptionIcon name="icon-light-caret-right" size="16" color={colors.black48} />}
    </OptionContainer>
  )
}

export default OptionLabels
