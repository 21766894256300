import React from 'react'
import { isEmpty } from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { planConfig } from '../../constants/planConfig'
import { InfoCol } from '../../mixins'
import {
  MainInfoCard,
  Company,
  CardHeader,
  LogoWrapper,
  Logo,
  CustomText,
  NewTag,
  Title,
  Description,
  DataRowTitle,
  InfoMobile,
  CardBody,
  HeaderCol,
  HeaderContainer,
  DateRow,
  HeaderColContainer,
  DataCol,
  Rating,
} from './mixins'

const MAX_CHARS_TITLE = 70

const showNewTag = (estadoPostulacion, isRead, tipoAviso) => {
  return !(estadoPostulacion || isRead) && planConfig[tipoAviso].showNewTag
}

const defineCompanyName = ({ confidencial, tipoAviso, empresa, mobileFormat, isRead, showLogo }) => {
  if (confidencial) {
    return <Company mobileFormat={mobileFormat}>Confidencial</Company>
  }

  return (
    planConfig[tipoAviso].showCompanyName && (
      <Company mobileFormat={mobileFormat} isRead={isRead} showLogo={showLogo}>
        {empresa}
      </Company>
    )
  )
}

const makeTitleRow = props => {
  const {
    isDesktop,
    tipoAviso,
    empresa,
    avisoNuevo,
    cantidadDeDias,
    estadoPostulacion,
    isRead,
    confidencial,
    promedioEmpresa,
    mobileFormat,
    showLogo,
  } = props

  const Wrapper = ({ children }) =>
    !isDesktop || mobileFormat ? (
      <DateRow mobileFormat={mobileFormat} isRead={isRead}>
        {children}
      </DateRow>
    ) : (
      <>{children}</>
    )

  return (
    <DataRowTitle mobileFormat={mobileFormat}>
      {defineCompanyName({ confidencial, tipoAviso, empresa, mobileFormat, isRead, showLogo })}

      {isDesktop && !mobileFormat && planConfig[tipoAviso].showCompanyName && empresa && (
        <Company mobileFormat={mobileFormat}> -</Company>
      )}

      <Wrapper>
        {!confidencial && promedioEmpresa && (
          <>
            <Rating>{promedioEmpresa.toFixed(1)}</Rating>
            <Icon name="icon-bold-star" color="#FAB306" size="14" />
            <Company> -</Company>
          </>
        )}

        {showNewTag(estadoPostulacion, isRead, tipoAviso) && avisoNuevo && <NewTag>Nuevo</NewTag>}
        <CustomText>{cantidadDeDias}</CustomText>
      </Wrapper>
    </DataRowTitle>
  )
}

const showDescription = (tipoAviso, detalle, mobileFormat, clamp) => {
  return (
    planConfig[tipoAviso].showDescription &&
    !isEmpty(detalle) && (
      <CardBody>
        <Description clamp={clamp} mobileFormat={mobileFormat}>
          {ReactHtmlParser(detalle)}
        </Description>
      </CardBody>
    )
  )
}

const Component = ({
  tipoAviso,
  confidencial,
  logoURL,
  estadoPostulacion,
  isDesktop,
  empresa,
  cantidadDeDias,
  avisoNuevo,
  titulo,
  detalle,
  promedioEmpresa,
  isRead,
  mobileFormat,
  clamp,
  hideCompanyRow = false,
}) => {
  const showLogo = !isEmpty(logoURL) && planConfig[tipoAviso].showLogo
  const showTitle = (
    <Title
      mobileFormat={mobileFormat}
      hideCompanyRow={hideCompanyRow}
      buttons={false}
      tipoAviso={tipoAviso}
      hasDescription={planConfig[tipoAviso].showDescription && !isEmpty(detalle)}
    >
      {titulo.length > MAX_CHARS_TITLE ? `${titulo.substring(0, MAX_CHARS_TITLE)}...` : titulo}
    </Title>
  )

  return (
    <HeaderColContainer>
      <HeaderContainer mobileFormat={mobileFormat} tipoAviso={tipoAviso}>
        <MainInfoCard mobileFormat={mobileFormat}>
          <CardHeader
            hasCompanyName={planConfig[tipoAviso].showCompanyName && !confidencial}
            hasLogo={showLogo}
            hideCompanyRow={hideCompanyRow}
          >
            {showLogo && (
              <LogoWrapper>
                <Logo src={logoURL} />
              </LogoWrapper>
            )}

            <HeaderCol mobileFormat={mobileFormat} hasLogo={showLogo}>
              {isDesktop && !mobileFormat && showTitle}
              {!hideCompanyRow &&
                makeTitleRow({
                  isDesktop,
                  tipoAviso,
                  empresa,
                  avisoNuevo,
                  cantidadDeDias,
                  estadoPostulacion,
                  confidencial,
                  promedioEmpresa,
                  isRead,
                  showLogo,
                  mobileFormat,
                })}
            </HeaderCol>
          </CardHeader>

          {isDesktop && !mobileFormat && showDescription(tipoAviso, detalle, mobileFormat, clamp)}
        </MainInfoCard>
      </HeaderContainer>

      {(!isDesktop || mobileFormat) && (
        <InfoMobile>
          <DataCol>
            <InfoCol mobileFormat={mobileFormat}>{showTitle}</InfoCol>
          </DataCol>

          <DataCol>
            <InfoCol mobileFormat={mobileFormat}>{showDescription(tipoAviso, detalle, mobileFormat, clamp)}</InfoCol>
          </DataCol>
        </InfoMobile>
      )}
    </HeaderColContainer>
  )
}

export default Component
