import styled from 'styled-components'
import { Title } from '@navent-jobs/ui-kit/components/title'

export const Heading = styled(Title)`
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 32px;
    line-height: 40px;
  }
`
