import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlreadyReviewedText, Title, CustomLink } from './mixins'
import { userLoggedIn } from '../../../../utils/cookies'
import { DataLayersReviews } from '../../../../constants/gtm-events-reviews'

const Component = ({ applicantReview, idCompany, nameCompany }) => {
  const isUserLogged = userLoggedIn()
  const { t } = useTranslation()

  const handleOnClick = () => {
    if (!applicantReview) {
      DataLayersReviews.goToReviewReviewd(window.location.pathname)
    }
  }

  return applicantReview ? (
    <AlreadyReviewedText>{t('review.access.buttonReviewed')}</AlreadyReviewedText>
  ) : (
    <>
      <Title>{t('review.access.title')}</Title>
      <div onClick={handleOnClick}>
        <CustomLink
          href={
            isUserLogged ? `/perfiles/${idCompany}/calificacion` : `/login?returnTo=/perfiles/${idCompany}/calificacion`
          }
          disabled={applicantReview}
          linkoptions={{ variant: 'primary', type: 'fill', size: 'medium' }}
        >
          {t('review.result.buttonReview')}
        </CustomLink>
      </div>
    </>
  )
}

export default Component
