import {
  GET_SETTINGS_SORT,
  GET_SETTINGS_SORT_SUCCESS,
  GET_POSTULACIONES_SETTINGS_SORT,
  GET_POSTULACIONES_SETTINGS_SORT_SUCCESS,
  GET_EMPRESAS_SETTINGS_SORT,
  GET_EMPRESAS_SETTINGS_SORT_SUCCESS,
  initialState,
  SortActions,
  SortState,
  SET_EMPRESAS_SETTINGS_SORT,
  SET_EMPRESAS_SETTINGS_SORT_SUCCESS,
} from '../types/sort'

const reducers = (state: any = initialState, action: SortActions): SortState => {
  switch (action.type) {
    case GET_SETTINGS_SORT:
    case GET_POSTULACIONES_SETTINGS_SORT:
    case GET_EMPRESAS_SETTINGS_SORT:
      return {
        ...state,
      }
    case GET_SETTINGS_SORT_SUCCESS:
    case GET_POSTULACIONES_SETTINGS_SORT_SUCCESS:
    case GET_EMPRESAS_SETTINGS_SORT_SUCCESS:
    case SET_EMPRESAS_SETTINGS_SORT:
    case SET_EMPRESAS_SETTINGS_SORT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default reducers
