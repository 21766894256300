import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { moneyIconName, showSalarioPromedio } from '../../../constants/postulaciones/statistics'
import { SITE_ID } from '../../../constants'
import { getAverageSalaryRange } from '../utils'

const useSettingsStatistics = ({
  cantidadPostulaciones,
  edadPromedio,
  salarioPromedio,
  nivelEducativoFrecuente,
  tipoAviso,
}) => {
  const { colors } = useContext<{ colors }>(ThemeContext)

  const freemiumId = 2001
  const salaryEnable = tipoAviso !== freemiumId

  const quantityPostulaciones =
    cantidadPostulaciones > 1 ? `${cantidadPostulaciones} postulados` : `${cantidadPostulaciones} postulado`

  const quantityAges = edadPromedio && edadPromedio.replace(/-/g, ' a ')

  const response = [
    {
      key: 'promedio-postulaciones',
      title: 'Cantidad de postulaciones',
      icon: {
        color: colors.black24,
        name: 'icon-light-people',
        size: '48',
      },
      label: quantityPostulaciones,
    },
    {
      key: 'promedio-educacion',
      title: 'Nivel educativo más frecuente',
      icon: {
        color: colors.black24,
        name: 'icon-light-graduation-cap',
        size: '48',
      },
      label: nivelEducativoFrecuente,
    },
  ]

  if (showSalarioPromedio) {
    response.splice(1, 0, {
      key: 'promedio-salario',
      title: 'Salario promedio pretendido',
      icon: {
        color: colors.black24,
        name: moneyIconName[SITE_ID || 'BMAR'],
        size: '48',
      },
      label: getAverageSalaryRange({ salaryEnable, salarioPromedio }),
    })
  } else {
    response.splice(1, 0, {
      key: 'promedio-edades',
      title: 'Promedio de edad de los postulados',
      icon: {
        color: colors.black24,
        name: 'icon-light-person-add',
        size: '48',
      },
      label: quantityAges,
    })
  }

  return response
}

export default useSettingsStatistics
