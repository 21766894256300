import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  position: relative;
  height: 145px;
  width: 160px;
  margin: auto;

  ${({ mobileView, theme }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      height: 160px;
      margin: 0;
    }
  `}
`

export const ValueText = styled.p`
  color: ${({ theme }) => theme.colors.black84};
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  margin: 0;
`

export const TextContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 15%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  // This is important to preserve the chart interactivity
  pointer-events: none;
`

export const Text = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black84};
  margin: 0;
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: 8px;
  padding: 8px;
`

export const Spacer = styled.div`
  margin-bottom: 16px;
`
