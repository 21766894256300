import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const CardContentWrapper = styled.div`
  display: block;
  position: relative;
  color: rgba(0, 0, 24, 0.6);
  height: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: rgba(0, 0, 24, 0.6);
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  height: 100%;
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
`

export const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 90%;
  color: ${({ theme }) => theme.colors.black84};

  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    width: ${({ iconBtn }) => (iconBtn ? '55%' : '40%')};
  }
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ iconBtn }) => (iconBtn ? '45%' : '60%')};
`

export const CustomLink = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary.normal};
`

export const CustomButton = styled.button`
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline: 0;
`
