import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import Sidebar from '../sidebar'

const FilterSidebarComponent = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
`

export const FilterBar = styled(FilterSidebarComponent)`
  max-height: calc(100% - 112px);
`

export const SidebarMenuContent = styled(FilterSidebarComponent)`
  overflow: auto;
  max-height: calc(100% - 56px);
  padding: 0px 16px;
`

export const FilterBarApplied = styled('ul')`
  padding: 0;
  margin-top: 0;
  list-style-type: none;
  text-align: justify;
  display: inline;
`

export const ActiveFilterTag = styled('li')`
  max-width: 100%;
  display: inline-block;
  margin: 0px 12px 12px 0px;

  &:last-child {
    margin-right: 0px;
  }
`

export const FilterBarFooter = styled('div')`
  padding: 10px 0;
  margin: 0 0 -6px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const FilterBarRemoveFilters = styled('button')`
  border: 0;
  padding: 0;
  background: transparent;

  &:active,
  &:focus {
    outline: 0;
  }
`

export const SidebarMenu = styled(Sidebar)`
  width: 100%;
  height: 100%;
  background-color: #ffe600;
  position: fixed;
  top: 0;
  ${({ position }) => (position === 'right' ? 'left: 0;' : 'right: 0;')}
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
`

export const SidebarMenuTitle = styled('h3')`
  font-weight: 600;
  font-size: 18px;
  height: 56px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  padding: 0px 16px 0px 50px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
`

export const SidebarMenuFacet = styled('button')`
  border: 0px;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  background-color: transparent;
  color: rgba(0, 0, 24, 0.6);
  font-size: 14px;
  line-height: 20px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  &:active,
  &:focus {
    outline: 0;
  }
`

export const RemoveFilter = styled('button')`
  border: 0;
  padding: 0;
  background: transparent;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  &:active,
  &:focus {
    outline: 0;
  }
`

export const CloseButton = styled('button')`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.normal};
  &:active,
  &:focus {
    outline: 0;
  }
`

export const ActiveFilter = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  min-width: 76px;
  max-width: inherit;
  padding: 4px 4px 4px 6px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  color: white;
  background-color: ${({ theme }) => theme.colors.secondary.normal};
`

export const FilterName = styled('p')`
  margin: 0px 8px 2px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const FilterItem = styled('button')`
  width: 100%;
  height: 44px;
  background-color: ${({ theme }) => theme.colors.grey};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black84};
  padding: 0px 12px;
  margin: 0px 0px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 0px;
  border-radius: 8px;
  box-shadow: 0px;

  &:active,
  &:focus {
    outline: 0;
  }
`

export const FilterIcon = styled(Icon)`
  margin-right: 8px;
`
