import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  ImageForEmptyState,
  ElementsContainer,
  TextContainer,
  Subtitle,
  ComponentContainer,
  CustomButton,
  Heading,
} from './mixins'
import EmptyGuardados from '../../../../../../assets/images/empty-avisos-guardados.svg'
import EmptyGuardadosZona from '../../../../../../assets/images/empty-avisos-guardados-zona.svg'
import { IS_ZONA_JOBS } from '../../../../../../constants'

const EmptyState = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const goToSearch = () => {
    history.push('/empleos.html')
  }

  return (
    <ElementsContainer>
      <ImageForEmptyState src={IS_ZONA_JOBS ? EmptyGuardadosZona : EmptyGuardados} />
      <TextContainer>
        <Heading level={1}>{t('saveJobPosting.empty.title')}</Heading>
        <Subtitle>{t('saveJobPosting.empty.subtitle')}</Subtitle>
        <ComponentContainer>
          <CustomButton buttonoptions={{ variant: 'primary', size: 'medium', block: true }} onClick={goToSearch}>
            {t('saveJobPosting.empty.buttonText')}
          </CustomButton>
        </ComponentContainer>
      </TextContainer>
    </ElementsContainer>
  )
}

export default EmptyState
