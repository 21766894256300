import i18n from '../../../../i18n'

export const getLabel = (bar, myKey) => {
  if (bar.indexValue === myKey.indexValue && (!myKey.id || bar.id === myKey.id)) {
    return i18n.t('postulaciones.statistics.me')
  }
  return ''
}

export const getLabelColor = (bar, colors) => {
  if (colors.tertiary.normal === bar.color) {
    return colors.black100
  }
  return '#FFFFFF'
}

export const getGraphDef = (color, colors) => {
  return [
    {
      id: 'trama',
      type: 'patternLines',
      background: 'inherit',
      color: color[0] === colors.tertiary.normal ? 'white' : colors.aviso.infinit.brandPrimary,
      rotation: -45,
      lineWidth: 2,
      spacing: 10,
    },
  ]
}

export const getGraphFill = myKey => {
  return [
    {
      match: item => item.key === `${myKey.id}.${myKey.indexValue}`,
      id: 'trama',
    },
  ]
}
