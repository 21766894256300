import styled from 'styled-components'

export const SidebarMenuFacet = styled.button`
  border: 0px;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.black60};
  font-size: 14px;
  line-height: 20px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  &:active,
  &:focus {
    outline: 0;
  }
`

export const EmptySearchText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black48};
  text-align: center;
  padding: 0px 0px 16px;
`
