const bannerConsejos = {
  mejoraPerfil: {
    title: 'Mejorá tu perfil profesional',
  },
  sumaConocimientos: {
    title: 'Sumá tus conocimientos y habilidades',
    description:
      'Además de tus experiencias y estudios, agregá tanto tus conocimientos técnicos como tus cualidades relacionadas al desempeño de tu trabajo.',
  },
  informacionActualizada: {
    title: 'Mantené tu información actualizada',
    description:
      'Si cambiaste de empleo o tus datos de contacto, ¡registralo! Para los empleadores es esencial que tu perfil esté al día.',
  },
  experiencia: {
    title: 'Describí tu experiencia',
    description:
      'Ofrecé la mayor cantidad de detalle de las actividades que realizás o hayas realizado en tu puesto de trabajo.',
  },
}

export default bannerConsejos
