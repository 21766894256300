import React from 'react'
import { useTranslation } from 'react-i18next'

// mixins
import { Heading } from './mixins'

const Component = () => {
  const { t } = useTranslation()

  return <Heading level={2}>{t('signUp.title')}</Heading>
}

export default Component
