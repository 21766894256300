import styled from 'styled-components'
import { Title } from '@navent-jobs/ui-kit/components/title'

export const Component = styled('div')`
  background-color: white;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 20px;
  }
`

export const Heading = styled(Title)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black84};
  line-height: 20px;
  max-width: 200px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    max-width: initial;
  }

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const Image = styled('img')`
  max-width: 220px;
  position: absolute;
  right: -59px;
  top: -33px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) and (max-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 150px;
    right: -40px;
    top: -22px;
  }
`
