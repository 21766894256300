import { all } from 'redux-saga/effects'
import applicant from './applicant'
import locations from './locations'
import seoFooter from './seo-footer'
import listadoAvisos from './listado-avisos'
import homePage from './home-page'
import breadcrumbs from './breadcrumbs'
import activeAviso from './active-aviso'
import sort from './sort'
import statics from './statics'
import email from './email'
import filters from './filters'
import paymentMP from './payment-mp'
import curriculum from './descarga-curriculum'
import signIn from './sign-in'
import signUp from './sign-up'
import confirmCandidate from './confirm-candidate'
import changePassword from './change-password'
import activationEmail from './activation-email'
import account from './account'
import alertsSettings from './alerts-settings'
import logout from './logout'
import postularBar from './postular-bar'
import postulaciones from './postulaciones'
import companySummary from './company-summary'
import analysisCv from './analysis-cv'
import paymentInventory from './applicant/inventory'
import languageTest from './language-test'
import notificarions from './notifications'
import notificationSettings from './notification-settings'
import postulacionQrSagas from './postulacion-qr'
import saveJobPosting from './save-job-posting'
import messages from './messages'
import productos from './products'
import listadoEmpresas from './listado-empresas'
import marketing from './marketing'
import confirmReference from './confirm-reference'
import alertsV2 from './alerts-v2'
import review from './review'
import denuncia from './denuncia'
import actividad from './actividad'
import promedioSalarial from './promedioSalarial'
import suscriptions from './suscripcion'
import recomendados from './recomendados'
import cvParser from './cv-parser'
import empresasSeguidas from './save-company'
import feria from './feria'
import disponibleInmediato from './disponible-inmediato'
import disponibleOfertas from './disponible-ofertas'
import areaAds from './area-ads'

export default function* rootSaga() {
  yield all([
    applicant(),
    locations(),
    seoFooter(),
    listadoAvisos(),
    homePage(),
    breadcrumbs(),
    activeAviso(),
    sort(),
    statics(),
    email(),
    filters(),
    paymentMP(),
    curriculum(),
    signIn(),
    signUp(),
    confirmCandidate(),
    changePassword(),
    activationEmail(),
    account(),
    alertsSettings(),
    logout(),
    postularBar(),
    postulaciones(),
    productos(),
    companySummary(),
    analysisCv(),
    paymentInventory(),
    languageTest(),
    notificarions(),
    notificationSettings(),
    postulacionQrSagas(),
    saveJobPosting(),
    messages(),
    listadoEmpresas(),
    marketing(),
    confirmReference(),
    alertsV2(),
    review(),
    denuncia(),
    actividad(),
    promedioSalarial(),
    suscriptions(),
    recomendados(),
    cvParser(),
    empresasSeguidas(),
    feria(),
    disponibleInmediato(),
    disponibleOfertas(),
    areaAds(),
  ])
}
