const fichaAviso = {
  acciones: {
    seguir: 'Seguir empresa',
    siguiendo: 'Siguiendo',
    dejarDeSeguir: 'Dejar de seguir',
    denunciar: 'Denunciar aviso',
    compartir: 'Compartir',
  },
  anchorBar: {
    detalleDelAviso: 'Detalle del empleo',
    empresa: 'La empresa',
    avisosRelacionados: 'Empleos relacionados',
  },
  sections: {
    empresa: {
      industria: 'Industria',
      ubicacion: 'Ubicación',
      empleados: 'Tamaño de la empresa',
      verMasAvisos: 'Ver más avisos de la empresa',
    },
    details: {
      empresaInclusiva: 'Esta empresa recibirá tu {{cv}} anónimo sin foto, edad, género ni nacionalidad.',
    },
  },
  legales:
    'El contenido de este aviso es propiedad del anunciante. Los requisitos de la posición son definidos y administrados por el anunciante sin que {{nombreSitio}} sea responsable por ello.',
  avisosRelacionados: 'Empleos relacionados',
  postularBar: {
    activaTuCuenta: 'activa tu cuenta.',
    reenviarMail: 'Reenviarme el mail ',
    completaTuCV: 'completa tu {{cv}}.',
    actualizarSueldoCV: 'Actualizar sueldo en Mi {{cv}}',
    sueldoPretendido: 'Sueldo pretendido',
    ingresaSueldoPretendido: 'Ingresa tu sueldo bruto pretendido',
  },
  inputSalario: {
    helperText: {
      default: 'Este campo solo acepta carácteres numéricos.',
      error: 'Este campo es obligatorio',
      salarioPromedio: 'Ver el <0>salario promedio pretendido</0> Bruto por área.',
    },
  },
  postularme: 'Postularme',
  postulado: 'Postulado el {{fecha}}',
  postulacion: {
    incompleta: {
      estado: 'Postulación incompleta: ',
      completarMiCV: 'Completar mi {{cv}}',
      activarCuenta:
        'Para que la empresa reciba tu postulación, tienes que activar tu cuenta desde el email que te enviamos a',
      completarCV:
        'Para que la empresa reciba tu postulación, tienes que completar tu {{cv}} con tu experiencia laboral o con tus estudios.',
    },
  },
  salarios: {
    referenciaSalarial: 'Referencia salarial',
    descubreCuantoGana: 'Descubre cuánto gana un ',
  },
  busquedasRelacionadas: {
    title: 'Búsquedas Relacionadas',
    emptyState: 'No hay búsquedas relacionadas',
  },
  salaryLabelInfo: 'Recuerda que el sueldo bruto es el salario total sin las retenciones e impuestos.',
  knowMore: 'Conocé más.',
  cvMatch: {
    text: 'Tu {{cv}} se ajusta a esta oferta',
  },
  modalAvisos: {
    title: 'Sigue postulandote a más empleos',
    subtitle: 'Puede que estos avisos también sean de tu interés',
    secondaryButtonText: 'Volver al aviso',
    primaryButtonText: 'Ver más avisos',
  },
  calculadoraSalario: {
    empty: 'Ingresa tu sueldo bruto pretendido y calculamos un aproximado de cuánto te queda de sueldo neto',
    salaryDescription: 'Es un aproximado a tu sueldo neto.',
    modal: {
      title: 'Cálculo de sueldo neto',
      sueldoBrutoLabel: 'Sueldo bruto pretendido',
      sueldoNetoLabel: 'Sueldo neto aproximado',
      description:
        '<0>El sueldo neto</0> en {{pais}} se calcula restando del <0>sueldo bruto</0> (el importe total sumando impuestos y deducciones),',
      impuestos: '',
    },
  },
  descripcion: {
    titulo: 'Descripción del puesto',
    beneficios: 'Beneficios',
  },
}

export default fichaAviso
