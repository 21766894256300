import styled from 'styled-components'
import { Title } from '@navent-jobs/ui-kit/components/title'

export const Heading = styled(Title)`
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px;
  }
`

export const Lead = styled('p')`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 32px;
  }
`
