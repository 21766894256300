import React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Lead } from './mixins'

const Component = () => {
  const { t } = useTranslation()
  return (
    <>
      <Heading level={2}>{t('Cambiá tu contraseña')}</Heading>
      <Lead>{t('Elegí una nueva contraseña para ingresar a tu cuenta.')}</Lead>
    </>
  )
}

export default Component
