import React from 'react'
import { useTranslation } from 'react-i18next'
import { ComponentContainer, CustomLink, Text } from './mixins'
import { DataLayer } from '../../../../constants/gtm-events-sign-up'

interface ComponentProps {
  returnTo: string | null
}

const Component = ({ returnTo }: ComponentProps) => {
  const { t } = useTranslation()
  const href = returnTo ? `/signin?returnTo=${returnTo}` : '/signin'
  const trackingAction = () => {
    DataLayer.linkARegisto()
  }

  return (
    <ComponentContainer>
      <Text>{t('signIn.sinCuenta')}</Text>

      <CustomLink
        href={href}
        id="registrar"
        onClick={() => trackingAction()}
        linkoptions={{
          native: false,
          size: 'medium',
          type: 'link',
          variant: 'primary',
        }}
      >
        {t('signIn.registrate')}
      </CustomLink>
    </ComponentContainer>
  )
}

export default Component
