import { FilterApplied } from './filters'

export const GET_AVISOS = 'GET_AVISOS'
export const GET_AVISOS_SUCCESS = 'GET_AVISOS_SUCCESS'
export const GET_AVISOS_ERROR = 'GET_AVISOS_ERROR'
export const CLEAN_AVISOS = 'CLEAN_AVISOS'

export const GET_AVISOS_V2 = 'GET_AVISOS_V2'
export const GET_AVISOS_SUCCESS_V2 = 'GET_AVISOS_SUCCESS_V2'
export const GET_AVISOS_ERROR_V2 = 'GET_AVISOS_ERROR_V2'
export const CLEAN_AVISOS_V2 = 'CLEAN_AVISOS_V2'

export const GET_WORDINGS = 'GET_WORDINGS'
export const GET_WORDINGS_SUCCESS = 'GET_WORDINGS_SUCCESS'
export const GET_WORDINGS_ERROR = 'GET_WORDINGS_ERROR'

export const CANTIDAD_EMPLEOS = 'CANTIDAD_EMPLEOS'

export const ADS = 'ADS'

export const FACET_BANNER = 'FACET_BANNER'

export const RESET_LISTADO_AVISOS_STORE = 'RESET_LISTADO_AVISOS_STORE'

export const SET_ACTIVE_LANDING = 'SET_ACTIVE_LANDING'

export interface FilterCore {
  type: string
  facets: {
    id: string
    name: string
    quantity: number
  }[]
}

interface Filter {
  id: string
  value: string
}

export interface Aviso {
  aptoDiscapacitado?: boolean
  confidencial?: boolean
  detalle?: string
  empresa?: string
  fechaHoraPublicacion?: Date
  fechaPublicacion?: Date
  id?: number
  idEmpresa?: number
  links?: {
    href: string
    rel: string
    templated: string
  }
  localizacion?: string
  logoURL?: string
  oportunidad?: boolean
  planPublicacion?: {
    id: number
    nombre: string
  }
  puesto?: {
    puestoId: number
    nombre: string
    tieneEstadisticas: boolean
    linkSeoSalario?: string | null
  }
  portal?: string
  salarioMaximo?: number
  salarioMinimo?: number
  tipoTrabajo?: string
  titulo?: string
  fechaPostulacion?: Date
  estadoPostulacion?: string
  leido?: boolean
  idArea?: number
  idSubarea?: number
  visitadoPorPostulante?: boolean
}

interface AvisosSponsoreados {
  empresa: {
    logo: string
    nombre: string
    id: number | null
  }
  avisos: Aviso[]
}

interface Pagination {
  currentPage: number
  totalPages: number
  elementsPerPage: number
  totalElements: number
}

export interface GetAvisosParams {
  page: number
  pageSize?: number
  sortParams: URLSearchParams
  filtros?: Filter[]
  match: any
  internacional?: boolean
}

export interface SeoWordings {
  description: string | null
  h1: string | null
  option: string | null
  title: string | null
  preh1?: string | undefined
}

export interface GetAvisos {
  type: typeof GET_AVISOS | typeof GET_AVISOS_V2
  payload: GetAvisosParams
}
export interface GetAvisosSuccess {
  type: typeof GET_AVISOS_SUCCESS | typeof GET_AVISOS_SUCCESS_V2
  payload: {
    totalAvisos: number
    totalAvisosSuperdestacados: number
    grandTotal: number
  }
}

export interface GetAvisosError {
  type: typeof GET_AVISOS_ERROR | typeof GET_AVISOS_ERROR_V2
  payload: boolean
}

export interface CleanAvisos {
  type: typeof CLEAN_AVISOS | typeof CLEAN_AVISOS_V2
  payload: null
}

export interface GetWordings {
  type: typeof GET_WORDINGS
  payload: SeoWordings
}

export interface GetWordingsSuccess {
  type: typeof GET_WORDINGS_SUCCESS
  payload: SeoWordings
}

export interface GetWordingsError {
  type: typeof GET_WORDINGS_ERROR
  payload: boolean
}
export interface CantidadEmpleos {
  type: typeof CANTIDAD_EMPLEOS
  payload: boolean
}

export interface Ads {
  type: typeof ADS
  payload: boolean
}

export interface FacetBanner {
  type: typeof FACET_BANNER
  payload: boolean
}

export interface ResetListadoAvisosStore {
  type: typeof RESET_LISTADO_AVISOS_STORE
  payload: any
}

export interface ActiveLanding {
  type: typeof SET_ACTIVE_LANDING
  payload: string
}

export interface GetAvisosResponse {
  number: number
  size: number
  total: number
  content: Aviso[]
  filters: FilterCore[]
  filtersApplied: FilterApplied[]
  homeList?: {
    number: number
    size: number
    total: number
    content: Aviso[]
    filters: FilterCore[]
  }
  listadoSponsor?: {
    logoURL: string
    denominacion: string
    idEmpresa: number
    content: Aviso[]
  }
  totalSearched: number
  urlData?: any
}

/**
 * Los states del fetch deberiamos ubicarlos en un nuevo reducer
 */

export interface ListadoAvisosState {
  loading: boolean
  error: boolean
  pagination: Pagination
  avisoSelected?: { id: number } | null
  seoWordings?: SeoWordings | null
  sort?: string
  totalAvisos: number
  totalAvisosSuperdestacados: number
  grandTotal: number
  avisos: Aviso[] | null
  avisosSuperdestacados?: Aviso[] | null
  avisosSponsoreados?: AvisosSponsoreados | null
  filters: FilterCore[] | null
  filtersApplied?: FilterCore[] | null
  cantidadEmpleos: boolean
  ads: boolean
  facetBanner: {
    enabled: boolean
    title?: string
  }
  activeLanding?: string
}

export const initialState: ListadoAvisosState = {
  loading: true,
  error: false,
  pagination: {
    currentPage: 0,
    totalPages: 0,
    elementsPerPage: 20,
    totalElements: 0,
  },
  seoWordings: {
    description: null,
    h1: null,
    option: null,
    title: null,
    preh1: undefined,
  },
  sort: '',
  totalAvisos: 0,
  totalAvisosSuperdestacados: 0,
  grandTotal: 0, // Es la suma de totalAvisos y totalAvisosHome
  avisos: null,
  avisosSuperdestacados: null,
  avisosSponsoreados: null,
  filters: null,
  filtersApplied: null,
  cantidadEmpleos: true,
  ads: true,
  facetBanner: {
    enabled: false,
  },
  activeLanding: undefined,
}

export type ListadoAvisosActions =
  | GetAvisos
  | GetAvisosSuccess
  | GetAvisosError
  | CleanAvisos
  | GetWordings
  | GetWordingsSuccess
  | GetWordingsError
  | CantidadEmpleos
  | Ads
  | FacetBanner
  | ResetListadoAvisosStore
  | ActiveLanding
