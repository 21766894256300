import React from 'react'

// mixins
import { RatingScore, StarContainer, StarIcon, CustomIcon } from './mixins'

const CompanyRating = ({ reviewAverage, singleStar = false }) => {
  if (!reviewAverage) return null

  return (
    <StarContainer>
      <RatingScore>{reviewAverage.toFixed(1)}</RatingScore>
      {singleStar ? (
        <StarIcon className={'company-single-star'} name={'icon-bold-star'} color="#FAB306" size="16" />
      ) : (
        [...Array(5)].map((estrella, i) => (
          <CustomIcon
            className={'company-single-star'}
            key={`icon-${estrella}`}
            name={i + 1 <= reviewAverage ? 'icon-bold-star' : 'icon-light-star'}
            color="#FAB306"
            size="16"
          />
        ))
      )}
    </StarContainer>
  )
}

export default CompanyRating
