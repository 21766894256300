import React from 'react'
import { useTranslation } from 'react-i18next'
import { ComponentContainer, CustomLink, Text } from './mixins'
import { DataLayer } from '../../../../constants/gtm-events-sign-up'

const Component = () => {
  const { t } = useTranslation()

  return (
    <ComponentContainer>
      <Text>{t('signUp.conCuenta')}</Text>

      <CustomLink
        href="/login"
        onClick={() => DataLayer.linkIngresar()}
        linkoptions={{
          native: false,
          size: 'medium',
          type: 'link',
          variant: 'primary',
        }}
      >
        {t('signUp.ingresa')}
      </CustomLink>
    </ComponentContainer>
  )
}

export default Component
