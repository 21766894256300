import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const ImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`

export const ImgCoderhouse = styled('img')`
  max-width: 144px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 166px;
  }
`

export const Title = styled('h3')`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin-bottom: 16px;
`

export const Paragraph = styled('p')`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 16px;
`

export const CopyclipboardContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Label = styled('p')`
  margin-bottom: 4px;
`

export const ButtonWrapper = styled('div')`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const ButtonSuccess = styled(Button)`
  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 188px;
    margin-bottom: 0px;
    order: 2;
  }
`

export const ButtonCancel = styled(Button)`
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 188px;
  }
`

export const CopyButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    /* 
     * Styles Fix: se fixea con "min-width" & "min-height",
     * porque el componente Input de la ui-kit evita width & height en :hover
     */
    min-width: 32px;
    min-height: 32px;
    background-color: ${({ theme }) => theme.colors.black16};
  }
`

export const Legales = styled('p')`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black48};
  line-height: 16px;
  margin: 0px;
`
