import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// ui-kit
import { Row, Col, ContainerFluid } from '@navent-jobs/ui-kit/components/grid'
import RadioButton from '@navent-jobs/ui-kit/components/RadioButton'

// mixins
import Image from '@navent-jobs/ui-kit/components/Image'
import {
  ModalComponent,
  ModalFooterComponent,
  ButtonContainer,
  CustomButton,
  ModalTitle,
  ModalSubtitle,
  TitleContainer,
  Spacer,
} from './mixins'
import ImagenSeleccionEstado from '../../../../../../../assets/images/postulaciones/seleccion-estado.svg'
import ImagenSeleccionEstadoZJ from '../../../../../../../assets/images/postulaciones/seleccion-estado-zj.svg'
import { IS_ZONA_JOBS } from '../../../../../../../constants'

export interface ModalSeleccionEstadoProps {
  width: number
  visible: boolean
  loading: boolean
  closeModal: () => void
  handleSave: (estadoId) => void
  tituloAviso: string
  opciones: {
    id: number
    nombre: string
    descripcion: string
  }[]
}

export interface ModalFooterProps {
  isFormValid: boolean
  loading: boolean
  closeHandler: any
}

const ModalFooter = ({ isFormValid, loading, closeHandler }: ModalFooterProps) => {
  const { t } = useTranslation()

  return (
    <ModalFooterComponent>
      <ButtonContainer>
        <CustomButton
          buttonoptions={{ variant: 'primary', block: false, size: 'medium', type: 'outline' }}
          type="button"
          onClick={closeHandler}
        >
          {t('postulaciones.seleccion_estado.modal.cancelar')}
        </CustomButton>

        <CustomButton
          buttonoptions={{ variant: 'primary' }}
          form="form-seleccion-estado-postulacion"
          type="submit"
          disabled={!isFormValid}
          isLoading={loading}
        >
          {t('postulaciones.seleccion_estado.modal.aceptar')}
        </CustomButton>
      </ButtonContainer>
    </ModalFooterComponent>
  )
}

const ModalSeleccionEstado = ({
  width,
  visible,
  closeModal,
  handleSave,
  loading,
  tituloAviso,
  opciones,
}: ModalSeleccionEstadoProps) => {
  const { handleSubmit, formState, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { t } = useTranslation()
  const showImage = window.outerWidth >= 576

  const sendAnswersHandler = formData => {
    if (formData.estadoId && handleSave && !loading) {
      handleSave(formData.estadoId)
    }
  }

  const closeHandler = () => {
    closeModal()
  }

  return (
    <ModalComponent
      width={width}
      visible={visible}
      onClose={closeHandler}
      title={
        <TitleContainer>
          {showImage && (
            <Image
              width="168"
              height="100%"
              src={IS_ZONA_JOBS ? ImagenSeleccionEstadoZJ : ImagenSeleccionEstado}
              alt="Seleccion estado"
            />
          )}
          <ModalTitle>{t('postulaciones.seleccion_estado.modal.titulo', { tituloAviso })}</ModalTitle>
        </TitleContainer>
      }
      footer={<ModalFooter closeHandler={closeHandler} loading={loading} isFormValid={formState.isValid} />}
    >
      <form id="form-seleccion-estado-postulacion" onSubmit={handleSubmit(sendAnswersHandler)}>
        <ContainerFluid>
          <ModalSubtitle>{t('postulaciones.seleccion_estado.modal.pregunta')}</ModalSubtitle>
          <Row>
            <Col>
              <Spacer>
                <Controller
                  name="estadoId"
                  rules={{ required: true }}
                  control={control}
                  render={({ onChange }) => (
                    <RadioButton
                      name="respuestas-seleccion-estado"
                      fieldOptions={{
                        label: undefined,
                        size: 'medium',
                      }}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      options={opciones?.map(opcion => ({
                        id: opcion.id,
                        opcion:
                          t(`postulaciones.seleccion_estado.estados.${opcion.nombre}`) +
                          (opcion.id === 4 || opcion.id === 5 ? t('postulaciones.seleccion_estado.info') : ''),
                      }))}
                    />
                  )}
                />
              </Spacer>
            </Col>
          </Row>
        </ContainerFluid>
      </form>
    </ModalComponent>
  )
}

export default ModalSeleccionEstado
