import React from 'react'
import { Row, MainContainer } from '@navent-jobs/ui-kit/components/grid'
import { ProgressBar, Filler, ValueText, IdText, Container, IconStar, TextContainer, Wrapper } from './mixins'

const getScorePercentage = category => {
  // Calcula los porcentajes de cada puntaje para el detalle de la categoria general
  const scores = category.detail
    .sort((a, b) => b.score - a.score)
    .map(detail => {
      return {
        percentage: +((detail.quantity * 100) / category.quantity).toFixed(0),
        score: detail.score,
      }
    })

  // Revisa si el porcentaje se pasa o es menor a 100 debido al redondeo
  const diff = 100 - scores.reduce((a, b) => a + +b.percentage, 0)
  if (diff < 0) {
    // En el caso de pasarse descuenta del porcentaje del ultimo puntaje con calificaciones
    const lastScoreWithReview = scores.findLastIndex(score => score.percentage > 0)
    scores[lastScoreWithReview].percentage += diff
  } else if (diff > 0) {
    // En caso de ser menor a 100 se lo suma a la primer categoria con calificaciones
    const firstScoreWithReview = scores.findIndex(score => score.percentage > 0)
    scores[firstScoreWithReview].percentage += diff
  }
  return scores
}

const Component = ({ category }) => {
  if (!category) return null
  return (
    <MainContainer noGutter>
      <Wrapper>
        {getScorePercentage(category).map(calificacion => {
          return (
            <Row key={`progressBar-${calificacion.score}`} noGutter>
              <Container>
                <IdText>{calificacion.score}</IdText>
                <IconStar name="icon-bold-star" size="12" color="#474759" />
                <ProgressBar>
                  <Filler fillPercentage={calificacion.percentage} />
                </ProgressBar>
                <TextContainer>
                  <ValueText>{calificacion.percentage}%</ValueText>
                </TextContainer>
              </Container>
            </Row>
          )
        })}
      </Wrapper>
    </MainContainer>
  )
}

export default Component
