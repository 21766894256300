const feria = {
  logo: {
    width: '122px',
    height: '28px',
    alt: 'Logo Bumeran',
    className: 'logo',
  },
}

export default feria
