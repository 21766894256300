import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Button } from '@navent-jobs/ui-kit/components/button'

const selectStyles = theme => {
  return `
    .basic-single {
      position: static!important;
      
      .select__control{
        .select__value-container{
          padding: 0;
          line-height: 32px;
          font-size: 16px;
        }
      }

      .select__menu{
        left: 0px;
        border: 1px solid ${theme.colors.greyDark}
        top: calc( 100% + 4px );
      }

      .select__indicators {
        i:before {
          background-color: ${theme.colors.secondary.normal};
        }
      }
    }
  `
}

const FieldsContainers = `
  position: relative;
  z-index: 1;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 25px 0px 56px;
  background: #F5F5F5;
  border-radius: 8px;
`

export const KeywordFieldContainer = styled.div`
  ${FieldsContainers}
  border: ${({ value, theme }) => `1px solid ${value ? theme.colors.secondary.normal : theme.colors.gray}`};
  margin-bottom: 16px;
  ${({ theme }) => selectStyles(theme)}
`

export const LocationFieldContainer = styled.div`
  ${FieldsContainers}
  border: ${({ value, theme }) => `1px solid ${value ? theme.colors.secondary.normal : theme.colors.gray}`};
  margin-bottom: 20px;
  ${({ theme }) => selectStyles(theme)}
`

export const FieldIcon = styled(Icon)`
  position: absolute;
  left: 11px;
  top: 50%;
  transform: translate(0, -50%);
`

export const CustomButton = styled(Button)`
  margin: 16px 0;
`

export const CustomButtonTrash = styled(Button)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  gap: 4px;
`

export const RecentSearches = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style: none;
`

export const RecentSearchTitlte = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black48};
  margin-bottom: 12px;
`

export const RecentSearchesOption = styled.li`
  padding: 0px;
  margin: 0px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }
`
