import React, { useEffect, useState } from 'react'
// Tools
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

// Components
import { showSnackbar, showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'
import ToogleSuscription from './components/toogle-suscription'

// Constant
import { NOMBRE_SITIO, SITE_ID } from '../../../../constants'
// Redux
import { editSuscriptionAction, resetEditSuscription } from '../../../../redux/actions/suscriptcion'
import { StatusType } from '../../../../redux/types/status'
import { GetSuscripcionType, SuscripcionState } from '../../../../redux/types/suscripcion'

// Styles
import { Title, Divider } from '../../mixins'
import {
  Header,
  DescriptionWrapper,
  DescriptionEmail,
  DescriptionPush,
  SuscriptionItem,
  SuscriptionDetail,
  SuscriptionListTitle,
  ToggleWrapper,
  ToogleMargin,
  LastSuscriptionItem,
} from './mixins'

const config = {
  1: {
    nombre: `Novedades e innovaciones de ${NOMBRE_SITIO}`,
  },
  2: {
    nombre: 'Empleos recomendados según mi perfil',
  },
  3: {
    nombre: 'Actualización del estado de mis postulaciones',
  },
  4: {
    nombre: 'Mensajes nuevos de las empresas',
  },
  5: {
    nombre: 'Nuevos empleos de las empresas que sigo',
  },
}

interface UiSuscriptionListProps {
  editSuscripcionStatus: StatusType
  suscripcionsList: NonNullable<SuscripcionState['getSuscripcion']['items']>
}

const UiSuscriptionList = ({ editSuscripcionStatus, suscripcionsList }: UiSuscriptionListProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [currentState, setCurrentState] = useState<GetSuscripcionType[]>(suscripcionsList)
  const [previusState, setPreviusState] = useState<GetSuscripcionType[]>(suscripcionsList)

  useEffect(() => {
    if (editSuscripcionStatus.success) {
      setPreviusState(currentState)
      dispatch(showSnackbar(t('account.snackbarMessage.success'), 'icon-light-save'))
      dispatch(resetEditSuscription())
    } else if (editSuscripcionStatus.error) {
      setCurrentState(previusState)
      dispatch(showSnackbarError(t('account.snackbarMessage.error')))
    }
  }, [editSuscripcionStatus])

  const toogleHadler = (id: number, value: boolean, type: 'email' | 'push') => {
    const suscriptionListTemp = { ...currentState }
    suscriptionListTemp[id] =
      type === 'email' ? { ...suscriptionListTemp[id], email: value } : { ...suscriptionListTemp[id], push: value }
    setCurrentState(suscriptionListTemp)

    dispatch(editSuscriptionAction(suscriptionListTemp[id]))
  }

  return (
    <>
      <Header>
        <Title>{t('notificationAlerts.notifications.title')}</Title>
        <Divider />
      </Header>
      <DescriptionWrapper>
        <DescriptionEmail>
          <strong>{t('notificationAlerts.notifications.helperInfo.typeEmailLabel')} </strong>
          {t('notificationAlerts.notifications.helperInfo.typeEmailDescription')}
        </DescriptionEmail>
        <DescriptionPush>
          <strong>{t('notificationAlerts.notifications.helperInfo.typePushLabel')} </strong>
          {t('notificationAlerts.notifications.helperInfo.typePushDescription')}
        </DescriptionPush>
      </DescriptionWrapper>
      {currentState && (
        <>
          <SuscriptionListTitle>{t('notificationAlerts.notifications.subTitle1')}</SuscriptionListTitle>

          <SuscriptionItem>
            <SuscriptionDetail>{config[3].nombre}</SuscriptionDetail>
            <ToggleWrapper>
              <ToogleSuscription
                state={currentState[3]?.email ?? true}
                suscriptionType="email"
                suscriptionInfo={currentState[3]}
                onToogle={value => toogleHadler(3, value, 'email')}
              />
            </ToggleWrapper>
          </SuscriptionItem>

          {SITE_ID !== 'ZJAR' && (
            <SuscriptionItem>
              <SuscriptionDetail>{config[4].nombre}</SuscriptionDetail>
              <ToggleWrapper>
                <ToogleMargin>
                  <ToogleSuscription
                    state={currentState[4]?.push ?? true}
                    suscriptionType="push"
                    suscriptionInfo={currentState[4]}
                    onToogle={value => toogleHadler(4, value, 'push')}
                  />
                </ToogleMargin>

                <ToogleSuscription
                  state={currentState[4]?.email ?? true}
                  suscriptionType="email"
                  suscriptionInfo={currentState[4]}
                  onToogle={value => toogleHadler(4, value, 'email')}
                />
              </ToggleWrapper>
            </SuscriptionItem>
          )}

          <SuscriptionItem>
            <SuscriptionDetail>{config[5].nombre}</SuscriptionDetail>
            <ToggleWrapper>
              <ToogleMargin>
                <ToogleSuscription
                  state={currentState[5]?.push ?? true}
                  suscriptionType="push"
                  suscriptionInfo={currentState[5]}
                  onToogle={value => toogleHadler(5, value, 'push')}
                />
              </ToogleMargin>

              <ToogleSuscription
                state={currentState[5]?.email ?? true}
                suscriptionType="email"
                suscriptionInfo={currentState[5]}
                onToogle={value => toogleHadler(5, value, 'email')}
              />
            </ToggleWrapper>
          </SuscriptionItem>

          <SuscriptionItem>
            <SuscriptionDetail>{config[2].nombre}</SuscriptionDetail>
            <ToggleWrapper>
              <ToogleSuscription
                state={currentState[2]?.email ?? true}
                suscriptionType="email"
                suscriptionInfo={currentState[2]}
                onToogle={value => toogleHadler(2, value, 'email')}
              />
            </ToggleWrapper>
          </SuscriptionItem>

          <SuscriptionListTitle>
            {t('notificationAlerts.notifications.subTitle2')} {NOMBRE_SITIO}
          </SuscriptionListTitle>

          <LastSuscriptionItem>
            <SuscriptionDetail>{config[1].nombre}</SuscriptionDetail>
            <ToggleWrapper>
              <ToogleSuscription
                state={currentState[1]?.email ?? true}
                suscriptionType="email"
                suscriptionInfo={currentState[1]}
                onToogle={value => toogleHadler(1, value, 'email')}
              />
            </ToggleWrapper>
          </LastSuscriptionItem>
        </>
      )}
    </>
  )
}

const states = ({ suscripcionesStore }) => {
  return {
    editSuscripcionStatus: suscripcionesStore.editSuscripcion.status,
  }
}

export default connect(states)(UiSuscriptionList)
