import React from 'react'
import { useTranslation } from 'react-i18next'

import { Heading } from './mixins'

const Component = () => {
  const { t } = useTranslation()

  return <Heading level={2}>{t('¿Olvidaste tu contraseña?')}</Heading>
}

export default Component
