import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const ComponentContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const CustomButton = styled(Button)`
  width: 100%;
  max-width: 288px;
`

export const CustomDisabledButton = styled(Button)`
  border: none;
  background: ${({ theme }) => theme.colors.black72};
  color: white;
  width: 100%;
  max-width: 288px;
  font-size: 14px;
  pointer-events: none;
  line-height: 48px;

  :focus:not(:active) {
    background-color: ${({ theme }) => theme.colors.black72};
    outline: none;
  }
`

export const Text = styled('p')`
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  margin-bottom: 12px;
`

export const CustomLink = styled(Link)`
  font-weight: 600;
`
