import { merge } from 'lodash'
import all from '../all'
import pdaPago from './pda-pago'
import card from './card'
import fichaAviso from './ficha-aviso'
import curriculum from './curriculum'
import products from './products'
import sortEmpresas from './sortEmpresas'
import searchAvisosV2 from './search-avisos-v2'
import feria from './feria'
import avisosFake from './avisos-fake'

const bmpa = {
  ...all,
  pdaPago,
  fichaAviso: merge({}, all.fichaAviso, fichaAviso),
  card: merge({}, all.card, card),
  curriculum: merge({}, all.curriculum, curriculum),
  products: merge({}, all.products, products),
  sortEmpresas: merge({}, all.sortEmpresas, sortEmpresas),
  searchAvisosV2: merge({}, all.searchAvisosV2, searchAvisosV2),
  feria,
  avisosFake: merge({}, all.avisosFake, avisosFake),
}
export default bmpa
