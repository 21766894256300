import React from 'react'
import { useHistory } from 'react-router-dom'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Col } from '@navent-jobs/ui-kit/components/grid'

// components
import SearchBar from '../search-bar'

// mixins
import {
  RecientesComponent,
  Heading,
  RecientesList,
  RecientesItem,
  RecientesLink,
  IconCaretRight,
  SearchText,
} from './mixins'

// constants
import { NOMBRE_PAIS } from '../../../../constants/index'

// services
import { SearchCompaniesStack } from '../../../../services/search-companies-stack'

export const BusquedasRecientes = ({ loading, location }) => {
  const lastSearches = SearchCompaniesStack.getInstance().getHistory()
  const { isTabletLandscape, isDesktop } = useWindowSize()
  const isDesktopOrTabletLandscape = isDesktop || isTabletLandscape
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)

  return (
    <>
      {!isDesktopOrTabletLandscape && (
        <Col xs={{ size: 12, order: 0 }} lg={4} xl={3}>
          <SearchBar loading={loading} location={location} />
        </Col>
      )}

      <Col xs={{ size: 12, order: 2 }} lg={4} xl={3}>
        {isDesktopOrTabletLandscape && <SearchBar loading={loading} location={location} />}

        <RecientesComponent>
          <Heading level={3}>Búsquedas recientes</Heading>

          <RecientesList>
            {lastSearches.length > 0 &&
              lastSearches.map(recentSearch => {
                return (
                  recentSearch && (
                    <RecientesItem>
                      <RecientesLink
                        onClick={() => {
                          queryParams.delete('query')
                          if (recentSearch?.keyword) {
                            queryParams.append('query', recentSearch.keyword)
                          }
                          history.push({
                            search: queryParams.toString(),
                          })
                        }}
                      >
                        <SearchText>{recentSearch.keyword}</SearchText>
                        <IconCaretRight name="icon-light-caret-right" color="rgba(0, 0, 24, 0.48);" size="16" />
                      </RecientesLink>
                    </RecientesItem>
                  )
                )
              })}

            <RecientesItem>
              <RecientesLink
                onClick={() => {
                  history.push('/listado-empresas')
                }}
              >
                Empresas en {NOMBRE_PAIS}
                <IconCaretRight name="icon-light-caret-right" color="rgba(0, 0, 24, 0.48);" size="16" />
              </RecientesLink>
            </RecientesItem>
          </RecientesList>
        </RecientesComponent>
      </Col>
    </>
  )
}
