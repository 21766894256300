import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'
import Image from '@navent-jobs/ui-kit/components/Image'

export const Section = styled.div`
  background: no-repeat 0% 100% url(${({ bkg }) => bkg}), #edf1f7;
`

export const MainForm = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 36px 0 28px;
  margin: 0 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 53px 0 48px;
  }
`

export const CardForm = styled('div')`
  width: 100%;
  max-width: 805px;
  background: #ffffff;
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 64px;
    border-radius: 32px;
  }
`

export const CardFlex = styled('div')`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: initial;
  }
`

export const Title = styled('p')`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  line-height: 32px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 32px;
    line-height: 40px;
    text-align: left;
  }
`

export const Subtitle = styled('p')`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black84};
  ${({ bold }) => bold && 'font-weight: 600;'};
  line-height: 20px;
  text-align: center;
  margin: 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
  }
`

export const InfoContainer = styled('div')`
  max-width: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    align-items: flex-start;
  }
`

export const ImageContainer = styled('div')`
  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-left: 23px;
    margin-bottom: 0px;
  }
`

export const ImageCustom = styled(Image)`
  width: 100%;
  max-width: 296px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 304px;
  }
`

export const ButtonCustom = styled(Button)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 200px;
  }
`
