import React from 'react'
import { useTranslation } from 'react-i18next'

import { Heading, CustomText } from './mixins'

const Component = ({ applicantEmail }) => {
  const { t } = useTranslation()

  return (
    <>
      <Heading level={2}>{t('Te enviamos un email a ')}</Heading>

      <CustomText>
        {applicantEmail}
      </CustomText>
    </>
  )
}

export default Component
