import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import loggerService from '../../services/logger-service'

import errorImageUrl from '../../assets/images/algo-no-salio-bien.png'

const ErrorScreen = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const DefaultErrorScreen = () => {
  return (
    <ErrorScreen>
      <img src={errorImageUrl} alt="error" />
      <h2>Algo no salió bien!</h2>
    </ErrorScreen>
  )
}
export class ErrorBoundary extends PureComponent {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    loggerService.logError(
      `Error desde did catch de ErrorBoundary. Error : ${error.message} , info : ${errorInfo.componentStack}`,
      error.stack
    )
  }

  render() {
    const { renderOnError, children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return renderOnError()
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  renderOnError: PropTypes.func,
  children: PropTypes.node.isRequired,
}

ErrorBoundary.defaultProps = {
  renderOnError: DefaultErrorScreen,
}
