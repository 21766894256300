import styled from 'styled-components'

export const CustomLink = styled.a`
  color: ${({ theme }) => theme.colors.black84};

  :hover {
    color: ${({ theme }) => theme.colors.black84};
    text-decoration: none;
  }
`
