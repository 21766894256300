import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

// components
import CantidadEmpresas from '../cantidad-empresas'
import { DataLayerListadoEmpresas } from '../../../../constants/gtm-events-listado-empresas'
import Sort from '../../../../components/sort'

// mixins
import { SortEmpresasContainer } from './mixins'

// redux
import { getEmpresasSortSettings, setEmpresasSortSettings } from '../../../../redux/actions/sort'

// constants
import { SITE_ID } from '../../../../constants'
import features from '../../../../constants/features-per-country'

const sortEmpresas = features[SITE_ID || 'BMAR']?.sortEmpresas

const getSort = (location, ubicacionParam, industriaParam, queryParam, urlParams) => {
  const sortProps = {
    queryParams: urlParams,
    ...sortEmpresas,
  }

  const params: string[] = []
  if (queryParam) params.push(`query=${queryParam}`)
  if (ubicacionParam) params.push(`ubicacion=${ubicacionParam}`)
  if (industriaParam) params.push(`industria=${industriaParam}`)

  if (params && params.length > 0) {
    sortProps.path = `${location.pathname}?${params.join('&')}`
    sortProps.items = sortEmpresas.items.map(item => {
      return {
        ...item,
        to: item.to.replace('?', '&'),
      }
    })
  } else {
    sortProps.path = location.pathname
  }
  return sortProps
}

const SortEmpresas = ({
  location,
  ubicacionParam,
  industriaParam,
  queryParam,
  urlParams,
  hideQuantity,
  totalEmpresas,
  sortStore,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (sortEmpresas?.enabled) {
      dispatch(getEmpresasSortSettings())
    }
  }, [])

  useEffect(() => {
    dispatch(setEmpresasSortSettings(getSort(location, ubicacionParam, industriaParam, queryParam, urlParams)))
  }, [location, ubicacionParam, industriaParam, queryParam, urlParams])

  if (!sortEmpresas?.enabled || totalEmpresas < 1) {
    return null
  }

  return (
    <SortEmpresasContainer>
      {hideQuantity && <CantidadEmpresas />}
      <Sort {...sortStore} dataLayers={DataLayerListadoEmpresas} />
    </SortEmpresasContainer>
  )
}

const mapStateToProps = ({ listadoEmpresasStore, sortStore }) => ({
  totalEmpresas: listadoEmpresasStore.totalEmpresas,
  sortStore,
})

export default connect(mapStateToProps)(SortEmpresas)
