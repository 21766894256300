import { useEffect } from 'react'
import { TagManager } from '@navent-jobs/config'
import { connect, useDispatch } from 'react-redux'
import { DataLayers } from '../../constants/gtm-events-base'
import { getApplicantSummary } from '../../redux/actions/applicant/applicant'
import { SITE_ID } from '../../constants'
import { updateTokenFCM } from '../../redux/actions/notifications'

const Component = ({ applicant, success, error }) => {
  const dispatch = useDispatch()
  const isLogged = !!applicant

  useEffect(() => {
    window.addEventListener('load', () => {
      dispatch(getApplicantSummary())
    })

    return () => {
      window.removeEventListener('load', () => {})
    }
  }, [])

  useEffect(() => {
    /**
     * Esta TagManager debe ejecutarse si el usuario esta logueado o no
     * Recibe isLogged como parametro para realizar el tracking si ingreso un usuario logueado o uno no logueado.
     */
    if (success) {
      TagManager.Init({
        siteId: SITE_ID,
        dataLayer: {
          isLogged: 'Si',
          user_id_custom: `${applicant?.id}`,
        },
      })
    } else if (error) {
      TagManager.Init({
        siteId: SITE_ID,
        dataLayer: { isLogged: 'No' },
      })
    }
  }, [success, error, applicant])

  useEffect(() => {
    if (isLogged) {
      dispatch(updateTokenFCM())
    }
  }, [isLogged])

  return null
}

const state = ({ applicantStore }) => {
  return {
    applicant: applicantStore.applicant,
    success: applicantStore.success,
    error: applicantStore.error,
  }
}

export default connect(state)(Component)
