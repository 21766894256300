import React from 'react'
import { IS_ZONA_JOBS } from '../../../../constants'
import LoginImageBum from '../../../../assets/images/login.svg'
import LoginImageZona from '../../../../assets/images/login-zonajobs.svg'
import { ComponentContainer, CustomImage } from './mixins'

const Component = () => {
  return (
    <ComponentContainer>
      <CustomImage src={IS_ZONA_JOBS ? LoginImageZona : LoginImageBum} width="414px" height="539px" alt="Login Image" />
    </ComponentContainer>
  )
}

export default Component
