import styled from 'styled-components'

export const CustomForm = styled.div`
  margin-top: 16px;
  padding: 16px;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  background-color: white;
  min-height: calc(44vh);

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 24px;
  }
`

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CustonImg = styled.img`
  width: 256px;
  height: 160px;
  margin-bottom: 16px;
`

export const GeneralTitle = styled.p`
  margin: 8px 0 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const InputContainer = styled.div`
  width: 100%;
`

export const InputLabel = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.colors.black84};
`

export const ErrorText = styled.span`
  font-size: 14px;
  line-height: 20px;
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const ErrorTextContainer = styled.span`
  display: flex;
  text-align: center;
`
