import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

// common
export const Image = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 0px 0px 52px;
`

/**
 * Modal
 */

export const ModalBodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  button {
    width: 100%;
    max-width: 340px;
  }
`

export const ParagraphSimple = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #222b45;
`

export const MailSent = styled.div`
  background: ${({ theme }) => theme.colors.black84};
  border-radius: 8px;
  max-width: 340px;
  width: 100%;
  text-align: center;
  line-height: 36px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    padding: 0px;
  }

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    line-height: 48px;
  }
`

export const MailText = styled.span`
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
`

export const CustomButton = styled(Button)`
  padding: 0 12px;
`

/**
 * Sidebar
 */

export const SidebarTitle = styled.h3`
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin: 64px auto 40px;
  max-width: 290px;
`

export const SidebarContent = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;

  flex-direction: column;
`

export const SidebarReminder = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  padding-bottom: 20px;

  &:first-letter {
    text-transform: capitalize;
  }

  &:before {
    content: '*';
    color: red;
  }
`

export const SidebarFooter = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
`
