import React from 'react'
import { userLoggedIn } from '../../utils/cookies'
import Corazon from './components/corazon'
import CorazonLleno from './components/corazon-lleno'

const CorazonAnimado = ({ index = 0, savePosting, onClick, disabled = false, checkLogin = true }) => {
  const isUserLogged = userLoggedIn()

  const handleOnClick = () => {
    if (!isUserLogged && checkLogin) {
      window.location.assign(`/login?returnTo=${window.location.pathname}`)
      window.scrollTo(0, 0)
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      style={{ width: 24, cursor: disabled ? 'not-allowed' : 'pointer' }}
      onClick={handleOnClick}
      onKeyDown={() => {}}
    >
      {savePosting ? (
        <CorazonLleno index={index} onClick={onClick} disabled={disabled} />
      ) : (
        <Corazon index={index} disabled={disabled} onClick={onClick} />
      )}
    </div>
  )
}
export default CorazonAnimado
