import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components'

export const SelectField = styled(CreatableSelect)<{ theme; isZonajobs }>`
  position: relative;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  box-sizing: border-box;
  width: 100%;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  padding: 0px;

  /**
    *  Estilos en caso que el campo posea contenido o se encuentra activo
    */

  .select__indicators {
    .select__dropdown-indicator {
      transition: all 0.2s ease-in-out;
      transform: rotate(0deg);
    }

    span {
      display: none;
    }

    .select__indicator {
      padding: 0;
    }

    i:before {
      background-color: rgba(0, 0, 24, 0.24);
    }
  }

  & > .select__control,
  & > .select__control:hover {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    min-height: 0;
    line-height: 34px;
    cursor: pointer;

    .select__value-container {
      padding: 0;
      line-height: 34px;

      .select__placeholder {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        color: rgba(0, 0, 24, 0.6);
      }

      .select__single-value {
        margin: 0px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.secondary.lighten};
      }

      .select__input {
        input {
          font-family: ${({ theme }) => theme.fonts.families.base};
          color: ${({ theme }) => theme.colors.secondary.normal} !important;
          font-weight: 600;
        }
      }

      & > [class^='css-'] {
        margin: 0;
        padding: 0;
      }
    }

    &.select__control--menu-is-open {
      border: 0;

      .select__indicators {
        .select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }
  }

  & > .select__menu {
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.grey};
    color: rgba(0, 0, 24, 0.6);
    padding: 8px;

    ${({ optionsOnTop = false }) => optionsOnTop && 'top: -5px;Transform: translateY(calc(-100% - 60px));'};

    .select__menu-list {
      padding: 0px;

      .select__option {
        border-radius: 4px;
        font-weight: 600;
        padding: 0 12px;
        line-height: 36px;
        font-size: 14px;
        cursor: pointer;

        &.select__option--is-selected,
        &.select__option--is-focused {
          background: #ffffff;
        }

        &.select__option--is-focused {
          color: rgba(0, 0, 24, 0.6);
        }

        &.select__option--is-selected {
          color: #3d47f5;
        }
      }
    }
  }
`

const fontWeight600 = `font-weight: 600;`

export const InputField = styled.input`
  line-height: 32px;
  font-size: 16px;
  background: transparent;
  border: 0px;
  outline: 0px;
  padding: 0px;
  width: 100%;
  ${({ value }) => value && fontWeight600}
  color: ${({ theme }) => theme.colors.secondary.lighten};
  font-family: 'Hind Vadodara';

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 24, 0.6);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 24, 0.6);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(0, 0, 24, 0.6);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 24, 0.6);
  }
`

export const RecentSearchesOption = styled.div``
