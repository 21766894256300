const feria = {
  header: {
    contact: 'Bienvenidos a la feria de empleos más grande de todo {{pais}}.',
    register: 'Regístrate',
  },
  list: {
    title: 'Conoce todas las empresas participantes por industria',
    subtitle: 'Elige una industria para filtrar las empresas',
    searchPlaceholder: 'Ingresa una empresa',
  },
  register: {
    title: 'Regístrate y descubre un mundo de posibilidades profesionales.',
    button: 'Regístrate',
  },
  ficha: {
    enviar: 'Enviar {{cv}}',
    enviarPostulado: '{{cv}} enviado',
    error: 'Ocurrió un error inesperado',
    seccionAreas: 'Áreas de la empresa',
    postularme: 'Postularme',
  },
  conferences: {
    description: 'Te compartimos algunos consejos útiles para tu búsqueda.',
    replay: 'Revive la charla',
    soon: 'Muy pronto',
  },
  description: {
    imageTitle: 'Descubre nuestra exclusiva sección de conferencias.',
  },
  info: {
    avisos: 'Avisos publicados',
  },
  empty: {
    title: 'Esta empresa aún no ha publicado empleos',
    subtitle: 'Envía tu {{cv}} a su base de datos',
  },
}

export default feria
