import styled from 'styled-components'
import { Dropdown } from '@navent-jobs/ui-kit/components/dropdown'
import { Link } from '@navent-jobs/ui-kit/components/link'
import { Nav, NavItem } from '@navent-jobs/ui-kit/components/nav'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Navbar } from '@navent-jobs/ui-kit/components/navbar'

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
`

export const HeaderNav = styled(Nav)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0px;

  @media (min-width: ${props => props.theme.screen.xlMin}) {
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    flex-shrink: 0;
    margin: 0px;
  }
`

/**
 * Estilos del header utilizado en el dropdown
 */
export const UserDropdownHeaderContainer = styled('div')<{ isUserLoggedIn }>`
  ${({ isUserLoggedIn }) => (isUserLoggedIn ? `padding: 8px;` : `margin: 4px 0 8px;`)}
  display: flex;
  flex-direction: ${props => (props.isUserLoggedIn ? 'row' : 'column')};
  align-items: ${props => (props.isUserLoggedIn ? 'center' : 'flex-start')};
  flex-wrap: nowrap;
  border-radius: 8px;

  @media (min-width: ${props => props.theme.screen.xlMin}) {
    ${({ isUserLoggedIn }) =>
      isUserLoggedIn ? `margin: 24px 0 10px 0; padding: 10px 0 10px 16px;` : `margin: 24px 0px 20px 16px;`}
    min-width: 328px;
  }

  ${({ isUserLoggedIn, theme }) =>
    isUserLoggedIn
      ? `
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      background-color: ${theme.colors.black4};
      cursor: pointer;
    };`
      : ''}
`

export const UserAvatar = styled.img`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;
`

export const UserName = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black84};
`

export const UserEmail = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black60};
  margin: 0;
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const UserDropdownHeaderSubtitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black84};
  padding: 0px;
  flex: 1 1 100%;
  margin: 0;
`

/**
 * Estilos para los items del menu
 */

/**
 * Revisar esto
 * HeaderItemNavbar flex property
 */
const NavItemFlex = {
  link: '1 0 100%',
  fill: '1 0 40%',
  outline: '1 0 40%',
}

export const HeaderItemNavbar = styled(NavItem)`
  display: block;
  padding: 0;
  position: relative;

  flex: ${props => {
    const type = props?.children?.props?.linkoptions?.type || 'link'

    return NavItemFlex[type]
  }};

  &:first-child {
    padding ${props => {
      const type = props?.children?.props?.linkoptions?.type
      if (type === 'link') {
        return '0'
      }
      return '0 12px 0 0'
    }};
  }

  &:last-child {
    margin: 0;
  }

  &:nth-child(n + 3) {
    &:nth-last-child(2) {
      &:before {
        height: 0px !important;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin: 0px;
    padding: 0px;
    flex: none;
    margin-left: ${props => (props?.children?.props?.linkoptions?.type === 'link' ? '7px' : '10px')};

    &:nth-child(1) {
      margin-left: 15px;
    }

    &:before {
      display: none;
    }

    &:first-child {
      padding: 0;
    }
  }
`

const linksHeaderItemLinkStyles = theme => `
  min-width: 0;
  padding: 8px;
  margin: 4px 0px;
  width: 100%; 
  border: 0;
  display: flex;
  aling-items: center;

  @media (max-width: 1024px) {
    text-align: left;
  }

  @media (min-width: ${theme.screen.xlMin}) { 
    padding: 12px 6px;
  }
`
const noLinksHeaderItemLinkStyles = (theme, width) => `
  width: 100%;
  margin: 12px 0px;

  @media (min-width: ${theme.screen.xlMin}) { 
    width: ${width ? `${width}` : '100%'};

    max-width: 112px;
    min-width: 100px;
  }
`
const userHeaderItemLinkStyles = (theme, variant) => `
  &:hover, 
  &:active, 
  &:focus {
    text-decoration: none;
    background-color: ${theme.colors.black4};
    color: ${variant === 'primary' ? theme.colors.primary.normal : theme.colors.black60} !important;

    i:before{
      background-color: ${variant === 'primary' ? theme.colors.primary.normal : theme.colors.black60} !important;
    }
  }`

export const HeaderItemLink = styled(Link)`
  ${({ linkoptions, theme, width }) =>
    linkoptions?.type === 'link' ? linksHeaderItemLinkStyles(theme) : noLinksHeaderItemLinkStyles(theme, width)}
  ${({ linkoptions, theme, section }) =>
    linkoptions?.type === 'link' && section !== 'navbar' ? userHeaderItemLinkStyles(theme, linkoptions?.variant) : ''}
`

export const HeaderDropdown = styled(Dropdown)`
  & > [class^='Dropdown__DropdownCollapse'] {
    @media (max-width: ${props => props.theme.screen.lgMax}) {
      position: relative;
      padding: 0px;
    }
  }
`

export const HeaderIcon = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
  margin: ${props => (props.icon === 'logout' ? '0 10px 0 0' : '0')};
  width: ${props => (props.icon === 'logout' ? '24px' : '20px')};
  height: ${props => (props.icon === 'logout' ? '24px' : '20px')};

  &:before {
    width: ${props => (props.icon === 'logout' ? '24px' : '20px')};
    height: ${props => (props.icon === 'logout' ? '24px' : '20px')};

    @media (min-width: ${props => props.theme.screen.xlMin}) {
      position: relative;
      display: ${props => (props.icon === 'menu' || props.icon === 'arrow' ? 'none' : 'block')};
    }
  }
`
/**
 * Menu on the right of the navbar in mobile
 */

export const MobileRightMenu = styled.div`
  position: absolute;
  top: 0;
  right: 12px;
  height: 56px;
  line-height: 56px;

  @media (min-width: ${props => props.theme.screen.xlMin}) {
    right: 0;
  }
`

export const NavbarCustom = styled(Navbar)``
