import styled from 'styled-components'

export const Title = styled.p`
  margin: 16px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const Disclaimer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`

export const DisclaimerText = styled.span`
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black84};
`

export const Info = styled.span`
  position: relative;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black60};
`

export const Asterisco = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.alternativeError};
`

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 684px;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const CustomButton = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 27px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-image: url('${({ bkg }) => bkg}');
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
  border: ${({ theme, selected }) => `1px solid ${selected ? theme.colors.secondary.normal : theme.colors.black8}`};
`

export const ButtonText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme, selected }) => (selected ? theme.colors.secondary.normal : theme.colors.black84)};
`

export const OptTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    flex-direction: row;
    gap: 24px;
  }
`

export const SecondTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 24px 0 16px 0;
  color: ${({ theme }) => theme.colors.secondary.normal};
`
