import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const Page = styled('div')`
  background-color: '#ffffff';
  padding: 24px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 48px 0px;
  }
`

export const CustomButton = styled(Button)`
  max-width: 288px;
  margin: 0 auto;
  max-width: 288px;
  width: 100%;
  display: block;
`
