import styled from 'styled-components'
import { Title } from '@navent-jobs/ui-kit/components/title'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const RecientesComponent = styled('div')`
  background-color: white;
  padding: 20px;
  display: flex;
  border-radius: 8px;
  text-align: left;
  flex-direction: column;
  margin-bottom: 24px;
  max-height: 284px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 0;
  }
`

export const Heading = styled(Title)`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black84};
  line-height: 24px;
  margin-bottom: 4px;
`

export const RecientesList = styled('ul')`
  margin: 0;
  padding: 0;
`

export const RecientesItem = styled('li')`
  padding: 0px;
  margin: 16px 0 0;
  list-style: none;
`

export const RecientesLink = styled('p')`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black60};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.black84};
    text-decoration: none;
  }
`

export const SearchText = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
`

export const IconCaretRight = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(0, -50%);
`
