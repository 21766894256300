import { merge } from 'lodash'
import all from '../all'
import interlinking from './interlinking'
import pda from './pda'
import userPremium from './user-premium'
import pdaPago from './pda-pago'
import fichaAviso from './ficha-aviso'
import curriculum from './curriculum'
import card from './card'
import carrouselCompanies from './carrousel-companies'
import empleosMasBuscados from './empleos-mas-buscados'
import searchAvisosV2 from './search-avisos-v2'
import feria from './feria'
import avisosFake from './avisos-fake'

const bmmx = {
  ...all,
  ...pda,
  ...interlinking,
  userPremium: { ...all.userPremium, ...userPremium },
  pdaPago,
  fichaAviso: merge({}, all.fichaAviso, fichaAviso),
  curriculum: merge({}, all.curriculum, curriculum),
  card: merge({}, all.card, card),
  carrouselCompanies: merge({}, all.carrouselCompanies, carrouselCompanies),
  empleosMasBuscados: merge({}, all.empleosMasBuscados, empleosMasBuscados),
  searchAvisosV2: merge({}, all.searchAvisosV2, searchAvisosV2),
  feria,
  avisosFake: merge({}, all.avisosFake, avisosFake),
}

export default bmmx
