import styled from 'styled-components'

export const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary.lighten};
`

export const Title = styled.p`
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin: 36px 16px;
  color: ${({ theme }) => theme.colors.secondary.normal};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    max-width: 888px;
    font-size: 32px;
    line-height: 40px;
    margin: 48px 0;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    flex-direction: row;
    padding: 24px 24px 48px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Subtitle = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 24px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 24px;
    margin-top: 48px;
    font-size: 32px;
    line-height: 40px;
  }
`

export const Divider = styled.div`
  border-top: 1px solid #ffffff;
  width: calc(100% - 48px);
`

export const BackgroundImage = styled.div`
  width: 100%;
  background: url(${({ source }) => source}) no-repeat;
  background-size: cover;
`
