import styled from 'styled-components'
import { Dropdown } from '@navent-jobs/ui-kit/components/dropdown'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2px;
  &:after {
    content: '';
    width: 1px;
    height: 16px;
    background-color: ${({ theme }) => theme.colors.greyDark};
  }
`

export const Text = styled.span`
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.families.base};
  font-style: normal;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black84};
`

export const DropdownCustom = styled(Dropdown)``

export const ImageCustom = styled.img`
  margin: 0px 8px 0px 0px;
`

export const IconCustom = styled(Icon)`
  margin: 0px 8px 0px 0px;
`

export const IconDropdown = styled(Icon)`
  margin-left: 8px;
  transform: ${({ dropdownOpen }) => (dropdownOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.2s ease-in-out;
  margin-right: 20px;
`
