import React from 'react'
import { IconCustom } from './mixins'
import { CrossSiteDataLayers } from '../../gtm-events-cross-site'

export const dropdownSitesItems = {
  BMAR: [],
  ZJAR: [],
  BMCL: [
    {
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      content: 'Jóvenes profesionales',
      key: 'jovenes-profesionales',
      before: <IconCustom name="icon-light-graduation-cap" size="16" color="#666674" />,
      onClick: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
    },
    {
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      content: 'Puestos ejecutivos',
      key: 'puestos-ejecutivos',
      before: <IconCustom name="icon-light-briefcase" size="16" color="#666674" />,
      onClick: () => CrossSiteDataLayers.header('Puestos Ejecutivos'),
    },
    {
      href: '/empleos-discapacitados-apto.html?landing-empleos-inclusivos=true',
      content: 'Empleos inclusivos',
      key: 'empleos-inclusivos',
      before: <IconCustom name="icon-light-discapacity" size="16" color="#666674" />,
      onClick: () => CrossSiteDataLayers.header('Empleos inclusivos'),
    },
    {
      href: '/empleos-part-time.html?landing-empleos-part-time=true',
      content: 'Empleos Part-time',
      key: 'empleos-part-time',
      before: <IconCustom name="icon-light-clock" size="16" color="#666674" />,
      onClick: () => CrossSiteDataLayers.header('Empleos part-time'),
    },
    {
      href: '/empleos-practica-profesional.html?landing-practicas-profesionales=true',
      content: 'Prácticas profesionales',
      key: 'practicas-profesionales',
      before: <IconCustom name="icon-light-briefcase" size="16" color="#666674" />,
      onClick: () => CrossSiteDataLayers.header('Practicas profesionales'),
    },
  ],
  BMEC: [],
  BMMX: [],
  BMPA: [],
  BMPE: [],
  BMVE: [],
}
