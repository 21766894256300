import styled from 'styled-components'

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
  gap: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyDark};
`

const PlaceholderContainer = `
  background: white;
  border-radius: 8px;
  width: 100%;
`

export const PlaceholderData = styled.div`
  ${PlaceholderContainer}
  margin-bottom: 24px;
  ${({ width }) => (width ? `width: ${width}px;` : '')}
`
