import { Container, Row } from '@navent-jobs/ui-kit/components/grid'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import styled from 'styled-components'

export const CardContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: 8px;
  padding: 12px 12px 4px 12px;
`

export const CustomRow = styled(Row)`
  align-items: center;
`

export const CustomRowEnd = styled(Row)`
  align-items: center;
  justify-content: end;
`

export const ApplicantName = styled.span`
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`

export const Spacer = styled.div`
  margin-top: 12px;
`

export const CustomIcon = styled(Icon)`
  align-self: center;
  margin-right: 10px;
`

export const Text = styled.span`
  color: #666674;
`

export const Recommended = styled.div`
  display: flex;
  margin-bottom: 8px;
`

export const Title = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 3px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.black84};
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 14px;
`

export const RatingRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
  }
`

export const RatingContainer = styled.span`
  padding-right: 10px;
`
export const DisclaimerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;

  border-top: 1px solid #d9d9d9;
`

export const Disclaimer = styled.div`
  display: flex;
  flex-direction: row;
`

export const DisclaimerText = styled.span`
  margin-left: 8px
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;

  color: ${({ theme }) => theme.colors.black60};
`
