import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'

// ui-kit
import { Col, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import RadioButton from '@navent-jobs/ui-kit/components/RadioButton'

// style
import { ReviewsContainer, FormRow, GeneralReviewLabel, ReviewLabel, Recommended, FlexContainer } from './mixins'
import { CustomForm } from '../calificacion/mixins'
import { Asterisco } from '../form-header/mixins'

// components
import StarRating from '../selector'
import { StepButtons } from '../../../../components/StepButtons'

const Component = ({ categories, reviewData, backProps, submitHandler }) => {
  const { t } = useTranslation()
  const [recommendedValue, setRecommendedValue] = useState(
    reviewData?.recommends != null ? (reviewData?.recommends ? '1' : '2') : undefined
  )

  // Form
  const { control, formState, setValue, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      recommended: recommendedValue,
    },
  })

  return (
    <>
      <form onSubmit={e => e.preventDefault()}>
        <MainContainer>
          <Row>
            <Col>
              <FlexContainer>
                <CustomForm>
                  <ReviewsContainer>
                    {categories &&
                      categories.map((category, index) => {
                        return (
                          <FormRow key={category.id}>
                            {index === 0 ? (
                              <GeneralReviewLabel for={category.name}>
                                {'Calificación general de tu experiencia'}
                                <Asterisco>*</Asterisco>
                              </GeneralReviewLabel>
                            ) : (
                              <ReviewLabel for={category.name}>
                                {category.name}
                                <Asterisco>*</Asterisco>
                              </ReviewLabel>
                            )}
                            <Controller
                              name={category.name}
                              rules={{ required: true }}
                              control={control}
                              defaultValue={reviewData?.scoresByCategory?.find(i => i.id === category.id).score}
                              render={({ value, onChange }) => (
                                <StarRating
                                  name={category.name}
                                  value={value}
                                  onChange={onChange}
                                  setValue={setValue}
                                />
                              )}
                            />
                          </FormRow>
                        )
                      })}
                    <FormRow key="recommended">
                      <ReviewLabel>
                        {t('review.recomend')}
                        <Asterisco>*</Asterisco>
                      </ReviewLabel>
                      <Recommended>
                        <Controller
                          name="recommended"
                          rules={{ required: true }}
                          control={control}
                          render={({ onChange }) => (
                            <RadioButton
                              id="recommended"
                              name="recommended"
                              onChange={e => {
                                onChange(e.target.value)
                                setValue('recommended', e.target.value)
                                setRecommendedValue(e.target.value)
                              }}
                              value={recommendedValue}
                              options={[
                                {
                                  id: '1',
                                  opcion: 'Sí',
                                },
                                {
                                  id: '2',
                                  opcion: 'No',
                                },
                              ]}
                              fieldOptions={{
                                size: 'small',
                                direction: 'row',
                              }}
                            />
                          )}
                        />
                      </Recommended>
                    </FormRow>
                  </ReviewsContainer>
                </CustomForm>
              </FlexContainer>
            </Col>
          </Row>
        </MainContainer>
      </form>

      <StepButtons
        buttonsOptions={{
          primaryButton: {
            isValid: (formState.isValid && formState.isDirty) || reviewData,
            label: t('review.next'),
            action: () => submitHandler(getValues()),
          },
          ...(backProps && {
            secondaryButton: {
              label: backProps.backTitle,
              action: () => backProps.backHandler(),
            },
          }),
        }}
      />
    </>
  )
}

export default Component
