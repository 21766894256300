import { put, takeLatest } from 'redux-saga/effects'
import {
  GET_SETTINGS_SORT,
  GET_SETTINGS_SORT_SUCCESS,
  GET_POSTULACIONES_SETTINGS_SORT,
  GET_POSTULACIONES_SETTINGS_SORT_SUCCESS,
  GET_EMPRESAS_SETTINGS_SORT,
  GET_EMPRESAS_SETTINGS_SORT_SUCCESS,
  SET_EMPRESAS_SETTINGS_SORT_SUCCESS,
} from '../types/sort'
import { SITE_ID } from '../../constants'
import settings from '../../constants/features-per-country'

function* getSettings() {
  yield put({ type: GET_SETTINGS_SORT_SUCCESS, payload: settings[`${SITE_ID}`].sort })
}

function* getPostulacionesSortSettings() {
  yield put({ type: GET_POSTULACIONES_SETTINGS_SORT_SUCCESS, payload: settings[`${SITE_ID}`].sortPostulaciones })
}

function* getEmpresasSortSettings() {
  yield put({ type: GET_EMPRESAS_SETTINGS_SORT_SUCCESS, payload: settings[`${SITE_ID}`].sortEmpresas })
}

function* setEmpresasSortSettings(sortEmpresas) {
  yield put({ type: SET_EMPRESAS_SETTINGS_SORT_SUCCESS, payload: sortEmpresas })
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_SETTINGS_SORT, getSettings)
  yield takeLatest<any>(GET_POSTULACIONES_SETTINGS_SORT, getPostulacionesSortSettings)
  yield takeLatest<any>(GET_EMPRESAS_SETTINGS_SORT, getEmpresasSortSettings)
  yield takeLatest<any>(SET_EMPRESAS_SETTINGS_SORT_SUCCESS, setEmpresasSortSettings)
}
