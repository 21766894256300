import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const CardComponentWrapper = styled('div')``

export const CardContentWrapper = styled('div')`
  display: block;
  position: relative;
  color: rgba(0, 0, 24, 0.6);

  &:hover {
    text-decoration: none;
    color: rgba(0, 0, 24, 0.6);
  }
`

export const CardContainer = styled('div')`
  padding: 16px;
`

export const InfoCol = styled('div')`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    justify-content: space-between;
  }
`

export const DataRow = styled('div')`
  display: flex;
  align-items: center;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`

export const DataIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.black84};
  margin-right: 8px;
`

export const LoaderContainer = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin: 0 0 15px;
`
