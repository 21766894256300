import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Button } from '@navent-jobs/ui-kit/components/button'

const selectStyles = `
  input {
    line-height: 24px;
    font-size: 14px;
  }

  .basic-single {
    position: static!important;
    
    .select__control{
      .select__value-container {
        padding: 0;
        line-height: 24px;
        font-size: 14px;
      }
    }

    .select__menu {
      left: 0px;
      top: calc( 100% + 4px );
    }
  }
`
const FieldsContainers = `
  position: relative;
  z-index: 1;
  width: 100%;

  &:after {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #00001814;
  }
`
const FormActiveStyles = theme => `
  outline: none;
    
  &:after {
    background-color: #000DF1;
  }

  & > input{
    color: #000DF1;
  }

  .select__indicators {
    i:before {
      background-color: ${theme.colors.secondary.lighten};
    }
  }
`

export const SearchbarContainer = styled.div`
  position: relative;
  z-index: 10;
  padding: 1px;
  width: 100%;
  ${({ isZonajobs }) =>
    isZonajobs
      ? `
    max-width: initial;
    margin:0px;
      `
      : `
  max-width: 620px;
  margin-right: 24px;
  `}
`

export const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height || 'auto'};
  background: ${({ theme, isZonajobs }) => (isZonajobs ? '#FFFF' : theme.colors.grey)};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.greyDark};

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    background-color: transparent;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }

  ${({ isActive, theme }) => isActive && FormActiveStyles(theme)}
`

export const KeywordFieldContainer = styled.div`
  ${FieldsContainers}
  padding: 0px 12px;
  ${selectStyles}
`

export const LocationFieldContainer = styled.div`
  ${FieldsContainers}
  ${({ isZonajobs }) =>
    isZonajobs
      ? `
    max-width: 300px;
    padding: 0px 12px 0px 55px;`
      : `max-width: 208px;
    padding: 0px 12px 0px 35px;
    `}
  ${selectStyles}
`

export const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  line-height: 24px;
  z-index: 1;
  max-width: 50px;
  ${({ isZonajobs }) =>
    isZonajobs
      ? `
    max-width: 180px;
    padding: 0px 8px;
      `
      : `
  max-width: 50px;
  `}
`

export const ButtonSearch = styled(Button)`
  background-color: transparent;
  border: none;
  padding: 0;
  min-width: 28px;
  width: 28px;
  height: 28px;
  vertical-align: middle;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black8};
    color: ${({ theme }) => theme.colors.link.normal};
    border-radius: 50%;
  }
`

export const FieldIcon = styled(Icon)`
  position: absolute;
  left: 11px;
  top: 50%;
  transform: translate(0, -50%);
`
