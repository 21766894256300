import styled from 'styled-components'

export const MainInfoCard = styled('div')`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  scroll-behavior: smooth;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    flex-direction: column;
    justify-content: center;
  }`}
`

export const Company = styled('h3')`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black100};
  margin: 0px 3px 0px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 56%;

  ${({ mobileFormat, theme, showLogo, isRead }) =>
    !mobileFormat
      ? `@media (min-width: ${theme.screen.xlMin}) {
      font-size: 12px;
      line-height: 16px;
    }`
      : `max-width: calc(100% - 24px - ${showLogo ? 14 : 0}px - ${isRead ? 42 : 0}px);`}
`

export const CardHeader = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  height: ${({ hasLogo, hideCompanyRow }) => {
    if (hasLogo) return '44px'
    if (hideCompanyRow) return 'auto'
    return '36px'
  }};
  width: 100%;
`

export const LogoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #c5cee0;
  width: 68px;
  height: 44px;
  background: white;
  padding: 1px;
  margin-right: 12px;
  overflow: hidden;
`

export const Logo = styled('img')`
  border-radius: 8px;
  width: 68px;
  height: 44px;
  transition: all 0.3s ease-in-out;
`

export const CustomText = styled('h3')`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black60};
`

export const Title = styled('h3')`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-bottom: 0px;

  ${({ mobileFormat, hideCompanyRow, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    ${hideCompanyRow ? '' : 'margin-bottom: 4px;'}
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }`}
`

export const Description = styled('p')`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #222b45;
  margin: 0px;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ clamp }) => clamp || 5};
  overflow: hidden;
  word-break: break-word;

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    font-size: 12px;
    line-height: 16px;
    max-height: 52px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }`}
`

export const NewTag = styled('span')`
  display: inline;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.tertiary.darken};
  font-weight: 600;
  margin-right: 3px;
  font-size: 12px;
  line-height: 16px;
`

export const DataRowTitle = styled('div')`
  display: flex;
  align-items: flex-start;
  margin: 0px;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  scroll-behavior: smooth;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
      flex-direction: row;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
  }`}
`

export const InfoMobile = styled('div')`
  margin: 8px 0px;
`

export const CardBody = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`

export const HeaderCol = styled('div')`
  position: relative;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  scroll-behavior: smooth;
  flex-basis: 0;
  flex-grow: 1;
  max-width: ${({ mobileFormat, hasLogo }) => (mobileFormat && !hasLogo ? '100%' : 'calc(100% - 70px);')};
`

export const HeaderContainer = styled('div')`
  ${({ mobileFormat, theme, tipoAviso }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    display: flex;
    height: 100%;
    align-items: center;
    border-right: ${tipoAviso === 'freemium' ? theme.colors.black24 : theme.colors.secondary.ultraLighten}
      1px solid;
  }`}
`

export const DateRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${({ isRead }) => (isRead ? '5px' : 0)};
`

export const HeaderColContainer = styled('div')`
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
`

export const DataCol = styled('div')`
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-scroll-behavior: smooth;
  -moz-scroll-behavior: smooth;
  -ms-scroll-behavior: smooth;
  scroll-behavior: smooth;
`

export const Rating = styled('h3')`
  font-weight: 600;
  font-size: 12px;
  color: #666674;
  margin-right: 4px;
`
