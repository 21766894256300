import styled from 'styled-components'

export const Title = styled.p`
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
  text-align: left;
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 52px;
    line-height: 64px;
    margin-bottom: 36px;
    text-align: center;
  }
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 64px;
    text-align: center;
  }
`

export const BoldText = styled.p`
  display: inline;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-bottom: 0;
`

export const TableWrapper = styled.div`
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  width: fit-content;
  padding: 24px;
  margin: 0 auto;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 12%);
  width: 100%;
`

export const Page = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 24px 16px;
  background: no-repeat 0% 100% url(${({ urlImg }) => urlImg});

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 117px 0px;
  }
`

export const HeaderTable = styled.table`
  padding-right: 24px;
`

export const LevelTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const PrimaryHeader = styled.th`
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
  padding: 0 24px 16px 0;
  ${({ isMobilePortrait, theme }) => isMobilePortrait && `border-right: 1px solid ${theme.colors.black16};`}

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 2.5%;
  }
`

export const SecondaryHeader = styled.th`
  color: ${({ theme }) => theme.colors.black84};
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
  padding: 0 0 16px 24px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 2.5%;
  }
`

export const DataTable = styled.td`
  color: ${({ theme }) => theme.colors.black84};
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  ${({ isMobilePortrait, theme }) => isMobilePortrait && `border-right: 1px solid ${theme.colors.black16};`}
  ${({ left }) => (left ? 'padding: 16px 24px 16px 0;' : 'padding: 16px 0 16px 24px;')}

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 2.5%;
  }
`

export const RowTable = styled.tr`
  border-top: 1px solid ${({ theme }) => theme.colors.black16};
`
