import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const CardPlaceholderTablet = props => {
  // obtenemos el ancho de LoaderContainer
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const widthWithMargin = wrapperWidth * 0.95
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={248}
        viewBox="0 0 100% 248"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <path d="M 0 8 a 8 8 0 18 -8 h 680 a 8 8 0 18 8 v 232 a 8 8 0 1 -8 8 H 8 a 8 8 0 1 -8 -8 V 8 z" />
        <path d="M 0 0 h 6 v 248 H 0 z" />
        <rect x="16" y="12" rx="4" ry="4" width="68" height="44" />
        <rect x="96" y="18" rx="2" ry="2" width="220" height="8" />
        <rect x="96" y="42" rx="2" ry="2" width="157" height="8" />
        <rect x={widthWithMargin - 118} y="216" rx="4" ry="4" width="118" height="20" />
        <rect x="36" y="223" rx="2" ry="2" width="86" height="6" />
        <rect x="16" y="218" rx="4" ry="4" width="16" height="16" />
        <rect x="36" y="173" rx="2" ry="2" width="144" height="8" />
        <rect x="16" y="169" rx="4" ry="4" width="16" height="16" />
        <rect x="36" y="198" rx="2" ry="2" width="111" height="8" />
        <rect x="16" y="194" rx="4" ry="4" width="16" height="16" />
        <rect x="36" y="148" rx="2" ry="2" width="99" height="8" />
        <rect x="16" y="144" rx="4" ry="4" width="16" height="16" />
        <rect x="16" y="92" rx="2" ry="2" width={widthWithMargin} height="8" />
        <rect x="16" y="108" rx="2" ry="2" width={widthWithMargin} height="8" />
        <rect x="16" y="124" rx="2" ry="2" width="300" height="8" />
        <rect x="16" y="68" rx="2" ry="2" width="571" height="12" />
      </ContentLoader>
    </LoaderContainer>
  )
}

export default CardPlaceholderTablet
