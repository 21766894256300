import { merge } from 'lodash'

import all from '../all'
import pdaPago from './pda-pago'
import curriculum from './curriculum'
import sortEmpresas from './sortEmpresas'
import products from './products'
import flujoCvParser from './onboarding'
import fichaAviso from './ficha-aviso'
import searchAvisosV2 from './search-avisos-v2'
import feria from './feria'
import gptw from './gptw'
import avisosFake from './avisos-fake'

const bmec = {
  ...all,
  pdaPago,
  curriculum: merge({}, all.curriculum, curriculum),
  sortEmpresas: merge({}, all.sortEmpresas, sortEmpresas),
  products: merge({}, all.products, products),
  flujoCvParser: merge({}, all.flujoCvParser, flujoCvParser),
  fichaAviso: merge({}, all.fichaAviso, fichaAviso),
  searchAvisosV2: merge({}, all.searchAvisosV2, searchAvisosV2),
  feria,
  gptw: merge({}, all.gptw, gptw),
  avisosFake: merge({}, all.avisosFake, avisosFake),
}

export default bmec
