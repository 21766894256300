const colorByStatus = ({ read, applyed, applyedIncomplete, theme }) => {
  return (read || applyed) && !applyedIncomplete ? theme.colors.black48 : theme.colors.alternativeError
}

export const activeStyles = props => {
  const { tipoAviso, read, applyed, applyedIncomplete, theme } = props
  const { border } = theme.colors.aviso
  const readAppliedIncomplete = read || applyed || applyedIncomplete

  let styles = `background: ${readAppliedIncomplete ? theme.colors.grey : '#FFFFFF'};                
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-color: ${readAppliedIncomplete ? colorByStatus({ ...props }) : border[tipoAviso]};`

  if (tipoAviso === 'freemium' || tipoAviso === 'talento') {
    styles += `border-color: ${readAppliedIncomplete ? colorByStatus({ ...props }) : border[tipoAviso]};`
  }

  return styles
}

export const avisosSkin = props => {
  const { read, applyed, isActive, theme, mobileFormat } = props
  const readOrApplyed = read || applyed

  const commons = `cursor: pointer;
                position: relative;
                box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.12);
                border: 1px solid;
                border-color: transparent;
                transition: all .2s ease-in-out;
                border-radius: 8px;
                transition: all .3s ease-in-out;

                &:hover {
                  background: ${readOrApplyed ? theme.colors.grey : '#FFFFFF'};
                  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
                }
                
                &:active:not(:focus){
                  ${activeStyles(props)}
                }

                ${
                  !mobileFormat &&
                  `@media (min-width: ${theme.screen.xlMin}){
                  ${isActive ? activeStyles(props) : ''}`
                }}`

  const avisoTalento = `border-radius: 8px;
                      border: 1px solid ${readOrApplyed ? theme.colors.black48 : theme.colors.black24};
                      background: ${readOrApplyed ? theme.colors.grey : 'transparent'};
                      
                      &:hover {
                        border-color: transparent;
                      }`

  const avisoFreemium = `border-radius: 8px;
                      border: 1px solid ${theme.colors.black24};
                      background: ${readOrApplyed ? theme.colors.grey : 'transparent'};

                      &:hover {
                        background: transparent;
                      }`

  const highlightedPlans = `background: ${readOrApplyed ? theme.colors.grey : '#FFFFFF'};`

  const allTheRest = `background: ${readOrApplyed ? theme.colors.grey : '#FFFFFF'};`

  return {
    freemium: `${commons} ${avisoFreemium}`,
    talento: `${commons} ${avisoTalento}`,
    home: `${commons} ${highlightedPlans}`,
    destacado: `${commons} ${highlightedPlans}`,
    tecnologia: `${commons} ${highlightedPlans}`,
    simple: `${commons} ${allTheRest}`,
    zonajobs: `${commons} ${highlightedPlans}`,
    bumeran: `${commons} ${highlightedPlans}`,
    infinit: `${commons} ${allTheRest}`,
  }
}
