import React from 'react'
import { ComponentContainer, CustomImage } from './mixins'
import SignUpImageBum from '../../../../assets/images/sign-in.svg'
import SignUpImageZona from '../../../../assets/images/sign-in-zonajobs.svg'
import { IS_ZONA_JOBS } from '../../../../constants'

const Component = () => {
  return (
    <ComponentContainer>
      <CustomImage
        src={IS_ZONA_JOBS ? SignUpImageZona : SignUpImageBum}
        width="414px"
        height="539px"
        alt="Login Image"
      />
    </ComponentContainer>
  )
}

export default Component
