import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { Suripanta } from '@navent-jobs/ui-kit/components/suripanta'
import { PortalTheme } from '@navent-jobs/ui-kit/themes'
import { init, SiteConfig, SiteIds } from '@navent-jobs/config'
import { GoogleOAuthProvider } from '@react-oauth/google'
import moment from 'moment'
import 'moment/locale/es'
import { Provider } from 'react-redux'
import scripts from './scripts'
import App from './routes'
import { IS_ZONA_JOBS, SITE_ID } from './constants'
import configureStore from './redux/store'
import SiteLanguage from './site-language'
/**
 * Translations
 */
import './i18n'

/**
 * Interfaz global para el window.xxxx
 */
declare global {
  interface Window {
    GOOGLE_MAPS_KEY: string
    GOOGLE_LOGIN_KEY: string
    GOOGLE_LOGIN_KEY_ZJ: string
    LINKEDIN_CLIENT_ID: string
    adsbygoogle: any
    _gscq: any
  }
}

/**
 *  Initialize moment with ES locale
 */

moment.locale('es')

// Initialize API client
init({ baseURL: process.env.REACT_APP_API_BASE_URL || '/' })

// eslint-disable-next-line no-console
console.log(
  // eslint-disable-next-line max-len
  '%c\n                                               \n      ██╗ █████╗ ██████╗ ██╗███╗  ██╗████████╗ \n      ██║██╔══██╗██╔══██╗██║████╗ ██║╚══██╔══╝ \n      ██║██║  ██║██████╔╝██║██╔██╗██║   ██║    \n ██╗  ██║██║  ██║██╔══██╗██║██║╚████║   ██║    \n ╚█████╔╝╚█████╔╝██████╔╝██║██║ ╚███║   ██║    \n  ╚════╝  ╚════╝ ╚═════╝ ╚═╝╚═╝  ╚══╝   ╚═╝    \n                                               \n     TRABAJANDO PARA QUE CONSIGAS LABURO!      \n',
  'font-family:monospace;color:#0A26EE;font-size:12px;'
)
/* 
     ██╗ █████╗ ██████╗ ██╗███╗  ██╗████████╗
     ██║██╔══██╗██╔══██╗██║████╗ ██║╚══██╔══╝
     ██║██║  ██║██████╔╝██║██╔██╗██║   ██║
██╗  ██║██║  ██║██╔══██╗██║██║╚████║   ██║
╚█████╔╝╚█████╔╝██████╔╝██║██║ ╚███║   ██║
 ╚════╝  ╚════╝ ╚═════╝ ╚═╝╚═╝  ╚══╝   ╚═╝
*/
const Scripts = () => {
  if (scripts.length > 0) {
    const scriptsList = scripts.map(script => {
      return (
        <Suripanta key={script.name} {...script}>
          {script.code}
        </Suripanta>
      )
    })

    return <>{scriptsList}</>
  }

  return null
}

const getGoogleLoginKey = siteId => {
  if (siteId === SiteIds.ZJAR) {
    return window.GOOGLE_LOGIN_KEY_ZJ ?? process.env.REACT_APP_GOOGLE_LOGIN_KEY_ZJ
  }
  return window.GOOGLE_LOGIN_KEY ?? process.env.REACT_APP_GOOGLE_LOGIN_KEY
}

const googleLoginClientId = getGoogleLoginKey(SITE_ID)

const { REACT_APP_ENVIRONMENT } = process.env
const faviconFolder =
  SITE_ID === SiteIds.ZJAR ? SiteConfig.ZJAR.nombre.toLowerCase() : SiteConfig.BMAR.nombre.toLowerCase()
const faviconPath = REACT_APP_ENVIRONMENT === '1' ? `/${faviconFolder}/` : `/candidate/${faviconFolder}/`

/**
 * Init Store
 */
const store = configureStore()

const heatmapCode = {
  BMAR: 'gojbmpryfd',
  BMCL: 'h5g41ovlry',
  BMEC: 'h5g0iz5jjv',
  BMMX: 'h5g1ejigst',
  BMPA: 'h5fyp8dquv',
  BMPE: 'h5fsbx3pff',
  BMVE: 'h5fwxi85a5',
  ZJAR: 'h5g2zjir6r',
}

ReactDOM.render(
  <Suspense fallback="Loading...">
    <Provider store={store}>
      <GoogleOAuthProvider clientId={googleLoginClientId}>
        <PortalTheme portal={IS_ZONA_JOBS ? 'zonajobs' : 'bumeran'}>
          <Helmet>
            <link rel="shortcut icon" href={`${faviconPath}/favicon/favicon.ico`} />
            <link rel="apple-touch-icon" sizes="180x180" href={`${faviconPath}/favicon/apple-touch-icon.png`} />
            <link rel="icon" type="image/png" sizes="32x32" href={`${faviconPath}/favicon/favicon-32x32.png`} />
            <link rel="icon" type="image/png" sizes="16x16" href={`${faviconPath}/favicon/favicon-16x16.png`} />
            <link rel="manifest" href={`${faviconPath}/favicon/site.webmanifest`} />
            <link rel="mask-icon" href={`${faviconPath}/favicon/safari-pinned-tab.svg" color="#3d47f5`} />
            <meta property="og:image" content={`${faviconPath}/favicon/social-logo.png`} />
            <script type="text/javascript">
              {`(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "${heatmapCode[SITE_ID || 'BMAR']}");`}
            </script>
          </Helmet>
          <SiteLanguage />
          <App />
          <Scripts />
        </PortalTheme>
      </GoogleOAuthProvider>
    </Provider>
  </Suspense>,
  document.getElementById('root')
)
