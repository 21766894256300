import { Button } from '@navent-jobs/ui-kit/components/button'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: 16px 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 32px;
  }
`

export const ImgContainer = styled.div`
  margin-bottom: 16px;
  text-align: center;
  & > img {
    max-width: 242px;
  }

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    & > img {
      max-width: 302px;
    }
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const Claim = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  color: black;
  text-align: center;
`

export const Warning = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const WarningText = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-left: 5px;
  text-align: left;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const ButtonBack = styled(Button)`
  width: 100%;
  max-width: 200px;
`
