import styled from 'styled-components'

export const ImgLogo = styled.img`
  height: ${({ logoStyles }) => (logoStyles?.heightMobile ? `${logoStyles.heightMobile}px` : '24px')};
  margin-bottom: ${({ logoStyles }) => (logoStyles?.marginBottomMobile ? `${logoStyles.marginBottomMobile}px` : '0px')};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    height: ${({ logoStyles }) => (logoStyles?.heightDesktop ? `${logoStyles.heightDesktop}px` : '31px')};
    margin-bottom: ${({ logoStyles }) =>
      logoStyles?.marginBottomDesktop ? `${logoStyles.marginBottomDesktop}px` : '0px'};
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-bottom: 24px;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 44px;
    text-align: center;
  }
`

export const Img = styled.img`
  height: 132px;
`

export const Subtitle = styled.p`
  margin: 12px auto;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
`

export const Page = styled.div`
  margin: 0;
  background: no-repeat 0% 100% url(${({ urlImg }) => urlImg}), #ffffff;
  padding: 16px 16px 24px;
  margin-bottom: 32px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin: 80px 0 0;
    padding: 80px 0;
  }
`

export const ColContainer = styled.div`
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 51px;
  }
`

export const Iframe = styled.iframe`
  border-radius: 24px;
  width: 100%;
  height: 182px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    height: 356px;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin: 5px 0 0;
    height: 471px;
  }
`
