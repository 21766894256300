import { Link } from '@navent-jobs/ui-kit/components/link'
import styled from 'styled-components'

export const SubtitleText = styled('p')`
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: ${({ theme }) => theme.colors.black84};
`

export const CardNotEnded = styled('div')`
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  padding: 17px;
  text-align: center;
  margin: 24px 0;
  border: ${({ theme }) => theme.colors.greyDark} 1px solid;
`

export const CustomLink = styled(Link)`
  padding: 0;
  font-weight: bold;
  text-decoration: underline;
  line-height: 20px;
`
