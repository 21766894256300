import { Request, Get, Post, Delete } from '@navent-jobs/config'
import { getMessaging, getToken, Messaging } from 'firebase/messaging'
import { firebaseApp } from './firebase'
import loggerService from './logger-service'
import { firebaseVapidKey } from '../constants/firebase'
import { loginRestringidoCookieExist } from '../utils/cookies'

export class ErrorNotification extends Error {}

class Notifications {
  // 24 horas
  static FCM_EXPIRE_TIME = 1000 * 60 * 60 * 24

  messaging: Messaging | null

  constructor() {
    try {
      this.messaging = getMessaging(firebaseApp)
    } catch {
      // En caso que el browser no tenga soporte para el servicio de notificaciones
      this.messaging = null
    }
  }

  // Consuta si el usuario tiene notificaciones sin leer return Number
  public async GetUnreadNotifications() {
    const request = new Request()
    request.path = '/api/notificacion/nuevas?platform=WEB'
    request.method = Get
    return request.call().catch(() => {
      throw new ErrorNotification(`Error al leer las notificaciones nuevas`)
    })
  }

  // Consulta las notificaciones del postulante
  public async GetNotificationsList() {
    const request = new Request()
    request.path = '/api/notificacion/listado?platform=WEB'
    request.method = Get
    return request.call().catch(() => {
      throw new ErrorNotification(`Error al leer el listado de notificaciones`)
    })
  }

  // Marca de lectura
  public async MarkReadaNotificationsList() {
    const request = new Request()
    request.path = '/api/notificacion/lectura'
    request.method = Post
    return request.call().catch(() => {
      throw new ErrorNotification(`Error en marca de lectura, notificaciones`)
    })
  }

  // Actualiza en la base de datos el token FCM del usuario
  private async updateTokenDatabase(token: string) {
    const request = new Request()
    request.path = '/api/notificacion/token'
    request.method = Post
    request.body = { platform: 'WEB', token }
    return request.call().catch(() => {
      // No propagamos el error solo logueamos
      loggerService.logError('Error al actualizar el token FCM en la base de datos')
    })
  }

  // Actualiza el token FCM del usuario
  public async updateTokenFCM() {
    const loginFull = loginRestringidoCookieExist() === null
    if (this.messaging && loginFull) {
      // Verificamos si no se notifico anteriormente o paso x tiempo desde la ultima notificacion al backend
      const savedTokenTime = localStorage.getItem('PostulanteTokenFCMExpireTime')
      const now = new Date()
      const tokenExpired = !savedTokenTime || now.getTime() - Number(savedTokenTime) > Notifications.FCM_EXPIRE_TIME
      if (tokenExpired) {
        // Si el usuario no le da permisos se retorna un null y no se hace nada
        const token = await getToken(this.messaging, { vapidKey: firebaseVapidKey }).catch(() => null)
        if (token) {
          await this.updateTokenDatabase(token)
          localStorage.setItem('PostulanteTokenFCMExpireTime', now.getTime().toString())
        }
      }
    }
  }

  // Elimina el token FCM del usuario. Por el momento no se invoca hasta tener logout en react
  public async removeTokenFCM() {
    const savedTokenTime = localStorage.getItem('PostulanteTokenFCMExpireTime')
    if (savedTokenTime) {
      const request = new Request()
      request.path = '/api/notificacion/token'
      request.method = Delete
      return request
        .call()
        .then(() => {
          localStorage.removeItem('PostulanteTokenFCMExpireTime')
        })
        .catch(() => {
          // No propagamos el error solo logueamos
          loggerService.logError('Error al eliminar el token FCM en la base de datos')
        })
    }
  }
}

const notifications = new Notifications()

export default notifications
