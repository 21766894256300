import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const CopyButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    /* 
     * Styles Fix: se fixea con "min-width" & "min-height",
     * porque el componente Input de la ui-kit evita width & height en :hover
     */
    min-width: 32px;
    min-height: 32px;
    background-color: ${({ theme }) => theme.colors.black16};
  }
`

export const CustomText = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black84};
  margin-bottom: 4px;
  text-align: initial;
`

export const WrapperCopyLink = styled.div`
  width: 100%;

  @media (min-width: 652px) {
    width: 595px;
  }
`
