import { merge } from 'lodash'
import all from '../all'
import userPremium from './user-premium'
import pdaPago from './pda-pago'
import curriculum from './curriculum'
import marketplace from './marketplace'
import products from './products'
import carrouselCompanies from './carrousel-companies'
import postulacion from './postulacion'
import sortEmpresas from './sortEmpresas'
import fichaAviso from './ficha-aviso'
import feria from './feria'
import avisosFake from './avisos-fake'

const bmcl = {
  ...all,
  userPremium: { ...all.userPremium, ...userPremium },
  pdaPago,
  curriculum: merge({}, all.curriculum, curriculum),
  marketplace: merge({}, all.marketplace, marketplace),
  products: merge({}, all.products, products),
  carrouselCompanies: merge({}, all.carrouselCompanies, carrouselCompanies),
  postulacion: merge({}, all.postulacion, postulacion),
  sortEmpresas: merge({}, all.sortEmpresas, sortEmpresas),
  fichaAviso: merge({}, all.fichaAviso, fichaAviso),
  feria,
  avisosFake: merge({}, all.avisosFake, avisosFake),
}

export default bmcl
