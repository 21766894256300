import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const CarouselContainer = styled('div')`
  width: 100%;
  overflow: hidden;
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyDark};

  @media (min-width: 1024px) {
    padding-bottom: 20px;
  }
`

export const SwiperCustom = styled(Swiper)`
  && {
    overflow: initial;
  }

  .swiper-pagination {
    transform: translate(0px, 39px);

    .swiper-pagination-bullet-active {
      background: ${({ theme }) => theme.colors.secondary.normal};
    }
  }

  .swiper-slide {
    height: auto;
  }
`
