import styled from 'styled-components'

/**
 * Select Filter
 */

export const Label = styled.span``

export const Quantity = styled.span`
  font-weight: normal;
  color: rgba(0, 0, 24, 0.48);
`

export const OptionComponet = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

/**
 * Acordeon Filter
 */

export const AcordeonFilterComponent = styled.div`
  width: 100%;
  position: relative;
`

export const AcordeonFilterToggle = styled.div`
  font-size: 14px;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  padding: 0 16px;
  line-height: 34px;
  border: 1px solid;
  border-color: ${props => (props.visible ? `#3d47f5` : `#ffffff`)};
  color: ${props => (props.visible || props.widthFiltersApplied ? `#3D47F5` : `rgba(0, 0, 24, 0.6)`)};

  i {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%) rotate(0deg);
    transition: all 0.2s ease-in-out;
  }

  ${props =>
    props.visible &&
    !props.widthFiltersApplied &&
    `i { transform: translate(0, -50%) rotate(180deg); &:before{ background-color: #3D47F5; } }`};
`

export const AcordeonFilterToggleButton = styled.button`
  border: 0;
  padding: 0;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  outline: 0;
`

export const RemoveFilter = styled.button`
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline: 0;
  position: absolute;
  top: 12px;
  right: 0;
`

export const RemoveAllFilters = styled.button`
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline: 0;
`

export const Acordeon = styled.div`
  position: absolute;
  z-index: 20;
  top: calc(100% + 4px);
  right: 0;
  min-width: 335px;
  background: #ffffff;
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
`

export const AcordeonItemComponent = styled.div`
  padding: 0px;
  line-height: 44px;
  border-bottom: 1px solid rgba(0, 0, 24, 0.08);

  &:last-child {
    border-bottom: 0;
  }
`

export const NewTag = styled.p<{ theme }>`
  height: 20px;
  background-color: ${props => props.theme.colors.primary.normal};
  font-family: 'Hind Vadodara';
  color: white;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  padding: 0 10px;
  margin: 0 0 0 8px;
  border-radius: 4px;
`

export const AcordeonItemToggle = styled.button`
  padding: 0px 16px;
  margin: 0px;
  border: 0px;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 44px;
  color: rgba(0, 0, 24, 0.6);
  text-align: left;
  position: relative;
  background-color: transparent;
  outline: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
    position: absolute;
    right: 16px;
  }

  ${props => props.visible && `i { transform: rotate(180deg); }`};
`
export const AcordeonItemContent = styled.ul`
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow: auto;
  display: ${props => (props.visible ? 'block' : 'none')};
`

export const AcordeonItemOption = styled.li`
  display: block;
  line-height: 20px;
  margin-bottom: 12px;
  color: rgba(0, 0, 24, 0.6);
  padding: 0px 16px;
  list-style: none;
  cursor: pointer;

  &:last-child {
    margin-bottom: 20px;
  }

  span {
    float: right;
  }

  &.is-children {
    line-height: 44px;
    margin: 0px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`

export const ColWithCenterAlign = styled.div`
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-right: 12px;
  padding-left: 12px;
`
