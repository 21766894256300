import { IS_ZONA_JOBS } from '../../../constants'

export const planConfig = {
  talento: {
    icon: {
      name: '',
      size: '15',
      color: 'white',
    },
    showDescription: false,
    showLogo: false,
    showCompanyName: true,
    showNewTag: false,
    showSponsoredPlanText: false,
    showHighlightWrapper: false,
    showHighlight: false,
    showHighlightText: false,
    isAvisoZonaJobs: false,
    isAvisoBumeran: false,
    showVacancies: false,
    showMatch: true,
  },
  freemium: {
    icon: {
      name: '',
      size: '15',
      color: 'white',
    },
    showDescription: false,
    showLogo: false,
    showCompanyName: true,
    showNewTag: false,
    showSponsoredPlanText: false,
    showHighlightWrapper: false,
    showHighlight: false,
    showHighlightText: false,
    isAvisoZonaJobs: false,
    isAvisoBumeran: false,
    showVacancies: false,
    showMatch: false,
  },
  tecnologia: {
    icon: {
      name: 'icon-light-code',
      size: '15',
      color: 'white',
    },
    text: 'Tecnología',
    showDescription: true,
    showLogo: true,
    showCompanyName: true,
    showNewTag: true,
    showSponsoredPlanText: false,
    showHighlightWrapper: false,
    showHighlight: true,
    showHighlightText: true,
    isAvisoZonaJobs: false,
    isAvisoBumeran: false,
    showVacancies: false,
    showMatch: true,
  },
  simple: {
    icon: {
      name: '',
      size: '15',
      color: 'white',
    },
    showDescription: true,
    showLogo: false,
    showCompanyName: true,
    showNewTag: true,
    showSponsoredPlanText: false,
    showHighlightWrapper: false,
    showHighlight: false,
    showHighlightText: false,
    isAvisoZonaJobs: false,
    isAvisoBumeran: false,
    showVacancies: false,
    showMatch: true,
  },
  destacado: {
    icon: {
      name: 'icon-light-star',
      size: '15',
      color: 'white',
    },
    text: IS_ZONA_JOBS ? 'Gold' : 'Destacado',
    showDescription: true,
    showLogo: true,
    showCompanyName: true,
    showNewTag: true,
    showSponsoredPlanText: false,
    showHighlightWrapper: false,
    showHighlight: true,
    showHighlightText: true,
    isAvisoZonaJobs: false,
    isAvisoBumeran: false,
    showVacancies: false,
    showMatch: true,
  },
  home: {
    icon: {
      name: 'icon-bold-star',
      size: '15',
      color: 'white',
    },
    text: IS_ZONA_JOBS ? 'Platinum' : 'Superdestacado',
    showDescription: true,
    showLogo: true,
    showCompanyName: true,
    showNewTag: false,
    showSponsoredPlanText: true,
    showHighlightWrapper: true,
    showHighlight: true,
    showHighlightText: false,
    isAvisoZonaJobs: false,
    isAvisoBumeran: false,
    showVacancies: false,
    showMatch: true,
  },
  zonajobs: {
    icon: {
      name: '',
      size: '15',
      color: 'white',
    },
    showDescription: false,
    showLogo: true,
    showCompanyName: true,
    showNewTag: false,
    showSponsoredPlanText: false,
    showHighlightWrapper: false,
    showHighlight: true,
    showHighlightText: false,
    isAvisoZonaJobs: true,
    isAvisoBumeran: false,
    showVacancies: false,
    showMatch: false,
  },
  bumeran: {
    icon: {
      name: '',
      size: '15',
      color: 'white',
    },
    showDescription: false,
    showLogo: true,
    showCompanyName: true,
    showNewTag: false,
    showSponsoredPlanText: false,
    showHighlightWrapper: false,
    showHighlight: true,
    showHighlightText: false,
    isAvisoZonaJobs: false,
    isAvisoBumeran: true,
    showVacancies: false,
    showMatch: false,
  },
  infinit: {
    icon: {
      name: '',
      size: '15',
      color: 'infinit',
    },
    showDescription: false,
    showLogo: true,
    showCompanyName: true,
    showNewTag: false,
    showSponsoredPlanText: false,
    showHighlightWrapper: false,
    showHighlight: false,
    showHighlightText: false,
    isAvisoZonaJobs: false,
    showVacancies: false,
    showMatch: true,
  },
}
