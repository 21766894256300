import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  ImageForEmptyState,
  ElementsContainer,
  TextContainer,
  Subtitle,
  ComponentContainer,
  CustomButton,
  Heading,
} from './mixins'
import EmptyFavoritas from '../../../../../../assets/images/empty-empresas-favoritas.svg'
import EmptyFavoritasZona from '../../../../../../assets/images/empty-empresas-favoritas-zona.svg'
import { IS_ZONA_JOBS } from '../../../../../../constants'

const EmptyState = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const goToSearch = () => {
    history.push('/listado-empresas')
  }

  return (
    <ElementsContainer>
      <ImageForEmptyState src={IS_ZONA_JOBS ? EmptyFavoritasZona : EmptyFavoritas} />
      <TextContainer>
        <Heading level={1}>{t('saveCompany.empty.title')}</Heading>
        <Subtitle>{t('saveCompany.empty.subtitle')}</Subtitle>
        <ComponentContainer>
          <CustomButton buttonoptions={{ variant: 'primary', size: 'medium', block: true }} onClick={goToSearch}>
            {t('saveCompany.empty.buttonText')}
          </CustomButton>
        </ComponentContainer>
      </TextContainer>
    </ElementsContainer>
  )
}

export default EmptyState
