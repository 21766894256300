import styled from 'styled-components'
import { Title } from '@navent-jobs/ui-kit/components/title'

export const EmpleosTotalText = styled(Title)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black84};
  font-weight: 600;
  line-height: 25px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (min-width: 1024px) {
    line-height: 36px;
    margin-bottom: 0;
  }
`

export const Total = styled('span')`
  color: ${({ theme }) => theme.colors.primary.normal};
`
