const feria = {
  logo: {
    width: '153px',
    height: '40px',
    alt: 'Logo Bumeran',
    className: 'logo',
  },
}

export default feria
