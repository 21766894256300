import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

// ui-kit
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// mixins
import { Container, LinkGptwCustom, Label } from './mixins'

const LinkGptw = ({ url, name }) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  return (
    <LinkGptwCustom
      onClick={e => e.currentTarget.blur()}
      href={url}
      linkoptions={{ variant: 'gray', type: 'link', size: 'small', target: '_blank' }}
    >
      <Container>
        <Icon name="icon-light-external-link" size="20" color={colors.black60} />
        <Label>{name}</Label>
      </Container>
    </LinkGptwCustom>
  )
}

export default LinkGptw
