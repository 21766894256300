import styled from 'styled-components'

export const GraphTooltip = styled.span`
  background: #ffffff;
  color: ${({ theme }) => theme.colors.black84};
  font-size: 12px;
  line-height: 1;
  display: flex;
  flex-direction: column;
`

export const GraphTooltipText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 4px 0;
`

export const TooltipTextRow = styled.div`
  display: flex;
  align-items: center;
`

export const Color = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
`

export const Level = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '15px')};
  color: ${({ color }) => color || '#000000'};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  line-height: 16px;
  margin: 0 0 0 9px;
`

export const Percentage = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ color }) => color};
  margin: 0 0 0 18px;
`
