import styled from 'styled-components'

export const ReviewsContainer = styled.div``

export const ReviewLabel = styled.label`
  position: relative;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.black84};

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 18px;
    margin-bottom: 0px;
  }
`

export const GeneralReviewLabel = styled(ReviewLabel)`
  font-weight: 600;
`

export const FormRow = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  :not(:last-child) {
    margin-bottom: 12px;
  }
`

export const Recommended = styled.div`
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    translate: 32px;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 88px;
`
