import interlinking from './interlinking'
import pda from './pda'
import sort from './sort'
import sortPostulaciones from './sortPostulaciones'
import sortEmpresas from './sortEmpresas'
import ctaDownloadApp from './cta-download-app'
import prerender from './prerender'
import filters from './filters'
import filtersCompanies from './filters-companies'
import filtersPerfilEmpresa from './filters-perfil-empresa'
import userPremium from './user-premium'
import pdaPago from './pda-pago'
import fichaAviso from './ficha-aviso'
import salarios from './salarios'
import curriculum from './curriculum'
import saveJobPosting from './save-job-posting'
import card from './card'
import marketplace from './marketplace'
import products from './products'
import estadisticas from './estadisticas'
import reviews from './reviews'
import carrouselCompanies from './carrousel-companies'
import googleAds from './google-ads'
import cursos from './cursos'
import postulacion from './postulacion'
import navbarMobile from './navbar-mobile'
import seguirEmpresa from './seguir-empresa'
import gptw from './gptw'
import promedioSalarial from './promedio-salarial'
import empleosMasBuscados from './empleos-mas-buscados'
import listadoAvisos from './listado-avisos'
import searchAvisosV2 from './search-avisos-v2'
import flujoCvParser from './onboarding'
import recomendados from './recomendados'
import avisosFake from './avisos-fake'
import areaAds from './area-ads'

const all = {
  ...pda,
  ...interlinking,
  ...prerender,
  sort,
  sortPostulaciones,
  sortEmpresas,
  ctaDownloadApp,
  filters,
  filtersCompanies,
  filtersPerfilEmpresa,
  userPremium,
  pdaPago,
  fichaAviso,
  salarios,
  curriculum,
  saveJobPosting,
  card,
  marketplace,
  products,
  reviews,
  estadisticas,
  carrouselCompanies,
  googleAds,
  cursos,
  postulacion,
  navbarMobile,
  seguirEmpresa,
  gptw,
  promedioSalarial,
  empleosMasBuscados,
  listadoAvisos,
  searchAvisosV2,
  flujoCvParser,
  recomendados,
  avisosFake,
  areaAds,
}

export default all
