import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import i18n from 'i18next'
import { useSearchKeyword } from '../../../hooks/search-keyword'

// components
import KeywordSelect from '../components/KeywordSelect'
import LocationSelect from '../components/LocationSelect'

// mixins
import {
  Form,
  SearchbarContainer,
  KeywordFieldContainer,
  LocationFieldContainer,
  ButtonContainer,
  ButtonSearch,
  FieldIcon,
} from './mixins'

// constants
import { DataLayers } from '../../../constants/gtm-events-site-search'
import {
  NuevoFiltro,
  pushFilterToRecentSearches,
} from '../../../pages/listado-avisos/components/filters/utils/pushfilter'
import features from '../../../constants/features-per-country'
import { SITE_ID } from '../../../constants'

/**
 * Traduce los nombres de los filtros cuando están aplicados
 */
const translateFiltersApplied = filtersApplied => {
  if (!filtersApplied) {
    return filtersApplied
  }
  return filtersApplied.map(filter => {
    return { ...filter, name: i18n.t(`filtros.${filter.name}`, { defaultValue: filter.name }) }
  })
}

const searchAvisosV2Enabled = features[SITE_ID || 'BMAR'].searchAvisosV2.enabled
const CAPITAL_FEDERAL = searchAvisosV2Enabled ? 'argentina|buenos-aires|capital-federal' : 'capital-federal'

const Component = ({
  recentSearches: recentSearchesService,
  availableLocations,
  resetLanding,
  onLocations,
  filtersApplied,
}) => {
  const { pathname, search } = window.location
  const [isActive, setIsActive] = useState(false)
  const { searchKeyword, storeSearchKeyword } = useSearchKeyword()
  const [keyword, setKeyword] = useState<{ keyword?: string; label: string; value: string } | undefined>(undefined)
  const [location, setLocation] = useState<{ label: string; value: string } | undefined>(undefined)
  const [manualChange, setManualChange] = useState(false)

  const history = useHistory()

  const setActive = () => {
    setIsActive(true)
  }

  // Desfocusea el input seleccionado
  const blurElement = () => {
    const element = document.activeElement as HTMLElement
    if (element) {
      element.blur()
    }
  }

  const handleMetric = (searchTerm, locationTerm, recentSearch) => {
    DataLayers.siteSearch(searchTerm, locationTerm, pathname, recentSearch)
  }

  const handleSearch = event => {
    event && event.preventDefault()
    const filtrosNuevos: NuevoFiltro[] = [{ type: 'busqueda', facetSelected: keyword || { label: '', value: '' } }]

    if (location?.value === CAPITAL_FEDERAL) {
      filtrosNuevos.push({ type: 'localidad', facetSelected: location })
    } else {
      filtrosNuevos.push({ type: 'provincia', facetSelected: location })
      // Remove localidad filter from filtersApplied if provincia is selected
      const index = filtersApplied?.findIndex(f => f.type === 'localidad')
      if (index >= 0) {
        filtersApplied.splice(index, 1)
      }
    }

    const redirectUrl = pushFilterToRecentSearches(filtrosNuevos, translateFiltersApplied(filtersApplied))

    if (history.location.pathname !== redirectUrl) {
      history.push(redirectUrl)
      resetLanding()
      keyword && storeSearchKeyword(keyword.label)
    }
    handleMetric(keyword, location, false)
    blurElement()
  }

  useEffect(() => {
    if (filtersApplied && !search.includes('query')) {
      const keyValue = filtersApplied?.find(f => f.id === 'query')?.value
      if (keyValue) {
        setKeyword({ label: searchKeyword || keyValue?.replaceAll('-', ' '), value: keyValue, keyword: keyValue })
      } else {
        setKeyword(undefined)
      }

      // Seteo capital federal en el buscador de provincias del searchbar del header
      const capitalLocation = filtersApplied?.find(f => f.type === 'localidad')
      if (capitalLocation && (capitalLocation.idSemantico || capitalLocation.id) === CAPITAL_FEDERAL) {
        setLocation({ label: capitalLocation?.name, value: capitalLocation?.idSemantico || capitalLocation?.id })
      } else {
        const locationValue = filtersApplied?.find(f => f.type === 'provincia')
        if (locationValue) {
          setLocation({ label: locationValue?.name, value: locationValue?.idSemantico || locationValue?.id })
        } else {
          setLocation(undefined)
        }
      }
    } else {
      setKeyword(undefined)
      setLocation(undefined)
    }
  }, [filtersApplied])

  useEffect(() => {
    if (manualChange) {
      setManualChange(false)
      handleSearch(null)
    }
  }, [manualChange])

  return (
    <SearchbarContainer>
      <Form isActive={isActive} height="36px">
        <KeywordFieldContainer>
          <KeywordSelect
            options={recentSearchesService.getInstance().getHistory()}
            handlerOnFocus={setActive}
            handlerOnBlur={setActive}
            handlerOnChange={setKeyword}
            handlerOnEnter={handleSearch}
            keyword={keyword}
            setKeyword={setKeyword}
            showOptions
            placeholder="Buscar empleo por puesto o palabra clave"
            handleMetric={handleMetric}
          />
        </KeywordFieldContainer>

        <LocationFieldContainer>
          <FieldIcon size="16" color="rgba(0, 0, 24, 0.24);" name="icon-light-location-pin" />

          <LocationSelect
            options={availableLocations}
            handlerOnFocus={onLocations}
            handlerOnBlur={setActive}
            handlerOnChange={value => {
              setLocation(value)
              setManualChange(true)
            }}
            value={location}
          />
        </LocationFieldContainer>

        <ButtonContainer>
          <ButtonSearch
            buttonoptions={{ size: 'small', variant: 'secondary', type: 'link' }}
            type="button"
            icon={{ name: 'icon-light-search' }}
            onClick={event => handleSearch(event)}
          />
        </ButtonContainer>
      </Form>
    </SearchbarContainer>
  )
}

const states = ({ filtersStore }) => ({
  filtersApplied: filtersStore.filtersApplied,
})

export default connect(states)(Component)
