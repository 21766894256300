import { Row } from '@navent-jobs/ui-kit/components/grid'
import styled from 'styled-components'

const widthLogo = '95px'
const heightLogo = '60px'

export const ComponentContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    align-items: center;
    flex-direction: row;
  }
`

export const LogoAndTitle = styled('div')`
  width: 100%;
  display: flex;
  margin-bottom: 8px;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    max-width: 55%;
    align-items: center;
    border-right: 1px solid #edf1f7;
    padding-right: 18px;
    margin-right: 18px;
    margin-bottom: 0px;
  }
`

export const LogoWrapper = styled('div')`
  width: 100%;
  max-width: ${({ isDesktop }) => (isDesktop ? widthLogo : '68px')};
  height: ${({ isDesktop }) => (isDesktop ? heightLogo : '44px')};
  background: white;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #c3c2c8;
  overflow: hidden;
`

export const Logo = styled('img')`
  width: ${({ isDesktop }) => (isDesktop ? widthLogo : '68px')};
  height: ${({ isDesktop }) => (isDesktop ? heightLogo : '44px')};
  border-radius: 4px;
`

export const Title = styled('h2')`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  transition: all 0.3s ease-in-out;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
`

export const TitleContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    align-items: flex-start;
    flex-direction: column;
  }
`

export const CaptionContainer = styled('div')`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-bottom: 0;
  }
`

export const Separator = styled('div')`
  margin: 0 5px;
`

const stylesLabels = `
  font-size: 14px;
  line-height: 20px;
`

export const Quantity = styled('div')`
  ${stylesLabels};
  color: ${({ theme, hasQuantity }) => (hasQuantity ? theme.colors.black84 : '#c3c2c8')};
  margin-left: 6px;
`

export const Label = styled('span')`
  ${stylesLabels};
  color: ${({ theme }) => theme.colors.black84};
  margin-left: 6px;
`

export const FlexContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const Rating = styled('div')`
  align-self: center;

  & > * {
    margin-bottom: 0;
  }
`

export const CustomRow = styled(Row)`
  * > div {
    margin-bottom: 12px;
  }

  * > div:last-child {
    margin-bottom: 0px;
  }
`

export const ImgGptw = styled.img`
  width: 16px;
  height: 26px;
`
