import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: #FFFFFF;
    padding-top: 0px;
  }
`

export const Page = styled.div`
  position: relative;
  z-index: 10;
`

export const PageContent = styled.div`
  height: calc(100vh - 72px);
  overflow: auto;
`

export const Header = styled.header`
  background-color: #ffffff;
  line-height: 65px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
`

export const Logo = styled.img`
  width: 100%;
  max-width: 288px;
`
