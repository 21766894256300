import React from 'react'
import { useTranslation } from 'react-i18next'

import { IS_ZONA_JOBS } from '../../constants'

// mixins
import { Component, Heading, Image } from './mixins'
import emptyStateImage from '../../assets/images/empty-state-listado.svg'
import emptyStateImageZJ from '../../assets/images/empty-state-listado-zj.svg'

const ListEmptyState = () => {
  const { t } = useTranslation()

  return (
    <Component>
      <Heading level={3}>{t('No encontramos lo que estás buscando')}</Heading>
      <Image src={IS_ZONA_JOBS ? emptyStateImageZJ : emptyStateImage} alt={t('No encontramos lo que estás buscando')} />
    </Component>
  )
}

export default ListEmptyState
