import React, { useContext, useState } from 'react'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { ThemeContext } from 'styled-components'

import {
  ApplicationStatusContainer,
  ApplicationStatusWrapper,
  IconContainer,
  PostuladoContainer,
  ReadContainer,
  Tooltip,
  CorazonContainer,
  Container,
} from '../../mixins'
import CorazonAnimado from '../../../corazon-animado'
import ModalNotInterested from '../../../modals/ModalNotInterested'

// muestra el estado de postulacion representado por un icon: completa e incompleta
// si no esta postulado muestra icon para guardar aviso
const ApplicationStatus = ({
  index,
  tipoAviso,
  read,
  estadoPostulacionRealizada,
  estadoPostulacionEspera,
  saveJobPostingEnabled,
  handleSavePosting,
  savePosting,
  isDesktop,
  mobileFormat,
  notInterestedJobPostingEnabled = false,
  id,
  handleNotInterested,
}) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const [showTooltip, setShowTooltip] = useState(false)
  const [visible, setVisible] = useState(false)

  const onclick = () => {
    setVisible(false)
    if (handleNotInterested) {
      setTimeout(() => {
        handleNotInterested(id)
      }, 750)
    }
  }

  const tooltipClick = () => {
    if (!isDesktop) {
      setShowTooltip(true)
      setTimeout(() => setShowTooltip(false), 2000)
    }
  }

  const tooltipEnter = () => {
    isDesktop && setShowTooltip(true)
  }
  const tooltipLeave = () => {
    isDesktop && setShowTooltip(false)
  }

  if (tipoAviso === 'zonajobs') return null
  return (
    <ApplicationStatusContainer
      mobileFormat={mobileFormat}
      saveJobPostingEnabled={saveJobPostingEnabled}
      notInterestedJobPostingEnabled={notInterestedJobPostingEnabled}
    >
      <ApplicationStatusWrapper>
        {read && !(estadoPostulacionRealizada || estadoPostulacionEspera) && (
          <ReadContainer>
            <Icon name="icon-light-checkmark-double" size="20" color="#FFFFFF" />
          </ReadContainer>
        )}
        {estadoPostulacionRealizada && (
          <PostuladoContainer>
            {showTooltip && (
              <Tooltip>
                <span>Ya Postulado</span>
              </Tooltip>
            )}
            <IconContainer onMouseEnter={tooltipEnter} onMouseLeave={tooltipLeave} onClick={tooltipClick}>
              <Icon name="icon-light-checkbox-checked" size="24" color={colors.secondary.normal} />
            </IconContainer>
          </PostuladoContainer>
        )}
        {estadoPostulacionEspera && <Icon name="icon-light-alert-triangle" size="24" color="#EC0000" />}
        {saveJobPostingEnabled && tipoAviso !== 'bumeran' && (
          <CorazonContainer>
            <CorazonAnimado
              index={index}
              disabled={estadoPostulacionRealizada || estadoPostulacionEspera}
              savePosting={savePosting}
              onClick={() => handleSavePosting()}
            />
          </CorazonContainer>
        )}
        {notInterestedJobPostingEnabled && (
          <>
            <Container onClick={() => setVisible(true)}>
              <Icon size="18" color={colors.black72} name="icon-light-thumbs-down" />
            </Container>
            <ModalNotInterested avisoId={id} visible={visible} setVisible={setVisible} onClick={onclick} />
          </>
        )}
      </ApplicationStatusWrapper>
    </ApplicationStatusContainer>
  )
}

export default ApplicationStatus
